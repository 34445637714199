import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ConfigService {

  public isProdDomain: boolean;

  constructor(private Http: HttpClient) {
  }

  loadConfig(): Observable<any> {
    return this.Http.get(`assets/confs/${window.location.hostname}.config.json`);
  }
}
