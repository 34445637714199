import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SchedulesFacade } from './schedules.facade';
import { Action } from '@ngrx/store';
import { HomesFacade, HOMES_FACADE } from '../homes/homes.facade';

/**
 * Pre-fetches data before accessing the route
 *
 * How to use it:
 * In the routing module:
 *    - Add "resolve: {schedules: SchedulesResolver}" to the route initial path
 */
@Injectable({providedIn: 'root'})
export class SchedulesResolver  {

  constructor(private facade: SchedulesFacade, @Inject(HOMES_FACADE) private homeFacade: HomesFacade) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Action> | Promise<Action> | Action {
    this.homeFacade.selectHome(route.params.home_id);

    const params = {} as any;

    if (route.data.gatewayTypes) {
      params.detailed_config = true;
      params.gateway_types = route.data.gatewayTypes;
    }

    return this.facade.loadSchedules(params);
  }
}
