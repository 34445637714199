import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { ModalBodyComponent } from './modal-body/modal-body.component';
import { AtomsModule } from '@library/ui/atoms/atoms.module'
import { ErrorPopUpComponent } from './modal-global-error/error-pop-up.component';
import { PipesModule } from '@library/utils/pipes/pipes.module';
import { DirectivesModule } from '@library/ui/directives/directives.module';
import { ElHeadingModule } from '@library/ui/molecules/el-heading/el-heading.module';
import { WarningIconModule } from '@library/ui/molecules/warning-icon/warning-icon.module';
import { LinkModule } from '../link/link.module';
import { ModalErrorIconComponent } from './modal-error-icon/modal-error-icon.component';
import { ModalTitleComponent } from './modal-title/modal-title.component';
import { ModalSubtitleComponent } from './modal-subtitle/modal-subtitle.component';
import { ModalFooterTextComponent } from './modal-footer-text/modal-footer-text.component';
import { ModalButtonLinkComponent } from './modal-button-link/modal-button-link.component';
import { LayButtonModule } from '@library/ui/layout/button/button.module';

@NgModule({
  declarations: [
    ModalComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalBodyComponent,
    ErrorPopUpComponent,
    ModalErrorIconComponent,
    ModalButtonLinkComponent,
    ModalTitleComponent,
    ModalSubtitleComponent,
    ModalFooterTextComponent
  ],
  imports: [
    CommonModule,
    AtomsModule,
    PipesModule,
    DirectivesModule,
    ElHeadingModule,
    WarningIconModule,
    LinkModule,
    LayButtonModule
  ],
  exports: [
    ModalComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalBodyComponent,
    ErrorPopUpComponent,
    ModalErrorIconComponent,
    ModalButtonLinkComponent,
    ModalTitleComponent,
    ModalSubtitleComponent,
    ModalFooterTextComponent
  ],
})
export class ModalModule { }
