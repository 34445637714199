import { Action } from '@ngrx/store';
import { GlobalErrorPayload } from './global-error.types';

export enum EnumGlobalActions {
  GlobalError = '[APP] Observe an error'
}

export class GlobalError implements Action {
  public readonly type = EnumGlobalActions.GlobalError;
  constructor(public payload: GlobalErrorPayload) { }
}

export type GlobalActions = GlobalError;
