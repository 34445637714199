import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@library/utils/pipes/pipes.module'
import { EmbeddedSettingsFrameComponent } from './embedded-settings-frame.component'

@NgModule({
    declarations: [
        EmbeddedSettingsFrameComponent
    ],
    exports: [
        EmbeddedSettingsFrameComponent
    ],
    imports: [
        CommonModule,
        PipesModule
    ]
})
export class EmbeddedSettingsFrameModule { }
