import { Injectable } from '@angular/core';
import { GetUser } from '@library/store/user/user.action'
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { Store } from '@ngrx/store';


@Injectable({ providedIn: 'root' })
export class InitFacade {
  constructor(private store: Store<SettingsState>) {}

  start() {
    this.store.dispatch(GetUser());
  }
}
