export const DEFAULT_CONTACT_SERVICE = {
  at: {
    NAME: 'Service Austria',
    PHONE: '0570 50 2100',
    MAIL: 'termin@vaillant.at'
  },
  ba: {
    NAME: 'Service Bosnia and Herzegovina',
    PHONE: '+38762342567',
    EMAIL: 'admir.delalic@vaillant.com'
  },
  be: {
    NAME: 'Service Belgium',
    PHONE: '02/334.93.52',
    MAIL: 'connect@vaillant.be',
  },
  cz: {
    NAME: 'Service Czech Republic',
    PHONE: '+420 810 200 210',
    EMAIL: 'vaillant@vaillant.cz'
  },
  de: {
    NAME: 'Service Germany',
    PHONE: '',
    MAIL: 'erelax@vaillant.de'
  },
  dk: {
    NAME: 'Service Denmark',
    PHONE: '004546160200',
    MAIL: 'info@vaillant.dk'
  },
  es: {
    NAME: 'Service Spain',
    PHONE: '0034 910 779 779',
    MAIL: 'info@vaillant.es'
  },
  fr: {
    NAME: 'Service France',
    PHONE: '+33 9 74 75 74 75',
    MAIL: ''
  },
  hr: {
    NAME: 'Service Croatia',
    PHONE: '003851/6188692',
    EMAIL: 'zvonimir.strapajevic@vaillant.com'
  },
  hu: {
    NAME: 'Service Hungary',
    PHONE: '+36 1 464-7802',
    MAIL: 'info@vaillant.hu'
  },
  it: {
    NAME: 'Service Italy',
    PHONE: '02697121',
    MAIL: 'app-support@vaillant-group.com'
  },
  nl: {
    NAME: 'Service Netherlands',
    PHONE: '020-565 9420',
    MAIL: 'support@vaillant.nl'
  },
  pl: {
    NAME: 'Service Poland',
    PHONE: '08 01 80 44 44',
    MAIL: 'vallant@vaillant.pl'
  },
  pt: {
    NAME: 'Service Portugal',
    PHONE: '',
    EMAIL: 'info@vaillant.pt'
  },
  ro: {
    NAME: 'Service Romania',
    PHONE: '+40212098888',
    EMAIL: 'office@vaillant.com.ro'
  },
  rs: {
    NAME: 'Service Serbia',
    PHONE: '0038111/3540050',
    EMAIL: 'marko.bulatovic@vaillant.com'
  },
  sl: {
    NAME: 'Service Slovenia',
    PHONE: '01 280 93?45',
    EMAIL: 'info@vaillant.si'
  },
  sk: {
    NAME: 'Service Slovakia',
    PHONE: '+421 346966128',
    EMAIL: 'vaillant@vaillant.sk'
  },
  tr: {
    NAME: 'Service Turkey',
    PHONE: '444 2888',
    EMAIL: 'vaillant@vaillant.com.tr'
  },
  ua: {
    NAME: 'Service Ukraine',
    PHONE: '0800 501805',
    EMAIL: 'info@vaillant.ua'
  },
  uk: {
    NAME: 'Service UK',
    PHONE: '0330 100 3487',
    MAIL: 'technical@vaillant.co.uk',
  },
  gb: {
    NAME: '',
    PHONE: '0330 100 3487',
    MAIL: 'technical@vaillant.co.uk',
  },
  others: {
    NAME: '',
    PHONE: '',
    MAIL: 'app-support@vaillant-group.com'
  },
};

export const DEFAULT_CONTACT_SERVICE_MIGO = {
  be: {
    NAME: 'Service Belgium',
    PHONE: '02/431.80.00',
    MAIL: 'connect@bulex.be'
  },
  cz: {
    NAME: 'Service Czech Republic',
    PHONE: '+420 257 090 850',
    MAIL: 'protherm@protherm.cz'
  },
  es: {
    NAME: 'Service Spain',
    PHONE: '0034 910 77 99 11',
    MAIL: ''
  },
  fr: {
    NAME: 'Service France',
    PHONE: '09 74 75 02 75',
    MAIL: ''
  },
  hu: {
    NAME: 'Service Hungary',
    PHONE: '+36 1 283-0553',
    MAIL: 'info@saunierduval.hu'
  },
  it: {
    NAME: 'Service Italy',
    PHONE: '02697121',
    MAIL: 'app-support@vaillant-group.com'
  },
  pl: {
    NAME: 'Service Poland',
    PHONE: '08 01 80 66 66',
    MAIL: 'info@saunierduval.pl'
  },
  sk: {
    NAME: 'Service Slovakia',
    PHONE: '+421 346966166',
    MAIL: 'protherm@protherm.sk'
  },
  uk: {
    NAME: 'Service UK',
    PHONE: '0330 100 3486',
    MAIL: 'Technical@glow-worm.co.uk'
  },
};

export const APP_NAME_CROWDIN_MAP = {
  app_thermostat_vaillant: 'settings-vaillant',
  app_thermostat_migo: 'settings-sdbg'
};

export const moduleDefaults = {
  heatingCurveDefault: 26,
  simpleAlgoDeadband: 0.1
};

export const HEATING_TYPES = {
  subfloor: 'settings-therm.__HEATING_TYPE_SUBFLOOR',
  radiator: 'settings-therm.__HEATING_TYPE_RADIATOR',
  unknown: 'settings-therm.__HEATING_TYPE_DONT_KNOW'
};

export const IMPRINT_URLS = {
  migo: 'https://www.saunierduval.com/home/imprint',
  vaillant: 'https://www.vaillant-group.com/meta-navigation/legal-notice.html',
  demirdokum: 'https://www.demirdokum.com.tr/kurumsal/politikalar/bilgi-toplumu-hizmetleri/',
};

export const MYACCOUNT_URLS = {
  vaillant: '?client_id=com.netatmo.migo.vaillant&disable_dark_mode=true',
  migo: '?client_id=com.netatmo.migo&disable_dark_mode=true',
  demirdokum: '?client_id=com.netatmo.migo.demirdokum&disable_dark_mode=true'
};

export const pidTable = new Map([
  [10, 2],
  [18, 3],
  [24, 4],
  [32, 5],
  [40, 6],
  [50, 7],
]);
