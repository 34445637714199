import { initialUserState, UserState } from './user.state';
import { GetUserSuccess, GetUserFailure, SetHomeUser } from './user.action';
import { createReducer, on } from '@ngrx/store'

export const UserReducers = createReducer(initialUserState,
  on(GetUserSuccess, (state, {user}) => {
      return {
        ...state,
        ... {
          id: user['_id'],
          app_telemetry: user.app_telemetry,
          fb_chatbot_available: user.fb_chatbot_available,
          mail: user.mail,
          not_associable: user.not_associable,
          name: user.name,
          language: user['administrative']['lang'],
          locale: user['administrative']['reg_locale'],
          feel_like_algorithm: user['administrative']['feel_like_algo'],
          unit_pressure: user['administrative']['pressureunit'],
          unit_system: user['administrative']['unit'],
          unit_wind: user['administrative']['windunit'],
          pending_user_consent: user.pending_user_consent,
        }
      };
    }
  ),
  on(GetUserFailure, (state, {payload}) => {
    console.error('Failure', payload);
    return {
      ...state,
    };
  }),

  on(SetHomeUser, (state, {user}) => {
      return {
        ...state,
        ...user,
        mail: user['email'],
        pending_user_consent: user.pending_user_consent,
      }
    }
  )
)
