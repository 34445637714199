<header *ngIf="!agent.isNativeApp()" class="columns is-mobile is-vcentered is-gapless">
  <div class="back-button column is-3 has-text-left" [class.is-cursor-pointer]="needBack">
    <app-icon *ngIf="needBack" (click)="back()" class="is-header-button is-small" name="chevron-left"></app-icon>
  </div>

  <div class="title-container column is-6 has-text-centered ">
    <lay-overflow-ellipsis>
      <app-text [text]="title" class="header-title is-justify-center is-fullwidth"></app-text>
    </lay-overflow-ellipsis>
  </div>

  <div class="close-button column is-3 has-text-right" [class.is-cursor-pointer]="needClose">
    <app-icon *ngIf="needClose" class="is-align-end" styleClass="is-header-button is-medium" name="close" (click)="close()"></app-icon>
  </div>
</header>
