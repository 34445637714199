import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { YesNoComponent } from './yes-no.component';
import { AtomsModule } from '@library/ui/atoms/atoms.module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AtomsModule,
  ],
  exports: [
    YesNoComponent,
  ],
  declarations: [YesNoComponent]
})
export class YesNoModule { }
