import { Injectable } from '@angular/core';
import { NgrxHttpClient } from '@library/utils/services/http-service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { ResetHeatingCurvePayload, SetContactInfoPayload, SetHeatingAlgoPayload, SetHeatingCurvePayload, SetHeatingTypePayload } from './modules.interface';

@Injectable({
  providedIn: 'root'
})
export class ModulesServiceVaillant {

  constructor(
    private http: NgrxHttpClient,
    private store: Store<AppState>
  ) { }

  setHeatingCurve(params: SetHeatingCurvePayload | ResetHeatingCurvePayload) {
    return this.http.post('changeheatingcurve', params);
  }

  setHeatingType(payload: SetHeatingTypePayload) {
    return this.http.post('setheatingsystem', { ...payload });
  }

  changeContactService(data: SetContactInfoPayload) {
    if (data.reset) {
      return this.http.post('changecontactservice', { device_id: data.device_id, reset: true });
    }
    return this.http.post('changecontactservice', data);
  }

  setHeatingAlgo(data: SetHeatingAlgoPayload) {
    return this.http.post('changeheatingalgo', data);
  }

  setPeriod(params) {
    return this.http.post('changepidperiod', params);
  }
}
