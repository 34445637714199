import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaySelectionComponent } from './day-selection.component';
import { AtomsModule } from '@library/ui/atoms/atoms.module';
import { PipesModule } from '@library/utils/pipes/pipes.module';

@NgModule({
  declarations: [
    DaySelectionComponent
  ],
  imports: [
    CommonModule,
    AtomsModule,
    PipesModule,
  ],
  exports: [
    DaySelectionComponent
  ]
})
export class DaySelectionModule { }
