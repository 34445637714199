import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FunTextComponent } from './text.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    FunTextComponent
  ],
  declarations: [FunTextComponent]
})
export class FunTextModule { }
