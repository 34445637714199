import {
  ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit,
  Output,
  ViewChild
} from '@angular/core';

import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true
    }
  ]
})
export class InputTextComponent implements ControlValueAccessor, OnInit {

  @Input() placeholder: string = '';
  @Input() type = 'text';
  @Input() updateOn: 'blur' | 'change' | 'submit' = 'blur';
  @Input() hasEdit = true;

  @Input() autocomplete = 'on';
  @Input() styleClass = '';

  public isFocus = false;

  unique: string = this.constructor['ɵcmp'].id;

  inputText: FormControl;

  @ViewChild('clearIcon', {read: ElementRef}) clearIcon: ElementRef<HTMLElement>;

  ngOnInit() {
    this.inputText = new FormControl(null, { updateOn: this.updateOn });
    this.inputText.valueChanges.subscribe((value) => {
      this.propagateChange(value);
    });
  }
  clickOnIcon() {
    const isClearVisible = getComputedStyle(this.clearIcon.nativeElement).display === "block";
    if (isClearVisible)
      this.inputText.setValue("");
  }

  propagateChange: any = () => {};

  writeValue(value: string) {
    this.inputText.setValue(value, {emitEvent: false});
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  // Unused but necessary to implement ControlValueAccessor
  registerOnTouched() {}
  
  onFocus() {
    this.isFocus = true;
  }

  onBlur() {
    this.isFocus = false;
  }

}
