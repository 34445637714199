import { Injectable } from '@angular/core';
import { InitFacade } from './init.facade';
import { ofType, Actions } from '@ngrx/effects';
import { take, tap } from 'rxjs/operators';
import { EnumLangActions, LoadLangsSuccess } from '@library/store/lang/lang.actions';

@Injectable()
export class InitLoader {
  langByUser: object;
  constructor(private actions$: Actions, private initFacade: InitFacade) {}

  load() {
    this.initFacade.start();
    return this.actions$
      .pipe(
        ofType<LoadLangsSuccess>(EnumLangActions.LoadLangsSuccess),
        take(1),
      ).toPromise(); // Without .toPromise, the loader is not working
  }
}

export function InitLoaderFactory(service: InitLoader) {
  return () => service.load();
}
