import { Component, Input } from '@angular/core';

@Component({
  selector: 'el-heading',
  templateUrl: './el-heading.component.html',
  styleUrls: ['./el-heading.component.scss']
})
export class ElHeadingComponent {
  /**
   * Main text
   */
  @Input() text: string;

  /**
   * Sub text, optional
   */
  @Input() subtext: string='';

  /**
   * Add extra class to text
   */
  @Input() textClass: string;

  /**
   * Add extra class to sub text
   */
   @Input() subTextClass: string;
}

/**
 * Used in Settings Security
 * Used in Settings Legrand
 */
