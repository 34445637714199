import { AfterViewInit, Component, ElementRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lay-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayButtonComponent implements AfterViewInit {

  constructor(private elRef: ElementRef) { }

  ngAfterViewInit() {
    /**
     * Takes any children and applies bulma 'Button' class to it
     */
    const layButtonChildren = this.elRef.nativeElement.children;
    if (layButtonChildren.length > 0) {
      for (const button of layButtonChildren) {
        button.className = 'button ' + button.className;
      }
    }
  }

}
