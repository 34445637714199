import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigFacade } from '../config/config.facade';
import { ConfigInterface } from '../config/config.interface';

@Injectable()
export class LangService {

  public config: ConfigInterface;

  constructor(
    private http: HttpClient,
    private configFacade: ConfigFacade
  ) {
    this.configFacade.config$.subscribe(c => this.config = c);
  }

  loadLangs(langOptions: string[] | string | object) {
    const aliases = [];
    // aliases can handle 3 format:
    // - string: alias: 'app'
    // - array of string: alias: ['app', 'common', 'settings]
    // - array of object: alias: [{name: 'app', locale: 'fr'},{name: 'common', locale: 'fr-FR'}]
    if (typeof langOptions['aliases'] === 'object') {
      langOptions['aliases'].forEach(alias => {
        // if we have an object which define the name and the locale of the file
        if (alias.hasOwnProperty('name') && alias.hasOwnProperty('locale')) {
          aliases.push({
            name: alias.name,
            locale: alias.locale,
          });
        } else {
          // if we have an object of string with only the name of the file, set the locale to the default
          aliases.push({
            name: alias,
            locale: langOptions['locale'],
          });
        }
      });
    } else if (typeof langOptions['aliases'] === 'string') {
      // if we have a string, set it to the name and the locale to the default
      aliases.push({
        name: langOptions['aliases'],
        locale: langOptions['locale'],
      });
    }

    const options = {
      baseUrl: this.config.langsUrl,
      aliases,
      locale: langOptions['locale'],
    };


    const langs$ = [];
    options.aliases.forEach(alias => {
      langs$.push(this.http.get(`${options.baseUrl}${alias.locale}/${alias.name}.json?v=1`));
    });

    return forkJoin(langs$).pipe(
      map(
        langs => langs.reduce((acc: {[key: string]: string}, currLang, index) => ({
          ...acc,
          [options.aliases[index].name]: currLang
        }), {}))
    );
  }
}
