import { Component, OnInit, AfterViewChecked, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UserAgentService } from '@library/utils/services/user-agent.service';
import { Subscription } from 'rxjs';
import { PageLoaderFacade } from './stores/page-loader/page-loader.facade';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {

  isPageLoading = true;
  pageLoadingSubscription: Subscription;

  constructor(
    private userAgent: UserAgentService,
    private pageLoaderFacade: PageLoaderFacade,
    private changeDetection: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.setDeviceClasses();

    this.pageLoadingSubscription = this.pageLoaderFacade.pageLoading().subscribe((loading) => {
      this.isPageLoading = loading;
      // Force change detection to update template as soon as the store is changed
      // we get ExpressionChangedAfterItHasBeenCheckedError without it
      this.changeDetection.detectChanges();
    });
  }

  /**
   * For Iphone X: add viewport-fit=cover to prevent the confirm button from being cut
   */
  ngAfterViewChecked() {
    const viewport = document.head.getElementsByTagName('meta').namedItem('viewport');

    // If the viewport meta tag doesn't contain "viewport-fit=cover", add it
    if (viewport.content.indexOf('viewport-fit=cover') === -1) {
      viewport.content += ', viewport-fit=cover';
    }
  }

  /**
   * Add device environement classes to body
   * depends on user agent
   */
  setDeviceClasses() {
    if (this.userAgent.isNativeApp()) {
      document.body.classList.add('is-native');
    }

    if (this.userAgent.isMobile()) {
      document.body.classList.add('is-mobile');
    } else {
      document.body.classList.add('is-desktop');
    }

    if (this.userAgent.isIos()) {
      document.body.classList.add('is-ios');
      document.body.classList.add(
        `is-ios-version-${this.userAgent.iosVersion().trim()}`
      );
    }

    if (this.userAgent.isAndroid()) {
      document.body.classList.add('is-android');
    }
  }

  ngOnDestroy() {
    this.pageLoadingSubscription.unsubscribe();
  }

}
