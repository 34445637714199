import { ActionReducerMap } from '@ngrx/store';
import { AppState } from './app.state';
import { HomesReducersVaillant } from './homes/homes.reducer';
import { ModulesReducersVaillant } from './modules/modules.reducers';
import { HomesStateVaillant, initialHomesStateVaillant } from './homes/homes.state';
import { HomesActionsVaillant } from './homes/homes.actions';
import { initialModulesStateVaillant, ModulesStateVaillant } from './modules/modules.state';
import { ModulesActionsVaillant } from './modules/modules.actions';
import { reduceReducers } from '@library/utils/helpers/store/reduce-reducers';
import { HomesReducers } from '@library/store/homes/homes.reducer';
import { ModulesReducers } from '@library/store/modules/modules.reducer';
import { EnumPageLoaderActions, PageLoaderActions } from '@library/store/page-loader/page-loader.actions';
import { ConfigReducers } from '@library/store/config/config.reducer';
import { UserReducers } from '@library/store/user/user.reducer';
import { LangReducers } from '@library/store/lang/lang.reducers';
import { RoomsReducers } from '@library/store/rooms/rooms.reducer';
import { initialRoomsState, RoomsState } from '@library/store/rooms/rooms.state';
import { RoomsActions } from '@library/store/rooms/rooms.action';
import { VaillantRoomsReducers } from './rooms/rooms.reducer';

export function HomesReducersReduced(
  state = initialHomesStateVaillant,
  action: HomesActionsVaillant
): HomesStateVaillant {
  const reducer = reduceReducers(
    initialHomesStateVaillant,
    HomesReducers,
    HomesReducersVaillant
  );
  return reducer(state, action);
}

export function ModulesReducersReduced(
  state = initialModulesStateVaillant,
  action: ModulesActionsVaillant
): ModulesStateVaillant {
  const reducer = reduceReducers(
    initialModulesStateVaillant,
    ModulesReducers,
    ModulesReducersVaillant
  );
  return reducer(state, action);
}

export function RoomsReducersReduced(
  state = initialRoomsState,
  action: RoomsActions
): RoomsState {
  const reducer = reduceReducers(
    initialRoomsState,
    RoomsReducers,
    VaillantRoomsReducers
  );
  return reducer(state, action);
}

export function isPageLoading(state = true, action: PageLoaderActions) {
  switch (action.type) {
    case EnumPageLoaderActions.ShowPageLoader: return true;
    case EnumPageLoaderActions.HidePageLoader: return false;
    default: return state;
  }
}

export const AppReducers: ActionReducerMap<AppState, any> = {
  config: ConfigReducers,
  user: UserReducers,
  langs: LangReducers,
  rooms: RoomsReducersReduced,
  homes: HomesReducersReduced,
  modules: ModulesReducersReduced,
  isPageLoading,
};
