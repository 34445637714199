import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfigInterface } from '@library/store/config/config.interface';
import { LangFacade } from '@library/store/lang/lang.facade';
import { UserFacade } from '@library/store/user/user.facade';
import { User } from '@library/store/user/user.interface';
import { LangPipe } from '@library/utils/pipes/lang.pipe';
import { UserAgentService } from '@library/utils/services/user-agent.service'
import { HomesFacadeVaillant } from 'app/stores/homes/homes.facade';
import { ModulesFacadeVaillant } from 'app/stores/modules/modules.facade';
import { ModuleVaillant } from 'app/stores/modules/modules.interface';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewComponent } from '../view.component';

interface VaillantConfig extends ConfigInterface {
  faqUrl: string;
}
@Component({
  selector: 'app-feedback-view',
  templateUrl: './feedback-view.component.html',
  styleUrls: ['./feedback-view.component.scss']
})
export class FeedbackViewComponent extends ViewComponent implements OnInit {

  defaultEmail = 'app-support@vaillant-group.com';

  data$ = combineLatest([
    this.configFacade.config$,
    this.userFacade.currentUser$,
    this.langFacade.regLocale$,
    this.modulesFacadeVaillant.currentModule$,
    this.homesFacadeVaillant.currentHome$,
    this.modulesFacadeVaillant.gatewayOfCurrentModule$
  ]).pipe(
    map(([config, user, regLocale, device, home, gateway]) => ({ config, user, regLocale, device, home, gateway }))
  );

  mailForm = new UntypedFormGroup({
    object: new UntypedFormControl('', Validators.required),
    message: new UntypedFormControl('', Validators.required)
  });


  constructor(
    injector: Injector,
    private modulesFacadeVaillant: ModulesFacadeVaillant,
    private homesFacadeVaillant: HomesFacadeVaillant,
    private userFacade: UserFacade,
    private langFacade: LangFacade,
    private langPipe: LangPipe,
    private userAgent: UserAgentService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.header.push({
      title: this.langPipe.transform('common-settings.__MY_CONTACT')
    });
  }

  getTopMessageText(config: VaillantConfig) {
    return this.langPipe.transform('settings-all.__VAI_SETTINGS_CONTACT_MIGO_FAQ_TEXT', [config.faqUrl]);
  }

  sendMessage(user: User, regLocale: string, config: ConfigInterface, device: ModuleVaillant, gateway: ModuleVaillant) {
    const object = this.getContactObject(regLocale, config.appName, this.mailForm.get('object').value);
    const message = this.supplementMessage(user, this.mailForm.get('message').value, device, gateway);
    const url = 'mailto:' + this.defaultEmail +
      '?subject=' +
      encodeURIComponent(object) +
      '&body=' +
      encodeURIComponent(message);
    return url;
  }

  private getContactObject(regLocale: string, appName: string, userRequest: string) {
    if (appName === 'app_thermostat_migo') {
      return `[${regLocale}] [Migo] - ${userRequest}`;
    }
    return `[${regLocale}] [eRELAX] - ${userRequest}`;
  }

  private supplementMessage(user: User, message: string, device: ModuleVaillant, gateway: ModuleVaillant) {
    const setupTime = moment.unix(gateway.setup_date).format('DD-MM-YYYY');
    return 'QUESTION\r\n' +
    message + '\r\n\r\n' +
    'USER\r' +
    'User Mail: ' + user.mail + '\r\n' +
    'User ID: ' + user.id + '\r\n\r\n' +
    'DEVICE\r\n' +
    'Gateway Mac: ' + gateway.id + '\r\n' +
    'Thermostat Mac: ' + device.id + '\r\n' +
    'Serial Number: ' + gateway.oem_serial + '\r\n' +
    'Date Setup: ' + setupTime +'(UTC)' + '\r\n\r\n' +
    'EBUS STATUS\r\n' +
    'boiler_error: ' + !!(gateway.boiler_error && gateway.boiler_error?.includes('boiler_error_internal')) + '\r\n' +
    'ebus_error: ' + !!gateway.ebus_error + '\r\n' +
    'maintenance_status: ' + !!(gateway.boiler_error && gateway.boiler_error?.includes('maintenance')) + '\r\n' +
    'refill_water: ' + !!(gateway.boiler_error && gateway.boiler_error?.includes('water_pressure')) + '\r\n' +
    'user agent:' + this.userAgent.info  + '\r\n';
  }


}
