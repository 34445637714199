import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlusMinusComponent } from './plus-minus.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AtomsModule } from '@library/ui/atoms/atoms.module'
import { LayoutModule } from '@library/ui/layout/layout.module'
import { MinusComponent } from './minus/minus.component';
import { PlusComponent } from './plus/plus.component';
import { LongClickDirective } from './long-click.directive';
import { PlusMinusService } from './plus-minus.service';

@NgModule({
  imports:
  [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AtomsModule,
    LayoutModule,
  ],
  exports: [
    PlusMinusComponent,
    MinusComponent,
    PlusComponent,
    LongClickDirective
  ],
  declarations:
  [
    PlusMinusComponent,
    MinusComponent,
    PlusComponent,
    LongClickDirective
  ],
  providers: [
    PlusMinusService
  ]
})
export class PlusMinusModule {}
