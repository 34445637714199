import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IconModule } from '../icon/icon.module'
import { RangeComponent } from './range.component';

@NgModule({
    declarations: [
        RangeComponent
    ],
    exports: [
        RangeComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IconModule
    ]
})
export class RangeModule { }
