import { createSelector } from '@ngrx/store';
import { ConfigState } from './config.state';

const getConfig = (state: {config: ConfigState}) => state.config;

export const getAllConfig = createSelector(
  getConfig,
  (config: ConfigState) => config
);

export const getAppId = createSelector(
  getConfig,
  (config: ConfigState) => config.appId
);

export const getEnvironment = createSelector(
  getConfig,
  (config: ConfigState) => config.env
);

export const getWebappMode = createSelector(
  getConfig,
  (config: ConfigState) => config.webappMode || false
);

export const darkModeEnabled = createSelector(
  getConfig,
  (config: ConfigState) => config.darkModeEnabled
);

export const getAppPrefix = createSelector(
  getConfig,
  (config: ConfigState) => config.appPrefix
);
