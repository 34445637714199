import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { switchMap, map, tap } from 'rxjs/operators';
import { GetConfig, EnumConfigActions, GetConfigSuccess } from './config.action';
import { ConfigService } from './config.service';
import { ConfigInterface } from './config.interface';
import { of } from 'rxjs';

@Injectable()
export class ConfigEffects {
  getConfig$ = createEffect(() => this.actions$.pipe(
    ofType<GetConfig>(EnumConfigActions.GetConfig),
    switchMap(() => {
      // Note : For new settings static we doesn't need to load config file, already load into environment.ts
      if (window['NAConfig'] && !window['NAConfig'].isProdDomain) {
        return this.configService.loadConfig();
      }
      return of(window['NAConfig']);
    }),
    map((config: ConfigInterface) => new GetConfigSuccess(config))
  ));

  constructor(
    private configService: ConfigService,
    private actions$: Actions
  ) { }
}
