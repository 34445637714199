import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayButtonComponent } from './button.component';



@NgModule({
  declarations: [LayButtonComponent],
  imports: [
    CommonModule
  ],
  exports: [
    LayButtonComponent
  ]
})
export class LayButtonModule { }
