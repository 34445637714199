import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AtomsModule } from '@library/ui/atoms/atoms.module';
import { TemperatureRangeComponent } from './temperature-range.component';

@NgModule({
  declarations: [
    TemperatureRangeComponent
  ],
  exports: [
    TemperatureRangeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AtomsModule,
  ]
})
export class TemperatureRangeModule { }
