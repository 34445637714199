import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsumptionBarComponent } from './consumption-bar/consumption-bar.component';
import { ConsumptionLineComponent } from './consumption-line.component';
import { AtomsModule } from '@library/ui/atoms/atoms.module'
import { LayoutModule } from '@library/ui/layout/layout.module'
import { DirectivesModule } from '@library/ui/directives/directives.module';



@NgModule({
  declarations: [
    ConsumptionBarComponent,
    ConsumptionLineComponent,
  ],
  imports: [
    CommonModule,
    AtomsModule,
    LayoutModule,
    DirectivesModule,
  ],
  exports: [
    ConsumptionLineComponent,
  ]
})
export class ConsumptionLineModule { }
