import { Pipe, PipeTransform } from '@angular/core';
import { LangFacade } from '@library/store/lang/lang.facade';
import { take } from 'rxjs/operators';


@Pipe({ name: 'LANG' })
export class LangPipe implements PipeTransform {

  constructor(
    private langFacade: LangFacade,
    ) {}

  transform(value, args: string[] = []) {
    let trans;

    this.langFacade.translate(value, args).pipe(take(1)).subscribe(t =>  trans = t);

    return trans;
  }
}
