import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RadioComponent } from './radio.component';

@NgModule({
    declarations: [
        RadioComponent
    ],
    exports: [
        RadioComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class RadioModule { }
