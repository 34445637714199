export interface LangState {
  [key: string]: {};
  reg_locale: string;
}

// const reg_locale = new URLSearchParams(document.location.search).get('reg_locale') || 'en_US';

export const initialLangState: LangState = {
  app: {},
  reg_locale: 'en_US'
};
