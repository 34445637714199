import { Action } from '@ngrx/store';
import { GetHomeConfigPayload, HomeData, HomeSyncPayload, SetHomeConfigPayload, SyncError } from '../homes/homes.interface';
import { APIResponseError } from '../interfaces/erros';
import { ChangePolarityPayload, HomeErrors, ModuleData, ModuleNameUpdate, ModuleRoomUpdate, RemoveModulePayload } from './modules.interface';

export enum EnumModulesActions {
  GetModules = '[Modules] Get Modules data',
  GetModulesSuccess = '[Modules] Get Modules data success',
  GetModulesFailure = '[Modules] Get Modules data failure',
  UpdateModulesStatus = '[Modules] update modules status after homes status success',
  UpdateModulesSetConfig = '[Modules] update modules config after set config success',
  UpdateModulesGetConfig = '[Modules] update modules config after get config success',
  SetModulesErrors = '[Modules] Set config errors on modules (after set or get config)',
  SetModulesStatusErrors = '[Modules] Set status errors on modules (after homestatus)',
  ModuleSelected = '[Modules] Set current module ID',

  UpdateModuleName = '[Modules] Updating Module Name',
  UpdateModuleNameSuccess = '[Modules] Updated Module Name Successfully',
  UpdateModuleNameFailure = '[Modules] Failed To Update Module Name',

  UpdateModuleRoom = '[Modules] Update Module To New Room',
  UpdateModuleRoomSuccess = '[Modules] Successfully Updated Module To New Room',
  UpdateModuleRoomFailure = '[Modules] Failed To Update Module To New Room',

  RemoveModuleFromHome = '[Modules] Removing Module From Home',
  RemoveModuleFromHomeSuccess = '[Modules] Successfully Removed Module From Home',
  RemoveModuleFromHomeFailure = '[Modules] Failed To Remove Module From Home',


  ChangePolarity = '[Modules] change polarity',
  ChangePolaritySuccess = '[Modules] change polarity success',
  ChangePolarityFailure = '[Modules] change polarity failure',
}

export class GetModules implements Action {
  public readonly type = EnumModulesActions.GetModules;
  constructor(public payload: ModuleData[]) { }
}

export class GetModulesSuccess implements Action {
  public readonly type = EnumModulesActions.GetModulesSuccess;
  constructor(public payload: HomeData[], public modules?: ModuleData[]) { }
}

export class GetModulesFailure implements Action {
  public readonly type = EnumModulesActions.GetModulesFailure;
  constructor(public payload: SyncError[]) { }
}

export class UpdateModulesStatus implements Action {
  public readonly type = EnumModulesActions.UpdateModulesStatus;
  constructor(public payload: HomeSyncPayload) { }
}

export class SetModulesErrors {
  public readonly type = EnumModulesActions.SetModulesErrors;
  constructor(public payload: HomeErrors) { }
}

export class SetModulesStatusErrors {
  public readonly type = EnumModulesActions.SetModulesStatusErrors;
  constructor(public payload: HomeErrors) { }
}

export class UpdateModulesSetConfig {
  public readonly type = EnumModulesActions.UpdateModulesSetConfig;
  constructor(public payload: SetHomeConfigPayload) { }
}
export class UpdateModulesGetConfig {
  public readonly type = EnumModulesActions.UpdateModulesGetConfig;
  constructor(public payload: GetHomeConfigPayload) { }
}

export class SelectModuleAction implements Action {
  public readonly type = EnumModulesActions.ModuleSelected;
  constructor(public payload: string) { }
}

export class UpdateModuleName implements Action {
  public readonly type = EnumModulesActions.UpdateModuleName;
  constructor(public payload: ModuleNameUpdate, public header?: { [key: string]: string }) {}
}

export class UpdateModuleNameSuccess implements Action {
  public readonly type = EnumModulesActions.UpdateModuleNameSuccess;
  constructor(public payload: ModuleNameUpdate) {}
}

export class UpdateModuleNameFailure implements Action {
  public readonly type = EnumModulesActions.UpdateModuleNameFailure;
  constructor(public payload: any) {}
}

export class UpdateModuleRoom implements Action {
  public readonly type = EnumModulesActions.UpdateModuleRoom;
  constructor(public payload: ModuleRoomUpdate, public header?: { [key: string]: string }) {}
}

export class UpdateModuleRoomSuccess implements Action {
  public readonly type = EnumModulesActions.UpdateModuleRoomSuccess;
  constructor(public payload: ModuleRoomUpdate) {}
}

export class UpdateModuleRoomFailure implements Action {
  public readonly type = EnumModulesActions.UpdateModuleRoomFailure;
  constructor(public payload: APIResponseError) {}
}

export class RemoveModuleFromHome implements Action {
  public readonly type = EnumModulesActions.RemoveModuleFromHome;
  constructor(public payload: RemoveModulePayload) {}
}

export class RemoveModuleFromHomeSuccess implements Action {
  public readonly type = EnumModulesActions.RemoveModuleFromHomeSuccess;
  constructor(public payload: RemoveModulePayload) {}
}

export class RemoveModuleFromHomeFailure implements Action {
  public readonly type = EnumModulesActions.RemoveModuleFromHomeFailure;
  constructor(public payload: any) {}
}


export class ChangePolarity implements Action {
  public readonly type = EnumModulesActions.ChangePolarity;
  constructor(public payload: ChangePolarityPayload) { }
}

export class ChangePolaritySuccess implements Action {
  public readonly type = EnumModulesActions.ChangePolaritySuccess;
  constructor(public payload: ChangePolarityPayload) { }
}

export class ChangePolarityFailure implements Action {
  public readonly type = EnumModulesActions.ChangePolarityFailure;
  constructor(public payload: ChangePolarityPayload) { }
}


export type ModulesActions = GetModules
  | GetModulesSuccess
  | GetModulesFailure
  | UpdateModulesGetConfig
  | UpdateModulesSetConfig
  | SetModulesErrors
  | SetModulesStatusErrors
  | UpdateModulesStatus
  | SelectModuleAction
  | UpdateModuleName
  | UpdateModuleNameSuccess
  | UpdateModuleNameFailure
  | UpdateModuleRoom
  | UpdateModuleRoomSuccess
  | UpdateModuleRoomFailure
  | RemoveModuleFromHome
  | RemoveModuleFromHomeSuccess
  | RemoveModuleFromHomeFailure
  | ChangePolarity
  | ChangePolaritySuccess
  | ChangePolarityFailure
  ;
