import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { InputPasswordComponent } from './input-password.component';
import { AtomsModule } from '@library/ui/atoms/atoms.module'

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AtomsModule,
    ],
    exports: [
        InputPasswordComponent
    ],
    declarations: [
        InputPasswordComponent
    ]
})
export class InputPasswordModule { }
