import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultipleSelectionIconComponent } from './multiple-selection-icon/multiple-selection-icon.component';
import { SingleSelectionIconComponent } from './single-selection-icon/single-selection-icon.component';
import { SelectedIconComponent } from './selected-icon/selected-icon.component';
import { AtomsModule } from '@library/ui/atoms/atoms.module'



@NgModule({
  declarations: [
    SelectedIconComponent,
    MultipleSelectionIconComponent,
    SingleSelectionIconComponent,
  ],
  exports: [
    MultipleSelectionIconComponent,
    SingleSelectionIconComponent,
  ],
  imports: [
    CommonModule,
    AtomsModule,
  ]
})
export class SelectionModule { }
