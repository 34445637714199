import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lay-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class PanelComponent {
  @HostBinding('class') get classes() {
    return `${this.styleClass}`;
  }

  @Input() styleClass = '';
}

/**
 * Used in Settings Lux
 */
