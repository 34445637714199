import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StepInputTimeComponent } from './step-input-time.component';

@NgModule({
  declarations: [StepInputTimeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
  ],
  exports: [StepInputTimeComponent]
})
export class StepInputTimeModule { }
