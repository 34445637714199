import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserService } from './user.service';
import {
  GetUser,
  GetUserSuccess,
  GetUserFailure,
} from './user.action';
import { GetUserDataSuccessPayload } from './user.interface';

@Injectable()
export class UserEffects {
  constructor(
    private userService: UserService,
    private actions$: Actions,
  ) { }

  getUser$ = createEffect(() => this.actions$.pipe(
    ofType(GetUser),
    switchMap(() => {
      return this.userService.getUser().pipe(
        map((user: GetUserDataSuccessPayload) => GetUserSuccess({user})),
        catchError(({ error }) => of(GetUserFailure(error)))
      );
    })
  ));
}

