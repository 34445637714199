import { User } from './user.interface';

// tslint:disable-next-line: no-empty-interface
export interface UserState extends User {

}

export const initialUserState: UserState = {
  id: null,
  app_telemetry: null,
  fb_chatbot_available: null,
  mail: null,
  not_associable: null,
  name: null,
  language: 'en-US',
  locale: 'en-US',
  country: 'US',
  unit_pressure: null,
  unit_system: null,
  unit_wind: null,
  all_linked: null,
  linked: [],
  feel_like_algorithm: null,
  type: null,
  is_caller: null,
  pending_user_consent: null
};
