import { Component, OnInit, Input, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lay-panel-footer',
  templateUrl: './panel-footer.component.html',
  styleUrls: ['./panel-footer.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class PanelFooterComponent implements OnInit {

  @Input() styleClass =  '';

  @HostBinding('class') get getClasses() {
    return this.styleClass;
  }

  constructor() { }

  ngOnInit() {
  }

}

/**
 * Used in Settings Lux
 */
