import { DEFAULT_CONTACT_SERVICE, DEFAULT_CONTACT_SERVICE_MIGO } from 'src/assets/constant/app.constant';

/**
 * Function used to select icons based on a device status
 * @param val Wifi strength, radio signal, battery level
 * @returns offline, 100, 75, 50, 25, 0 based on that value
 */
export function categorizeValues(val: number) {
  if (!val) {
    return 'disconnected';
  } else if (val < 50 ) {
    return 'full';
  } else if (val < 75) {
    return '2';
  } else { return '1'; }
}

/**
 * Function used to select icons based on a device status
 * @param val Wifi strength, radio signal, battery level
 * @returns offline, 100, 75, 50, 25, 0 based on that value
 */
export function categorizeRadio(val: number) {
  if (!val) {
    return 'radio_connection_dis-vaillant';
  } else if (val < 60) {
    return 'radio_connection-vaillant';
  } else if (val < 80) {
    return 'radio_connection_level_2-vaillant';
  } else {
    return 'radio_connection_level_1-vaillant';
  }
}

/**
 * Function used to select icons based on the property battery_state
 * Possible values: full, high, medium, low, very_low, undefined
 * @param bat battery state
 * @returns offline, 100, 75, 50, 25, 0 based on that value
 */
export function categorizeBatteryState(bat: string) {
  if (bat === 'full' || bat === 'high') {
    return 'battery_full-vaillant';
  } else if (bat === 'medium') {
    return 'battery_half_full-vaillant';
  } else if (bat === 'low') {
    return 'battery_nearly_empty-vaillant';
  } else if (bat === 'very_low') {
    return 'battery_empty-vaillant';
  } else { return 'battery_empty-vaillant'; }
}

export function retrieveDefaultContactServiceData(countryLowerCase: string, app: string) {
  if (app === 'app_thermostat_vaillant') {
    return DEFAULT_CONTACT_SERVICE[countryLowerCase] || DEFAULT_CONTACT_SERVICE.others;
  } else {
    return DEFAULT_CONTACT_SERVICE_MIGO[countryLowerCase] || DEFAULT_CONTACT_SERVICE.others;
  }
}


