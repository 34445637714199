import { QuizzData, Zone, TimetableSlot, ZoneModule } from './schedules.interface';
import { Home } from '../homes/homes.interface';
import { Room } from '../rooms/rooms.interface';
import { Module } from '../modules/modules.interface';
import { Const } from '@library/utils/constants/Const.constant';

const FP_SETPOINT_COMFORT = 'comfort';
const FP_SETPOINT_ECO = 'away';
const FP_SETPOINT_OFF = 'off';

export class ScheduleModel {
  static dayLength = 24 * 60;
  static weekLength = 7 * ScheduleModel.dayLength;

  nightShiftWeek = false;
  nightShiftWeekEnd = false;

  comfortZone: Zone = { id: 0, name: 'Comfort', rooms: [], modules: [], type: 0 };
  nightZone: Zone = { id: 1, name: 'Night', rooms: [], modules: [], type: 1 };
  comfortPlusZone: Zone = { id: 3, name: 'Comfort+', rooms: [], modules: [], type: 8 };
  ecoZone: Zone = { id: 4, name: 'Eco', rooms: [], modules: [], type: 5 };

  zones: Zone[];

  sundayEnd = 10080; // 7j * 24h * 60min

  // Full timetable with nightzone from monday to sunday
  timetable: TimetableSlot[] = [{
    zone_id: this.nightZone.id,
    m_offset: 0,
    end_offset: this.sundayEnd
  }];

  defaultRoomTemps = {};

  constructor(public quizzData?: QuizzData, public home?: Home, public rooms?: Room[], public modules?: Module[]) {
    this.nightShiftWeek = this.quizzData.dayStart > this.quizzData.dayEnd;
    this.nightShiftWeekEnd = this.quizzData.dayStartWeekend > this.quizzData.dayEndWeekend;

    this.zones = [
      this.comfortZone,
      this.nightZone,
      this.comfortPlusZone,
      this.ecoZone
    ];
    // If cooling schedule
    // => remove confort+ zone and change type of confort, eco and night zone
    if (quizzData.type === 'cooling' || quizzData.type === 'auto') {
      this.zones.splice(2, 2, this.ecoZone);
      this.ecoZone.id = 2;
    }
  }

  /**
   * Constructs the timetable and zones
   * @returns timetable and zones constructed
   */
  saveTimeTable(): { timetable: TimetableSlot[], zones: Zone[] } {
    // Build timetable based on given data
    this.buildTimeTable();

    // Generates list of defaultRoomTemps based on current schedule quiz data
    if (this.quizzData.type === 'cooling') {
      this.constructDefaultRoomTempsCooling();
    } else if (this.quizzData.type === 'auto') {
      this.constructDefaultRoomTempsAuto();
    } else {
      this.constructDefaultRoomTemps();
    }

    // Fill rooms and modules in zones
    for (const zone of this.zones) {
      for (const room of this.rooms) {
        let roomType = room[Const.NARoomInfo.RI_TYPE];
        if (!this.defaultRoomTemps[zone.id][room.type]) {
          roomType = Const.NARoomTypes.RT_CUSTOM;
        }
        // Only add temperature for rooms that supports it.
        if (this.quizzData.type === 'therm' || (this.quizzData.type === 'cooling' && room.hasCoolingDevice) || (this.quizzData.type === 'auto' && room.hasAutoTempDevice)) {
          const tempKey = this.getTemperatureField(room, zone.id);
          if (tempKey) {
            const roomTemp = { id: room[Const.NARoomInfo.RI_ID] };
            roomTemp[tempKey] = this.defaultRoomTemps[zone.id][roomType][tempKey];
            zone.rooms.push(roomTemp);
          }
        }
      }
      // Iterate over modules, to set modules in Schedule, if Home/Modules in home allow it
      for (const module of this.modules) {
        if (module.type === 'NAC' || module.type === 'BAC') {

          if (module.modules_bridged) {
            this.modules.filter((m) => {
              return module.modules_bridged.includes(m.id);
            }).forEach(bridgedModule => {
              // Create module object to be placed in modules array of zone
              const zoneModule: ZoneModule = {
                id: bridgedModule.id,
                bridge: bridgedModule.bridge,
                fan_mode: 'manual',
                fan_speed: 1
              }
              zone.modules.push(zoneModule);
            });
          }

          // Create module object to be placed in modules array of zone
          const zoneModule: ZoneModule = {
            id: module.id,
            fan_mode: 'manual',
            fan_speed: 1
          }

          zone.modules.push(zoneModule);
        }
      }
    }
    return { timetable: this.timetable, zones: this.zones };
  }

  getTemperatureField(room: Room, zoneID: number): string {
    for (const module of this.modules) {
      if (module.room_id === room.id && module.type === 'NLC' && module.appliance_type === 'radiator') {
        return 'therm_setpoint_fp';
      } else if (module.room_id === room.id
        && (module.type === 'NATherm1'
        || module.type === 'NRV'
        || module.type === 'OTM'
        ||( module.type === 'BNTH' && this.quizzData.type === 'therm')
        || (module.type === 'BNS' && this.quizzData.type === 'therm')
        || (module.type === 'BAC' && this.quizzData.type === 'therm')
        || (module.type === 'NAC' && this.quizzData.type === 'therm')
        || (module.type === 'BIRE' && this.quizzData.type === 'therm')
        || module.type === 'NAThermVaillant'
        )) {
        return 'therm_setpoint_temperature';
      }
      if (module.room_id === room.id && this.quizzData.type === 'cooling') {
        // If the zone is Night or Eco the Smarther is off in cooling
        if (zoneID === 1 || zoneID === 2) {
          return 'cooling_setpoint_mode';
        }
        return 'cooling_setpoint_temperature';
      }
      if (module.room_id === room.id && this.quizzData.type === 'auto') {
        // If the zone is Night or Eco the Smarther is off in cooling
        if (zoneID === 1 || zoneID === 2) {
          return 'auto_setpoint_mode';
        }
        return 'auto_setpoint_temperature';
      }

    }
    return null;
  }


  buildTimeTable() {
    // NIGHTSHIFT: Offset all values
    // Manage nightshift hour for week
    if (this.nightShiftWeek) {
      this.quizzData.dayEnd += ScheduleModel.dayLength;

      if (this.quizzData.dayStart > this.quizzData.workStart) {
        this.quizzData.workStart += ScheduleModel.dayLength;
      }

      if (this.quizzData.dayStart > this.quizzData.workEnd) {
        this.quizzData.workEnd += ScheduleModel.dayLength;
      }

      if (this.quizzData.dayStart > this.quizzData.lunchStart) {
        this.quizzData.lunchStart += ScheduleModel.dayLength;
      }

      if (this.quizzData.dayStart > this.quizzData.lunchEnd) {
        this.quizzData.lunchEnd += ScheduleModel.dayLength;
      }
    }

    // Manage nightshift hour for weekend
    if (this.nightShiftWeekEnd) {
      this.quizzData.dayEndWeekend += ScheduleModel.dayLength;

      // Saturday absence
      if (this.quizzData.dayStartWeekend > this.quizzData.saturdayOutsideStart) {
        this.quizzData.saturdayOutsideStart += ScheduleModel.dayLength;
      }

      if (this.quizzData.dayStartWeekend > this.quizzData.saturdayOutsideEnd) {
        this.quizzData.saturdayOutsideEnd += ScheduleModel.dayLength;
      }

      // Sunday absence
      if (this.quizzData.dayStartWeekend > this.quizzData.sundayOutsideStart) {
        this.quizzData.sundayOutsideStart += ScheduleModel.dayLength;
      }

      if (this.quizzData.dayStartWeekend > this.quizzData.sundayOutsideEnd) {
        this.quizzData.sundayOutsideEnd += ScheduleModel.dayLength;
      }
    }

    // Add days from monday to thursday
    for (let i = 0; i < 5; i++) {
      this.addWeekDay(i);
    }

    // Add saturday
    this.addSaturday(5);

    // Add sunday
    this.addSunday(6);

    // Clean timetable
    this.handleTimetableOverflow();
    this.sortTimetable();
    this.loopTimetable();
    this.cleanTimetable();
    this.removeEndOffsetsTimetable();
  }

  addWeekDay(index: number) {
    if (this.quizzData.workAtHome) {
      // DAY
      this.computeNewHeatingRange(
        this.comfortZone,
        this.quizzData.dayStart + ScheduleModel.dayLength * index,
        this.quizzData.dayEnd + ScheduleModel.dayLength * index
      );
    } else {
      // MORNING
      this.computeNewHeatingRange(
        this.comfortZone,
        this.quizzData.dayStart + ScheduleModel.dayLength * index,
        this.quizzData.workStart + ScheduleModel.dayLength * index
      );
      // ECO
      this.computeNewHeatingRange(
        this.ecoZone,
        this.quizzData.workStart + ScheduleModel.dayLength * index,
        this.quizzData.workEnd + ScheduleModel.dayLength * index
      );
      // EVENING
      this.computeNewHeatingRange(
        this.comfortZone,
        this.quizzData.workEnd + ScheduleModel.dayLength * index,
        this.quizzData.dayEnd + ScheduleModel.dayLength * index
      );
    }

    // NIGHT
    this.computeNewHeatingRange(
      this.nightZone,
      this.quizzData.dayEnd + ScheduleModel.dayLength * index,
      this.quizzData.dayStart + ScheduleModel.dayLength * (index + 1)
    );

    // LUNCH TIME
    if (this.quizzData.lunchAtHome) {
      this.computeNewHeatingRange(
        this.comfortZone,
        this.quizzData.lunchStart + ScheduleModel.dayLength * index,
        this.quizzData.lunchEnd + ScheduleModel.dayLength * index
      );
    }
  }

  addSaturday(index: number) {
    if (this.nightShiftWeek === this.nightShiftWeekEnd) {
      // Case : same timezone week and weekend

      // DAY
      if (this.quizzData.saturdayAtHome) {
        // Case: user stay at home
        this.computeNewHeatingRange(
          this.comfortZone,
          this.quizzData.dayStartWeekend + ScheduleModel.dayLength * index,
          this.quizzData.dayEndWeekend + ScheduleModel.dayLength * index,
        );
      } else {
        // Case: user leave home

        // MORNING
        this.computeNewHeatingRange(
          this.comfortZone,
          this.quizzData.dayStartWeekend + ScheduleModel.dayLength * index,
          this.quizzData.saturdayOutsideStart + ScheduleModel.dayLength * index
        );
        // ECO
        this.computeNewHeatingRange(
          this.ecoZone,
          this.quizzData.saturdayOutsideStart + ScheduleModel.dayLength * index,
          this.quizzData.saturdayOutsideEnd + ScheduleModel.dayLength * index
        );
        // EVENING
        this.computeNewHeatingRange(
          this.comfortZone,
          this.quizzData.saturdayOutsideEnd + ScheduleModel.dayLength * index,
          this.quizzData.dayEndWeekend + ScheduleModel.dayLength * index
        );
      }

      // NIGHT
      this.computeNewHeatingRange(
        this.nightZone,
        this.quizzData.dayEndWeekend + ScheduleModel.dayLength * index,
        this.quizzData.dayStartWeekend + ScheduleModel.dayLength * (index + 1),
      );

    } else if (!this.nightShiftWeek && this.nightShiftWeekEnd) {
      // Case: not nightshift week and nightshift weekend

      // DAY
      if (this.quizzData.saturdayAtHome) {
        // Case: user stay at home

        this.insertNewHeatingRange(this.comfortZone,
          this.quizzData.dayStartWeekend + ScheduleModel.dayLength * index,
          this.quizzData.dayEndWeekend + ScheduleModel.dayLength * index,
          true);
      } else {
        // Case: user leave home

        // MORNING
        this.insertNewHeatingRange(
          this.comfortZone,
          this.quizzData.dayStartWeekend + ScheduleModel.dayLength * index,
          this.quizzData.saturdayOutsideStart + ScheduleModel.dayLength * index
        );
        // ECO
        this.insertNewHeatingRange(
          this.ecoZone,
          this.quizzData.saturdayOutsideStart + ScheduleModel.dayLength * index,
          this.quizzData.saturdayOutsideEnd + ScheduleModel.dayLength * index
        );
        // EVENING
        this.insertNewHeatingRange(
          this.comfortZone,
          this.quizzData.saturdayOutsideEnd + ScheduleModel.dayLength * index,
          this.quizzData.dayEndWeekend + ScheduleModel.dayLength * index
        );
      }

      // NIGHT
      // Don't add night zone

    } else if (this.nightShiftWeek && !this.nightShiftWeekEnd) {
      // Case: nightshift week and not nightshift weekend

      // NIGHT
      this.insertNewHeatingRange(this.nightZone,
        this.quizzData.dayEndWeekend + ScheduleModel.dayLength * index,
        this.quizzData.dayStartWeekend + ScheduleModel.dayLength * (index + 1),
        true);
    }
  }

  sortTimetable() {
    // Sort the timetable
    this.timetable.sort((a, b) => {
      if (a.m_offset < b.m_offset) {
        return -1;
      }
      if (a.m_offset === b.m_offset) {
        return 0;
      }
      return 1;
    });
  }

  addSunday(index: number) {
    if (this.nightShiftWeek === this.nightShiftWeekEnd || !this.nightShiftWeek && this.nightShiftWeekEnd) {
      // Case : same timezone week and weekend

      if (this.quizzData.sundayAtHome) {
        // Case: user stay at home

        // DAY
        this.computeNewHeatingRange(
          this.comfortZone,
          this.quizzData.dayStartWeekend + ScheduleModel.dayLength * index,
          this.quizzData.dayEndWeekend + ScheduleModel.dayLength * index
        );
      } else {
        // Case: user leave home

        // MORNING
        this.computeNewHeatingRange(
          this.comfortZone,
          this.quizzData.dayStartWeekend + ScheduleModel.dayLength * index,
          this.quizzData.sundayOutsideStart + ScheduleModel.dayLength * index
        );
        // ECO
        this.computeNewHeatingRange(
          this.ecoZone,
          this.quizzData.sundayOutsideStart + ScheduleModel.dayLength * index,
          this.quizzData.sundayOutsideEnd + ScheduleModel.dayLength * index
        );
        // EVENING
        this.computeNewHeatingRange(
          this.comfortZone,
          this.quizzData.sundayOutsideEnd + ScheduleModel.dayLength * index,
          this.quizzData.dayEndWeekend + ScheduleModel.dayLength * index
        );
      }

      // NIGHT
      if (this.quizzData.dayEndWeekend + ScheduleModel.dayLength * index > this.sundayEnd) {
        // If user go to bed after midnight, he sleep from 0 to next first zone
        this.insertNewHeatingRange(this.nightZone,
          0,
          this.timetable[0].end_offset - 1,
          true);
      } else {
        this.insertNewHeatingRange(this.nightZone,
          this.quizzData.dayEndWeekend + ScheduleModel.dayLength * index,
          10080,
          true);
      }
    } else if (this.nightShiftWeek && !this.nightShiftWeekEnd) {
        // Case: nightshift week and not nightshift weekend

        // DAY
        if (this.quizzData.sundayAtHome) {
          // Case: user stay at home

          this.insertNewHeatingRange(this.comfortZone,
            this.quizzData.dayStartWeekend + ScheduleModel.dayLength * index,
            10080,
            true);
        } else {
          // Case: user leave home

          // MORNING
          this.computeNewHeatingRange(
            this.comfortZone,
            this.quizzData.dayStartWeekend + ScheduleModel.dayLength * index,
            this.quizzData.sundayOutsideStart + ScheduleModel.dayLength * index
          );
          // ECO
          this.computeNewHeatingRange(
            this.ecoZone,
            this.quizzData.sundayOutsideStart + ScheduleModel.dayLength * index,
            this.quizzData.sundayOutsideEnd + ScheduleModel.dayLength * index
          );
          // EVENING
          this.computeNewHeatingRange(
            this.comfortZone,
            this.quizzData.sundayOutsideEnd + ScheduleModel.dayLength * index,
            this.quizzData.dayEndWeekend + ScheduleModel.dayLength * index
          );
        }

        // NIGHT
        // Don't add night zone
    }
  }

  // Move back zones from outside the week at the begining
  handleTimetableOverflow() {
    // Store the zones outside the week
    const newZonesToAdd: { id: number; start: number; stop: number }[] = [];

    // Loop through the timetable
    for (let i = 0; i < this.timetable.length; i++) {
      // If the zone start after the end of the week
      if (this.timetable[i].m_offset >= 7 * ScheduleModel.dayLength) {
        // Store it
        newZonesToAdd.push({
          id: this.timetable[i].zone_id,
          start: this.timetable[i].m_offset %= (7 * ScheduleModel.dayLength),
          stop: this.timetable[i].end_offset %= (7 * ScheduleModel.dayLength)
        });

        // Remove it from the timetable
        this.timetable.splice(i--, 1);

        // If the zone start before end of the week but end after
        // Duplicate the zone at the begining of the week
      } else if (this.timetable[i].end_offset > 7 * ScheduleModel.dayLength) {
        // Store a new zone begining at the very start of the week
        // With the right duration
        newZonesToAdd.push({
          id: this.timetable[i].zone_id,
          start: 0,
          stop: this.timetable[i].end_offset - 7 * ScheduleModel.dayLength
        });

        // Make the last zone end at the very end of the week
        this.timetable[i].end_offset = 7 * ScheduleModel.dayLength;
      }
    }

    // Create the new zones we stored
    for (const newZone of newZonesToAdd) {
      this.computeNewHeatingRange({ id: newZone.id }, newZone.start, newZone.stop);
    }
  }

  loopTimetable() {
    // If the schedule does not start at 0, we add the last of the week as also the first of the week
    if (this.timetable[0].m_offset !== 0) {
      // If it's already the same id, just change the offset
      if (this.timetable[this.timetable.length - 1].zone_id === this.timetable[0].zone_id) {
        this.timetable[0].m_offset = 0;
      } else {
        // push front the new range
        this.timetable.splice(0, 0, { zone_id: this.timetable[this.timetable.length - 1].zone_id, m_offset: 0 });
      }
    }
  }

  constructDefaultRoomTempsCooling() {
    const defaultRoomTempsCooling = {};
    // Comfort
    defaultRoomTempsCooling[this.comfortZone.id] = {
      kitchen:      { cooling_setpoint_temperature: this.quizzData.defaultComfortCooling,     cooling_setpoint_mode: FP_SETPOINT_COMFORT },
      bedroom:      { cooling_setpoint_temperature: this.quizzData.defaultComfortCooling, cooling_setpoint_mode: FP_SETPOINT_COMFORT },
      livingroom:   { cooling_setpoint_temperature: this.quizzData.defaultComfortCooling,     cooling_setpoint_mode: FP_SETPOINT_COMFORT },
      bathroom:     { cooling_setpoint_temperature: this.quizzData.defaultComfortCooling,     cooling_setpoint_mode: FP_SETPOINT_COMFORT },
      lobby:        { cooling_setpoint_temperature: this.quizzData.defaultComfortCooling,     cooling_setpoint_mode: FP_SETPOINT_COMFORT },
      custom:       { cooling_setpoint_temperature: this.quizzData.defaultComfortCooling, cooling_setpoint_mode: FP_SETPOINT_COMFORT },
      toilets:      { cooling_setpoint_temperature: this.quizzData.defaultComfortCooling,     cooling_setpoint_mode: FP_SETPOINT_COMFORT }
    };
    // Night
    defaultRoomTempsCooling[this.nightZone.id] = {
      kitchen:      { cooling_setpoint_temperature: null, cooling_setpoint_mode: FP_SETPOINT_OFF },
      bedroom:      { cooling_setpoint_temperature: null, cooling_setpoint_mode: FP_SETPOINT_OFF},
      livingroom:   { cooling_setpoint_temperature: null, cooling_setpoint_mode: FP_SETPOINT_OFF},
      bathroom:     { cooling_setpoint_temperature: null, cooling_setpoint_mode: FP_SETPOINT_OFF},
      lobby:        { cooling_setpoint_temperature: null, cooling_setpoint_mode: FP_SETPOINT_OFF},
      custom:       { cooling_setpoint_temperature: null, cooling_setpoint_mode: FP_SETPOINT_OFF},
      toilets:      {  cooling_setpoint_temperature: null, cooling_setpoint_mode: FP_SETPOINT_OFF}
    };
    // Eco
    defaultRoomTempsCooling[this.ecoZone.id] = {
      kitchen:      {  cooling_setpoint_temperature: null, cooling_setpoint_mode: FP_SETPOINT_OFF},
      bedroom:      {  cooling_setpoint_temperature: null, cooling_setpoint_mode: FP_SETPOINT_OFF},
      livingroom:   {  cooling_setpoint_temperature: null,  cooling_setpoint_mode: FP_SETPOINT_OFF},
      bathroom:     {  cooling_setpoint_temperature: null, cooling_setpoint_mode: FP_SETPOINT_OFF},
      lobby:        {  cooling_setpoint_temperature: null, cooling_setpoint_mode: FP_SETPOINT_OFF},
      custom:       {  cooling_setpoint_temperature: null, cooling_setpoint_mode: FP_SETPOINT_OFF},
      toilets:      {  cooling_setpoint_temperature: null, cooling_setpoint_mode: FP_SETPOINT_OFF}
    };

    this.defaultRoomTemps = defaultRoomTempsCooling;
  }

  constructDefaultRoomTempsAuto() {
    const defaultRoomTempsAuto = {};
    // Comfort
    defaultRoomTempsAuto[this.comfortZone.id] = {
      kitchen:      { auto_setpoint_temperature: this.quizzData.defaultComfortAuto,     auto_setpoint_mode: FP_SETPOINT_COMFORT },
      bedroom:      { auto_setpoint_temperature: this.quizzData.defaultComfortAuto,     auto_setpoint_mode: FP_SETPOINT_COMFORT },
      livingroom:   { auto_setpoint_temperature: this.quizzData.defaultComfortAuto,     auto_setpoint_mode: FP_SETPOINT_COMFORT },
      bathroom:     { auto_setpoint_temperature: this.quizzData.defaultComfortAuto,     auto_setpoint_mode: FP_SETPOINT_COMFORT },
      lobby:        { auto_setpoint_temperature: this.quizzData.defaultComfortAuto,     auto_setpoint_mode: FP_SETPOINT_COMFORT },
      custom:       { auto_setpoint_temperature: this.quizzData.defaultComfortAuto,     auto_setpoint_mode: FP_SETPOINT_COMFORT },
      toilets:      { auto_setpoint_temperature: this.quizzData.defaultComfortAuto,     auto_setpoint_mode: FP_SETPOINT_COMFORT }
    };
    // Night
    defaultRoomTempsAuto[this.nightZone.id] = {
      kitchen:      { auto_setpoint_temperature: null, auto_setpoint_mode: FP_SETPOINT_OFF },
      bedroom:      { auto_setpoint_temperature: null, auto_setpoint_mode: FP_SETPOINT_OFF},
      livingroom:   { auto_setpoint_temperature: null, auto_setpoint_mode: FP_SETPOINT_OFF},
      bathroom:     { auto_setpoint_temperature: null, auto_setpoint_mode: FP_SETPOINT_OFF},
      lobby:        { auto_setpoint_temperature: null, auto_setpoint_mode: FP_SETPOINT_OFF},
      custom:       { auto_setpoint_temperature: null, auto_setpoint_mode: FP_SETPOINT_OFF},
      toilets:      { auto_setpoint_temperature: null, auto_setpoint_mode: FP_SETPOINT_OFF}
    };
    // Eco
    defaultRoomTempsAuto[this.ecoZone.id] = {
      kitchen:      {  auto_setpoint_temperature: null, auto_setpoint_mode: FP_SETPOINT_OFF},
      bedroom:      {  auto_setpoint_temperature: null, auto_setpoint_mode: FP_SETPOINT_OFF},
      livingroom:   {  auto_setpoint_temperature: null, auto_setpoint_mode: FP_SETPOINT_OFF},
      bathroom:     {  auto_setpoint_temperature: null, auto_setpoint_mode: FP_SETPOINT_OFF},
      lobby:        {  auto_setpoint_temperature: null, auto_setpoint_mode: FP_SETPOINT_OFF},
      custom:       {  auto_setpoint_temperature: null, auto_setpoint_mode: FP_SETPOINT_OFF},
      toilets:      {  auto_setpoint_temperature: null, auto_setpoint_mode: FP_SETPOINT_OFF}
    };

    this.defaultRoomTemps = defaultRoomTempsAuto;
  }

  constructDefaultRoomTemps() {
    const defaultRoomTemps = {};

    // Comfort
    defaultRoomTemps[this.comfortZone.id] = {
      kitchen:      { therm_setpoint_temperature: this.quizzData.defaultComfortTemp,     therm_setpoint_fp: FP_SETPOINT_COMFORT },
      bedroom:      { therm_setpoint_temperature: this.quizzData.defaultComfortTemp, therm_setpoint_fp: FP_SETPOINT_COMFORT },
      livingroom:   { therm_setpoint_temperature: this.quizzData.defaultComfortTemp,     therm_setpoint_fp: FP_SETPOINT_COMFORT },
      bathroom:     { therm_setpoint_temperature: this.quizzData.defaultComfortTemp,     therm_setpoint_fp: FP_SETPOINT_COMFORT },
      lobby:        { therm_setpoint_temperature: this.quizzData.defaultComfortTemp,     therm_setpoint_fp: FP_SETPOINT_COMFORT },
      custom:       { therm_setpoint_temperature: this.quizzData.defaultComfortTemp, therm_setpoint_fp: FP_SETPOINT_COMFORT },
      toilets:      { therm_setpoint_temperature: this.quizzData.defaultComfortTemp,     therm_setpoint_fp: FP_SETPOINT_COMFORT }
    };
    // Night
    defaultRoomTemps[this.nightZone.id] = {
      kitchen: { therm_setpoint_temperature: this.quizzData.defaultComfortTemp - 3, therm_setpoint_fp: FP_SETPOINT_ECO },
      bedroom:      { therm_setpoint_temperature: this.quizzData.defaultComfortTemp - 2, therm_setpoint_fp: FP_SETPOINT_ECO },
      livingroom: { therm_setpoint_temperature: this.quizzData.defaultComfortTemp - 2, therm_setpoint_fp: FP_SETPOINT_ECO },
      bathroom: { therm_setpoint_temperature: this.quizzData.defaultComfortTemp - 3, therm_setpoint_fp: FP_SETPOINT_ECO },
      lobby: { therm_setpoint_temperature: this.quizzData.defaultComfortTemp - 3, therm_setpoint_fp: FP_SETPOINT_ECO },
      custom: { therm_setpoint_temperature: this.quizzData.defaultComfortTemp - 3, therm_setpoint_fp: FP_SETPOINT_ECO },
      toilets: { therm_setpoint_temperature: this.quizzData.defaultComfortTemp - 3, therm_setpoint_fp: FP_SETPOINT_ECO }
    };
    // Comfort +
    defaultRoomTemps[this.comfortPlusZone.id] = {
      kitchen:      { therm_setpoint_temperature: this.quizzData.defaultComfortTemp,     therm_setpoint_fp: FP_SETPOINT_COMFORT },
      bedroom:      { therm_setpoint_temperature: this.quizzData.defaultComfortTemp - 2, therm_setpoint_fp: FP_SETPOINT_COMFORT },
      livingroom:   { therm_setpoint_temperature: this.quizzData.defaultComfortTemp,     therm_setpoint_fp: FP_SETPOINT_COMFORT },
      bathroom:     { therm_setpoint_temperature: this.quizzData.defaultComfortTemp + 2, therm_setpoint_fp: FP_SETPOINT_COMFORT },
      lobby:        { therm_setpoint_temperature: this.quizzData.defaultComfortTemp,     therm_setpoint_fp: FP_SETPOINT_COMFORT },
      custom:       { therm_setpoint_temperature: this.quizzData.defaultComfortTemp - 2, therm_setpoint_fp: FP_SETPOINT_COMFORT },
      toilets:      { therm_setpoint_temperature: this.quizzData.defaultComfortTemp + 2, therm_setpoint_fp: FP_SETPOINT_COMFORT },
    };
    // Eco
    defaultRoomTemps[this.ecoZone.id] = {
      kitchen:      { therm_setpoint_temperature: 16, therm_setpoint_fp: FP_SETPOINT_ECO },
      bedroom:      { therm_setpoint_temperature: 16, therm_setpoint_fp: FP_SETPOINT_ECO },
      livingroom:   { therm_setpoint_temperature: 16, therm_setpoint_fp: FP_SETPOINT_ECO },
      bathroom:     { therm_setpoint_temperature: 16, therm_setpoint_fp: FP_SETPOINT_ECO },
      lobby:        { therm_setpoint_temperature: 16, therm_setpoint_fp: FP_SETPOINT_ECO },
      custom:       { therm_setpoint_temperature: 16, therm_setpoint_fp: FP_SETPOINT_ECO },
      toilets:      { therm_setpoint_temperature: 16, therm_setpoint_fp: FP_SETPOINT_ECO }
    };

    this.defaultRoomTemps = defaultRoomTemps;
  }

  computeNewHeatingRange(zone, start, stop) {
    let index = 0;
    // If start > stop => We create 2 ranges
    if (start > stop) {
      // For the fist range , the new range is from 0 to stop:
      this.insertNewHeatingRange(zone, 0, stop);
      this.insertNewHeatingRange(zone, start, ScheduleModel.weekLength - 1);
    } else { // Else we just insert the new zone into the schedule
      index = this.insertNewHeatingRange(zone, start, stop);
    }
    return index;
  }

  insertNewHeatingRange(zone, start, stop, noClean = false) {
    if (start === stop) {
      return null;
    }

    // If not include, result is false
    const includeZone = this.isIncludeZone(start, stop);
    let index = 0;
    if (includeZone === false) {
      index = this.includeZoneByOverride(zone, start, stop);
    } else {
      index = this.includeZoneIntoAnother(zone, includeZone, start, stop);
    }

    // Remove empty zones and merge zone with same id
    if (!noClean) {
      index = this.cleanTimetable(index);
    }

    return index;
  }

  isIncludeZone(start, stop) {
    for (const i in this.timetable) {
      if (start >= this.timetable[i].m_offset && start <= this.timetable[i].end_offset &&
        stop >= this.timetable[i].m_offset && stop <= this.timetable[i].end_offset) {
          return parseInt(i, 10);
      }
    }
    return false;
  }

  includeZoneByOverride(zone, start, stop) {
    let insertIndex = 0;
    const newZone = {
      zone_id: zone.id,
      m_offset: start,
      end_offset: stop,
    };
    let deleteZone = false;
    let toDelete = 0;
    // tslint:disable-next-line:forin
    for (const i in this.timetable) {
      // Get the index of the first zone
      if (start >= this.timetable[i].m_offset && start <= this.timetable[i].end_offset) {
        // If the start is exactly the same as this zone, we will remove it instead of cutting it
        if (start === this.timetable[i].m_offset) {
          insertIndex = parseInt(i, 10);
          // We increment manually toDelete to delete current one
          toDelete++;
        } else {
          insertIndex = parseInt(i, 10) + 1;
          // as we don't delete the current zone, we change it's end_offset
          this.timetable[i].end_offset = start - 1;
        }
        deleteZone = true;
        continue;
      }

      // Look for the last zone
      if (stop >= this.timetable[i].m_offset && stop <= this.timetable[i].end_offset) {
        // If the end is exactly the same as this zone, we will remove it instead of cutting it
        if (stop === this.timetable[i].end_offset) {
          // We increment manually toDelete to delete current one
          toDelete++;
        } else {
          this.timetable[i].m_offset = stop + 1;
        }

        break;
      }

      if (deleteZone) {
        toDelete++;
      }

    }

    this.timetable.splice(insertIndex, toDelete, newZone);
    return insertIndex;
  }

  includeZoneIntoAnother(zone, index, start, stop) {
    index = parseInt(index, 10);

    // Don't added the new zone if it is already existed
    if (this.timetable[index].m_offset === start &&
      this.timetable[index].end_offset === stop &&
      this.timetable[index].zone_id === zone.id) {
        return index;
    }

    // when we insert a new zone in a new one, we create 3 zones out of one.
    const newZones = [
      // First zone is what's left of the old zone left of the inserted one
      {
        zone_id: this.timetable[index].zone_id,
        m_offset: this.timetable[index].m_offset,
        end_offset: start - 1,
      },
      // Second zone is the inserted one
      {
        zone_id: zone.id,
        m_offset: start,
        end_offset: stop - 1,
      },
      // Third zone is what's left of the old zone right of the inserted zone
      {
        zone_id: this.timetable[index].zone_id,
        m_offset: stop,
        end_offset: this.timetable[index].end_offset,
      },
    ];

    // First we replace the old zone by the new one
    // then we will handle the two more parts around if needed (maybe the new zone is exactly the old one)
    this.timetable.splice(index, 1, newZones[1]);

    // If the left part of the splitted zone is not null in size, we insert it
    if (newZones[0].m_offset < newZones[0].end_offset) {
      this.timetable.splice(index, 0, newZones[0]);
      // Then we increment index to keep index on the new inserted zone
      index++;
    }

    // If the right part of the splitted zone is not null in size, we insert it
    if (newZones[2].m_offset < newZones[2].end_offset) {
      this.timetable.splice(index + 1, 0, newZones[2]);
    }

    return index;
  }

  /**
   * Clean the timetable to remove 0 length ranges and merge contiguous ranges of same type
   */
  cleanTimetable(indexBeforeClean?: number) {
    for (let i = 0; i < this.timetable.length; i++) {
      // All 0 length range are deleted
      if (this.timetable[i].end_offset <= this.timetable[i].m_offset) {
        // As we delete a range before, we decrement the index
        if (typeof indexBeforeClean !== 'undefined' && i < indexBeforeClean) {
          indexBeforeClean--;
        }

        // then remove the 0 length range
        this.timetable.splice(i--, 1);
      } else {
        // All consecutive ranges with the same zone_id are merged
        // tslint:disable-next-line:max-line-length
        if (typeof this.timetable[i - 1] !== 'undefined' && this.timetable[i - 1].zone_id === this.timetable[i].zone_id) {
          // As we are merging two zones with at least one before the index, we decrement it
          if (typeof indexBeforeClean !== 'undefined' && i <= indexBeforeClean) {
            indexBeforeClean--;
          }

          this.timetable[i - 1].end_offset = this.timetable[i].end_offset;
          this.timetable.splice(i--, 1);
        }
      }
    }

    return indexBeforeClean;
  }

  removeEndOffsetsTimetable() {
    for (const table of this.timetable) {
      delete table.end_offset;
    }
  }
}
