import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomSheetComponent } from './bottom-sheet.component';
import { BottomSheetHeaderComponent } from './bottom-sheet-header/bottom-sheet-header.component';
import { PipesModule } from '@library/utils/pipes/pipes.module';

@NgModule({
  declarations: [
    BottomSheetComponent,
    BottomSheetHeaderComponent,
  ],
  exports: [
    BottomSheetComponent,
    BottomSheetHeaderComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
  ]
})
export class BottomSheetModule { }
