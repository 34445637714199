import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AtomsModule } from '@library/ui/atoms/atoms.module'
import { WarningIconComponent } from './warning-icon.component';

@NgModule({
  declarations: [
    WarningIconComponent
  ],
  exports: [
    WarningIconComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AtomsModule
  ]
})
export class WarningIconModule { }
