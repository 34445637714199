import { Component, HostBinding, OnInit } from '@angular/core';

import { Location } from '@angular/common';

import { ConfigFacade } from '@library/store/config/config.facade';
import { UserAgentService } from '@library/utils/services/user-agent.service';
import { CallbackService } from '@library/utils/services/callback.service';
import { HeaderService } from '@library/utils/services/header.service';

@Component({
  selector: 'lay-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

/**
 * Displays a header at the top of the view
 */
export class HeaderComponent implements OnInit {
  /**
   * Text to show in the Header
   */
  public title: string;

  /**
   * Display the back button
   */
  public needBack: boolean;

  /**
   * Display the close button
   */
  public needClose = false;

  @HostBinding('class.webapp-mode') webappMode = this.configFacade.getWebappMode();

  constructor(
    private location: Location,
    public agent: UserAgentService,
    public callback: CallbackService,
    public headerService: HeaderService,
    public configFacade: ConfigFacade,
    ) {}

    ngOnInit() {
      this.headerService.header$.subscribe(header => {
        this.title = header.title;
        this.needBack = header.needBack;
        this.needClose = header.needClose;
      });
    }

    /**
     * Called upon clinking the back button
     * Go back in the history or call the back function if provided
     */
    back(): void {
      this.location.back();
    }

    close(): void {
      this.callback.call(this.callback.list.shouldExit);
    }
  }
