import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ToDisplayValuePipe } from '@library/utils/pipes/to-display-value.pipe';
import { ToUserUnitPipe } from '@library/utils/pipes/to-user-unit.pipe';
import { AsyncLangPipe } from './asynclang.pipe';
import { LangPipe } from './lang.pipe';
import { SanitizePipe } from './sanitize.pipe';
import { SearchPipe } from './search.pipe';

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        LangPipe,
        AsyncLangPipe,
        SanitizePipe,
        ToDisplayValuePipe,
    ],
    exports: [
        SearchPipe,
        LangPipe,
        AsyncLangPipe,
        SanitizePipe,
        CommonModule,
        ToUserUnitPipe,
        ToDisplayValuePipe,
    ],
    declarations: [
        SearchPipe,
        LangPipe,
        AsyncLangPipe,
        SanitizePipe,
        ToUserUnitPipe,
        ToDisplayValuePipe,
    ]
})
export class PipesModule {}
