import { getCurrentHomeId, getHomes } from '@library/store/homes/homes.selector';
import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { HomeVaillant } from './homes.interface';

const homesState = (state: AppState) => state.homes;
const modulesState = (state: AppState) => state.modules;

export const getCurrentHomeVaillant = createSelector(
    getHomes,
    getCurrentHomeId,
    (homes, id) => {
        return homes.find(home => home.id === id) as HomeVaillant;
    }
);

export const getSetpoinDefaultDuration = createSelector(
    getCurrentHomeVaillant,
    (home) => home?.therm_setpoint_default_duration
);

export const getAnticipation = createSelector(
    getCurrentHomeVaillant,
    (home) => !!home?.anticipation
);

export const getHeatingType = createSelector(
    getCurrentHomeVaillant,
    (home) => home?.therm_heating_type ? home.therm_heating_type : 'unknown'
);
