import { NgModule } from '@angular/core';
import { InitLoader } from './init.loader';

@NgModule({
    imports: [],
    declarations: [],
    providers: [
      InitLoader
    ]
})
export class LoadersModule { }
