import { ConfigInterface } from '@library/store/config/config.interface';
import { getAllConfig } from '@library/store/config/config.selectors';
import { getCurrentHomeId } from '@library/store/homes/homes.selector';
import { getCurrentHomeModules, getCurrentModuleId } from '@library/store/modules/modules.selector';
import { createSelector } from '@ngrx/store';
import { moduleDefaults, pidTable } from 'src/assets/constant/app.constant';
import { AppState } from '../app.state';
import { retrieveDefaultContactServiceData } from './modules.functions';
import { ModuleConfigVaillant, ModuleVaillant } from './modules.interface';

const homesState = (state: AppState) => state.homes;
const modulesState = (state: AppState) => state.modules;

export const getCurrentModuleVaillant = createSelector(
  modulesState,
  getCurrentModuleId,
  (state, moduleId) => {
    return state.all.find(module => module.id === moduleId);
  }
);

export const getGatewayOfCurrentModule = createSelector(
  getCurrentModuleVaillant,
  modulesState,
  (currentModule, modules) => {
    if (!currentModule) { return null }
    return currentModule?.modules_bridged ?
    currentModule : modules.all.find(module => module.id === currentModule.bridge)
  }
);

export const checkDeviceExistance = createSelector(
  getCurrentModuleVaillant,
  modulesState,
  (currentModule, modules) => modules.all.some(m => m.id === currentModule.id)
);

export const getCurrentHomeGateway = createSelector(
  getCurrentHomeModules,
  (modules) => {
    if (modules.length < 1) { return null; }
    return modules.find((module) => module.type === 'NAVaillant') as ModuleVaillant || null;
  }
);


/************************************
 *
 * Error management
 *
***********************************/
export const currentThermostatUnreachable = createSelector(
  /**
   * Check if the thermostat is unreachable
   */
  getCurrentModuleVaillant,
  (module) => !module?.reachable
);

export const maintenanceError = createSelector(
  /**
   * Check for maintenance error
   */
  getGatewayOfCurrentModule,
  (module) => module?.boiler_error?.includes('maintenance')

);

export const waterPressureLowError = createSelector(
  /**
   * Check for low water pressure error
   */
  getGatewayOfCurrentModule,
  (module) => module?.boiler_error?.includes('water_pressure')
);

export const internalError = createSelector(
  /**
   * Check for boiler internal error
   */
  getGatewayOfCurrentModule,
  (module) => module?.boiler_error?.includes('boiler_error_internal')
);

export const ebusError = createSelector(
  /**
   * Check for ebus error
   */
  getGatewayOfCurrentModule,
  (module) => module?.ebus_error
);

export const batteryLevel = createSelector(
  /**
   * Return battery state (full, high, low, very_low)
   */
  getCurrentModuleVaillant,
  (module) => {
    return module?.battery_state;
  }
);

export const currentModuleReachabilityErrors = createSelector(
  /*
   * if exists, return the error object from homeStatus of the current module.
   */
  getGatewayOfCurrentModule,
  (gateway) => {
    if (gateway?.error?.code === 6) {
      return gateway.error;
    } else {
      return undefined;
    }
  }
);

export const currentModuleErrors = createSelector(
  /*
   * Other module errors that are not checked elsewhere.
   */
  getGatewayOfCurrentModule,
  (gateway: ModuleVaillant) => {
    if (gateway?.error?.code !== 6 || gateway?.boiler_error?.length > 0) {
      return gateway.boiler_error;
    } else {
      return undefined;
    }
  }
);

export const getMultiErrors = createSelector(
  /**
   * We check if we have at least one error
   */
  currentThermostatUnreachable,
  currentModuleReachabilityErrors,
  (thermostat, gateway) => {
    return [
      thermostat,
      gateway,
    ].filter(e => e).length > 0;
  }
);

/********************************
 *
 * Heating system info
 *
 *******************************/
export const getGatewayConfigVaillant = createSelector(
  /**
   * Get the config from the current gateway
   */
  homesState,
  modulesState,
  (homes, modules) => {
    const gateway = modules.all.find(module =>
      module.type === 'NAVaillant' && module.homeId === homes.currentHomeId
    );
    return gateway.config as ModuleConfigVaillant;
  }
);

export const getSlope = createSelector(
  getCurrentModuleVaillant,
  modulesState,
  (module, state) => {
    const id = module.bridge || module.id;
    const gateway = state.all.find(m => m.id === id);
    if (!gateway || !gateway.heating_curve_slope) { return moduleDefaults.heatingCurveDefault; }
    return gateway.heating_curve_slope;
  }
);

export const getThermHeatingAlgorithm = createSelector(
  /**
   * Get the currently used heating algo
   */
  getCurrentModuleVaillant,
  modulesState,
  (module, state) => {
    const id = module.bridge || module.id;
    const gateway = state.all.find(m => m.id === id);
    return !gateway.therm_heating_algorithm ? 'simple_algo' : gateway?.therm_heating_algorithm;
  }
);

export const getPidPeriod = createSelector(
  getCurrentModuleVaillant,
  modulesState,
  (module, state) => {
    const period = pidTable;
    const id = module.bridge || module.id;
    const gateway = state.all.find(m => m.id === id);
    if (!gateway || !gateway.pid_algo_period) {
      return null;
    }
    return period.get(gateway.pid_algo_period);
  }
);

export const getDeadband = createSelector(
  /**
   * Get the simple heating algo deadband
   */
  getCurrentModuleVaillant,
  modulesState,
  (module, state) => {
    const id = module.bridge || module.id;
    const gateway = state.all.find(m => m.id === id);

    return !gateway.simple_heating_algo_deadband ? moduleDefaults.simpleAlgoDeadband : (gateway.simple_heating_algo_deadband + 1) / 10;
  }
);


export const getDHWStorage = createSelector(
  getGatewayOfCurrentModule,
  (gateway: ModuleVaillant) => {
    if (!gateway) {
      return null
    } else {
      return gateway.dhw_control ? gateway.dhw_control : null;
    }
  }
);

/*************************
 *
 * Contact info
 *
 *************************/
export const getContactEmail = createSelector(
  homesState,
  getGatewayOfCurrentModule,
  getAllConfig,
  (state, gateway: ModuleVaillant, config: ConfigInterface) => {
    const homes = state.homes;
    const home = homes.find(h => h.id === state.currentHomeId);
    if (!gateway || !gateway.contact_service_email) {
      return retrieveDefaultContactServiceData(home.place.country.toLowerCase(), config.appName).MAIL;
    }
    return gateway.contact_service_email;
  }
);

export const getContactPhone = createSelector(
  homesState,
  getGatewayOfCurrentModule,
  getAllConfig,
  (state, gateway: ModuleVaillant, config: ConfigInterface) => {
    const homes = state.homes;
    const home = homes.find(h => h.id === state.currentHomeId);
    if (!gateway || !gateway.contact_service_phone) {
      return retrieveDefaultContactServiceData(home.place.country.toLowerCase(), config.appName).PHONE;
    }
    return gateway.contact_service_phone;
  }
);

export const getContactName = createSelector(
  homesState,
  getGatewayOfCurrentModule,
  getAllConfig,
  (state, gateway: ModuleVaillant, config: ConfigInterface) => {
    const homes = state.homes;
    const home = homes.find(h => h.id === state.currentHomeId);
    if (!gateway || !gateway.contact_service_name) {
      return retrieveDefaultContactServiceData(home.place.country.toLowerCase(), config.appName).NAME;
    }
    return gateway.contact_service_name;
  }
);
