import { Injectable } from '@angular/core';
import { EnumScales, TimeRange } from '@library/store/measures/measures.interface'


@Injectable()
export class UtilsService {
  constructor() {}

  /**
   * Takes a time in string format (ex: '00:00') and returns an array of numbers.
   * The first number is the hour and the second one is the minutes.
   * @param time String of time we want to convert
   */
  timeToHoursMin(time: string): number[] {
    return time.split(/[.:]/).map(t => parseInt(t, 10));
  }

  /**
   * Takes a time in string format (ex: '00:00') and returns this time in minutes
   * @param time String of the time we want to convert
   */
  timeToNumber(time: string): number {
    return parseInt(time.substring(0, 2), 10) * 60 + parseInt(time.substring(3, 5), 10);
  }

  /**
   * Takes a time in minutes and returns this time string format (ex: '00:00')
   * @param numberToConvert Number of minutes of the time we want to convert
   */
  numberToTime(numberToConvert: number, delimeter = ':'): string {
    const hoursNumber = Math.floor(numberToConvert / 60);
    const minutesNumber = numberToConvert % 60;

    const hours = hoursNumber < 10 ? '0' + hoursNumber : hoursNumber;
    const minutes = minutesNumber < 10 ? '0' + minutesNumber : minutesNumber;

    return hours + delimeter + minutes;
  }

  /**
   * Check if two numbers are equal
   * @param first First number
   * @param sec Second number
   */
  equalNumbers(first: number, sec: number): boolean {
    return first === sec;
  }

  /**
   * Checks if a number is between the two others
   * @param numberToCheck Number to check
   * @param min First number
   * @param max Second number
   */
  betweenNumbers(numberToCheck: number, min: number, max: number): boolean {
    return numberToCheck >= min && numberToCheck <= max;
  }

  /**
   * Rounds the number to the nearest step
   * @param numberToRound Number to round
   * @param step Step
   * @param decimals Number of decimals of the step
   */
  roundToNearestStep(numberToRound: number, step: number, decimals = 1): number {
    // Use a power of 10 to avoid JS floating point calculation errors
    const powerOfTen = Math.pow(10, decimals);
    const localComfortNumber = Math.trunc(numberToRound * powerOfTen);
    const localStep = step * powerOfTen;
    const r = localComfortNumber % localStep;

    if (r <= localStep / 2) {
      return (localComfortNumber - r) / powerOfTen;
    } else if (r > localStep / 2) {
      return (localComfortNumber + localStep - r) / powerOfTen;
    }
  }

  /**
   * Get params of the url given in parameters or current location by default
   * @param name Name of parameters
   * @param url
   */
  getParameterByName(name: string, url: string = null) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  /**
   * This function will convert a string (date) to
   * timestamp with the time (00:00 or 23:59)
   * @param date the string which contains date to convert in timestamp
   */
  stringToTimeStamp(date: string, time: string) {
    if (date) {
      const newDate = new Date(date);

      if (time) {
        const timeHoursMin = time.split(':')
        if (timeHoursMin.length === 2) {
          newDate.setHours(parseInt(timeHoursMin[0]))
          newDate.setMinutes(parseInt(timeHoursMin[1]))
        }
      }
      return newDate.getTime() * 0.001; // milliseconds -> seconds
    }
    return null;
  }

  capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.substr(1);
  }

  removeEmptyValues(obj) {
    // if the current object is not an array
    if (typeof obj === 'string' || obj instanceof String) {
        return obj;
    }
    else if (typeof obj === 'number' || obj instanceof Number) {
        return obj;
    }
    else if (!Array.isArray(obj)) {
        // declare new object
        const newObj = {};
        // iterate over its key
        Object.keys(obj).forEach((key) => {
            if (obj[key] && typeof obj[key] === 'object') {
                // if current value is truthy and is an object, recurse
                newObj[key] = this.removeEmptyValues(obj[key]);
            } else if (obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
                // if current value is truthy and is not an object, copy the value
                // this will therefore remove falsy values (null, empty strings...)
                newObj[key] = obj[key];
            }
            if (typeof newObj[key] === 'object' && Object.keys(newObj[key]).length === 0) {
                // if value is an object and has no keys, delete it
                delete(newObj[key]);
            }
        });
        return newObj;
    } else {
        // declare new array
        const newArray = [] ;
        obj.forEach((value) => {
            // recursively remove the array entry's empty values
            const parsedArrayValue = this.removeEmptyValues(value);
            // if the entry is an object and has at least one key, add it to the array
            if (typeof parsedArrayValue === 'object' && Object.keys(parsedArrayValue).length > 0) {
                newArray.push(parsedArrayValue);
            }
            else if (typeof parsedArrayValue === 'string' || parsedArrayValue instanceof String) {
                newArray.push(parsedArrayValue)
            }
            else if (typeof parsedArrayValue === 'number' || parsedArrayValue instanceof Number) {
                newArray.push(parsedArrayValue)
            }
        });
        return newArray;
    }
}


}
