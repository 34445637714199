window.NAConfig = {
  appUrl: window.location.origin,
  invitationUrl: 'https://auth.vg-thermostat.com/invitation/home/',
  prodApiUrl: 'https://app.netatmo.net',
  cookiePrefix: 'vgthermostatcom',
  webPusherUrl: 'wss://app.netatmo.net/ws/',
  langsUrl: "https://settings.vg-thermostat.com/langs/",
  helpcenterUrl: "https://helpcenter.netatmo.com/",
};

NAConfig.isProdDomain = NAConfig.prodAppUrl === NAConfig.appUrl;

NAConfig.apiUrl = NAConfig.prodApiUrl + '/api/';
NAConfig.syncApiUrl = NAConfig.prodApiUrl + '/syncapi/v1/';
