import { Component, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EnumHomesActions } from '@library/store/homes/homes.action';
import { Loader } from '@library/utils/classes/loader';
import { HomesFacadeVaillant } from 'app/stores/homes/homes.facade';
import { ModulesFacadeVaillant } from 'app/stores/modules/modules.facade';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewComponent } from '../../view.component';


@Component({
  selector: 'app-device-list-view',
  templateUrl: './home-routes-view.component.html',
  styleUrls: ['./home-routes-view.component.scss'],
})
export class HomeRoutesViewComponent extends ViewComponent implements OnDestroy {
  viewModel$ = combineLatest([
    this.homesFacade.currentHome$,
    this.modulesFacade.currentModule$,
    this.modulesFacade.gatewayOfCurrentModule$,
  ]).pipe(
    map(([home, thermostat, gateway]) => ({ home, thermostat, gateway }))
  );

  /**
   * Subscription for the loader
   */
  statusSubscription: Subscription;

  /**
   * Loader handling the spinning of the thermostat button
   */
  confirmLoader = new Loader(this.actions);

  constructor(
    injector: Injector,
    private homesFacade: HomesFacadeVaillant,
    private modulesFacade: ModulesFacadeVaillant,
    protected router: Router,
  ) {
    super(injector);
  }

  /**
   * We select the thermostat and navigate to heating system view, users view or thermostat view based on nextRoute
   * We wait for the homestatus, homeconfig or homeusers request to finish
   * @param param0 nextRoute: The url of the next view (heatingsystem/, users/ or device/)
   * @param param1 thermostat: The current thermostat
   */
  getNextView({ nextRoute, thermostat }) {
    if (thermostat) {
      if (nextRoute === 'device/') {
        // Load home status for heating system view
        this.statusSubscription = this.homesFacade.loadHomeStatus({ home_id: thermostat.homeId }).subscribe(() => {
          // Listen for actions to wait for GetHomeStatus result
          this.confirmLoader.loadUntil([EnumHomesActions.GetHomeStatusSuccess, EnumHomesActions.GetHomeStatusFailure]);
          this.router.navigate([nextRoute, thermostat.id], {
            relativeTo: this.activatedRoute,
          });
        });
      }
      else if (nextRoute === 'heatingsystem/') {
        // Load home status and home config for heating system view
        this.statusSubscription = forkJoin({
          status: this.homesFacade.loadHomeStatus({ home_id: thermostat.homeId}),
          config: this.homesFacade.loadHomeConfig({home_id: thermostat.homeId})
        }).subscribe(() => {
          // Listen for actions to wait for GetHomeConfig result
          this.confirmLoader.loadUntil([EnumHomesActions.GetHomeConfigSuccess, EnumHomesActions.GetHomeConfigFailure]);
          this.router.navigate([nextRoute, thermostat.id], {
            relativeTo: this.activatedRoute,
          });
        });
      }
      else if (nextRoute === 'users/') {
        // Load home users for device users view
        this.statusSubscription = this.homesFacade.loadHomeUsers(thermostat.homeId).subscribe(() => {
          // Listen for actions to wait for GetHomeUsers result
          this.confirmLoader.loadUntil([EnumHomesActions.GetHomeUsersSuccess, EnumHomesActions.GetHomeUsersFailure]);
          this.router.navigate([nextRoute, thermostat.id], {
            relativeTo: this.activatedRoute,
          });
        });
      }
    }
  }

  addDevice() {
    this.callback.call(this.callback.list.needsNewInstall);
  }

  ngOnDestroy() {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }
}
