<ng-container *ngIf="(data$ | async) as data">

    <form [formGroup]="mailForm">

        <lay-panel styleClass="flex-column">

            <lay-panel-content>

                <lay-panel-block size="large">

                    <app-text class="is-family-regular is-size-7" [text]="getTopMessageText(data.config)">
                    </app-text>

                </lay-panel-block>

                <lay-panel-block size="fluid">

                    <lay-title>

                        <app-text class="is-family-semiBold "
                            [text]="(crowdins[data.config.appName] + '.__CONTACT_BY_EMAIL') | LANG">
                        </app-text>

                    </lay-title>

                </lay-panel-block>

                <lay-panel-block size="large">
                    <div class="is-block is-fullwidth">
                        <app-text [text]="'common-settings.__CONTACT_OBJECT' | LANG"></app-text>
                        <app-input-text
                            updateOn="change"
                            formControlName="object">
                        </app-input-text>
                    </div>

                </lay-panel-block>

                <lay-panel-block size="fluid">

                    <div class="is-block is-fullwidth">

                        <app-text class="title input-title" [text]="'common-settings.__CONTACT_MESSAGE' | LANG">
                        </app-text>

                        <textarea formControlName="message"></textarea>

                    </div>

                </lay-panel-block>

            </lay-panel-content>

            <lay-panel-footer *ngIf="mailForm.valid">

                <lay-button
                    [url]="sendMessage(data.user, data.regLocale, data.config, data.device, data.gateway)"
                    [inBrowser]="true"
                    class="is-flex-button">

                  <button
                    type="submit"
                    [ngClass]="{
                        'is-primary-button': true,
                        'has-text-white': true
                    }">
                    <app-text
                      type="submit"
                      [text]="'common-settings.__SEND' | LANG">
                    </app-text>
                  </button>

                </lay-button>

              </lay-panel-footer>

        </lay-panel>

    </form>

</ng-container>
