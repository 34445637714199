import { HomeVaillant } from './homes.interface';

const emptyHomes: HomeVaillant[] = [];

export interface HomesStateVaillant {
    homes: HomeVaillant[];
    currentHomeId: string;
    loading: boolean;
    /**
     * For network error 400
     * Used to show/hide the error modal
     */
    errorModalDisplay: boolean;
}

export const initialHomesStateVaillant: HomesStateVaillant = {
    homes: emptyHomes,
    currentHomeId: null,
    loading: false,
    errorModalDisplay: false,
};
