import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { LangFacade } from '@library/store/lang/lang.facade';


@Pipe({ name: 'AsyncLang' })
export class AsyncLangPipe implements PipeTransform {

  constructor(
    private langFacade: LangFacade,
    ) {}

  transform(value: string, args: string[] = []): Observable<string> {
    return this.langFacade.translate(value, args)
  }
}
