import { Module, ModuleData } from '@library/store/modules/modules.interface';
import { Action } from '@ngrx/store';
import { User } from '../user/user.interface';
import {
  AckLocalResourcesPayload,
  GetHomeInfoPayload,
  Home,
  HomeData,
  HomeSyncPayload,
  HomesNameUpdate,
  LoadForecastPayload,
  LoadForecastSuccessPayload,
  Place,
  PlaceUpdate,
  SetHomeConfigPayload,
  SetStatePayload,
  SyncError
} from './homes.interface';

export enum EnumHomesActions {
  GetHomes = '[Homes] Get Homes data',
  GetHomesSuccess = '[Homes] Get Homes data success',
  GetHomesFailure = '[Homes] Get Homes data failure',
  UpdateHomePlace = '[Homes] Update home place data',
  UpdateHomePlaceSuccess = '[Homes] Update home place data success',
  UpdateHomePlaceFailure = '[Homes] Update home place data failure',
  HomeSelected = '[Homes] Set current home ID',
  GetHomeStatus = '[Homes] Get Homes status',
  GetHomeStatusFailure = '[Homes] Get Homes status failure',
  GetHomeStatusSuccess = '[Homes] Get Homes status success',
  GetReadonlyDeviceState = '[Homes] Get Readonly Device State',
  GetReadonlyDeviceStateSuccess = '[Homes] Get Readonly Device State Success',
  GetReadonlyDeviceStateFailure = '[Homes] Get Readonly Device State Failure',
  GetHomeConfig = '[Homes] Get Homes config',
  GetHomeConfigFailure = '[Homes] Get Homes config failure',
  GetHomeConfigSuccess = '[Homes] Get Homes config success',
  SetConfig = '[Homes] Set home gateway config',
  SetConfigFailure = '[Homes] Set home gateway config failure',
  SetConfigSuccess = '[Homes] Set home config success',
  GetHomeUsers = '[Homes] Get Homes users',
  GetHomeUsersSuccess = '[Homes] Get Homes users success',
  GetHomeUsersFailure = '[Homes] Get Homes users failure',
  RemoveUserFromHome = '[Homes] Remove user from home',
  RemoveUserFromHomeSuccess = '[Homes] Remove user from home success',
  RemoveUserFromHomeFailure = '[Homes] Remove user from home failure',
  UpdateHomeName = '[Homes] Update Name Of Home',
  UpdateHomeNameSuccess = '[Homes] Update Name Of Home Success',
  UpdateHomeNameFailure = '[Homes] Update Name Of Home Failure',
  GetAdminAccessCode = '[Homes] Get admin access code',
  GetAdminAccessCodeSuccess = '[Homes] Get admin access code success',
  GetAdminAccessCodeFailure = '[Homes] Get admin access code failure',
  SetState = '[Homes] Set home state',
  SetStateSuccess = '[Homes] Set home state success',
  SetStateErrors = '[Homes] Set home state error',
  SetStateFailure = '[Homes] Set home state failure',
  ShowSpinner = '[Homes] Show button spinner',
  HideSpinner = '[Homes] Hide button spinner',
  ShowErrorModal = '[Homes] Show error modal',
  HideErrorModal = '[Homes] Hide error modal',
  SetTemperatureControlMode = '[Homes] Set temperature control mode',
  SetTemperatureControlModeSuccess = '[Homes] Set temperature control success',
  SetTemperatureControlModeFailure = '[Homes] Set temperature control failure',
  GetHomeAddressSuccess = '[Homes] Successfully retrieved Home Address',
  GetHomeAddressFailure = '[Homes] Failed to retrieve Home Address',
  GetHomeNFA = '[Homes] Retrieving Home NFA',
  GetHomeNFASuccess = '[Homes] Successfully retrieved Home NFA',
  GetHomeNFAFailure = '[Homes] Failed to retrieve Home NFA',
  RemoveRoomFromHome = '[Homes] Removing Room from Home',
  SetHomeType = '[Homes] Set home type',
  SetHomeTypeSuccess = '[Homes] Set home type success',
  SetHomeTypeFailure = '[Homes] Set home type failure',
  RemoveDeviceFromHome = '[Homes] Removing device from Home',
  RemoveDeviceFromHomeSuccess = '[Homes] Removing device from Home success ',
  RemoveDeviceFromHomeFailure = '[Homes] Removing device from Home failure',
  SetThermMode =  '[Homes] Set therm mode',
  SetThermModeSuccess = '[Homes] Set therm mode success',
  SetThermModeFailure = '[Homes] Set therm mode failure',
  SetThermPoint =  '[Homes] Set therm point',
  SetThermPointSuccess = '[Homes] Set therm point success',
  SetThermPointFailure = '[Homes] Set therm point failure',
  SetHeatingMode = '[Homes] Set thermoregulation heating mode',
  SetHeatingModeSuccess = '[Homes] Set thermoregulation heating mode success',
  SetHeatingModeFailure = '[Homes] Set thermoregulation heating mode success failure',
  SetCoolingMode = '[Homes] Set thermoregulation cooling mode',
  SetCoolingModeSuccess = '[Homes] Set thermoregulation cooling mode success',
  SetCoolingModeFailure = '[Homes] Set thermoregulation cooling mode success failure',
  SetAutoMode = '[Homes] Set thermoregulation auto mode',
  SetAutoModeSuccess = '[Homes] Set thermoregulation auto mode success',
  SetAutoModeFailure = '[Homes] Set thermoregulation auto mode success failure',
  AckLocalResources = '[Homes] Ack local resources',
  AckLocalResourcesSuccess = '[Homes] Ack local resources success',
  AckLocalResourcesFailure = '[Homes] Ack local resources success',
  LoadForecast = '[Homes] Load Forecast',
  LoadForecastSuccess = '[Homes] Load Forecast Success',
  LoadForecastFailure = '[Homes] Load Forecast Failure',
}

export class GetHomes implements Action {
  public readonly type = EnumHomesActions.GetHomes;
  constructor(public payload: {}) { }
}

export class SelectHome implements Action {
  public readonly type = EnumHomesActions.HomeSelected;
  constructor(public payload: string) { }
}

export class GetHomesSuccess implements Action {
  public readonly type = EnumHomesActions.GetHomesSuccess;
  constructor(public payload: HomeData[], public modules?: ModuleData[]) { }
}

export class GetHomesFailure implements Action {
  public readonly type = EnumHomesActions.GetHomesFailure;
  constructor(public payload: {}) { }
}

export class UpdateHomePlace implements Action {
  public readonly type = EnumHomesActions.UpdateHomePlace;
  constructor(public payload: PlaceUpdate) { }
}

export class UpdateHomePlaceSuccess implements Action {
  public readonly type = EnumHomesActions.UpdateHomePlaceSuccess;
  constructor(public payload: { updatedHome: Place, home_id: string }) { }
}

export class UpdateHomePlaceFailure implements Action {
  public readonly type = EnumHomesActions.UpdateHomePlaceFailure;
  constructor(public payload: {}) { }
}

export class GetHomeStatus implements Action {
  public readonly type = EnumHomesActions.GetHomeStatus;
  constructor(public payload: GetHomeInfoPayload) { }
}
export class GetHomeStatusSuccess implements Action {
  public readonly type = EnumHomesActions.GetHomeStatusSuccess;
  constructor(public payload: HomeSyncPayload) { }
}

export class GetHomeStatusFailure implements Action {
  public readonly type = EnumHomesActions.GetHomeStatusFailure;
  constructor(public payload: {}) { }
}

export class GetReadonlyDeviceState implements Action {
  public readonly type = EnumHomesActions.GetReadonlyDeviceState;
  constructor() { }
}
export class GetReadonlyDeviceStateSuccess implements Action {
  public readonly type = EnumHomesActions.GetReadonlyDeviceStateSuccess;
  constructor(public modules: Module[], public errors: SyncError[]) { }
}

export class GetReadonlyDeviceStateFailure implements Action {
  public readonly type = EnumHomesActions.GetReadonlyDeviceStateFailure;
  constructor(public payload: {}) { }
}

export class GetHomeConfig implements Action {
  public readonly type = EnumHomesActions.GetHomeConfig;
  constructor(public payload: GetHomeInfoPayload) { }
}

export class GetHomeConfigFailure implements Action {
  public readonly type = EnumHomesActions.GetHomeConfigFailure;
  constructor(public payload: {}) { }
}

export class GetHomeConfigSuccess implements Action {
  public readonly type = EnumHomesActions.GetHomeConfigSuccess;
  constructor(public payload: HomeSyncPayload) { }
}

export class SetConfig implements Action {
  public readonly type = EnumHomesActions.SetConfig;
  constructor(public payload: SetHomeConfigPayload) { }
}

export class SetConfigFailure implements Action {
  public readonly type = EnumHomesActions.SetConfigFailure;
  constructor(public payload: {}) { }
}

export class GetHomeUsers implements Action {
  public readonly type = EnumHomesActions.GetHomeUsers;
  constructor(public payload: string) { }
}

export class GetHomeUsersSuccess implements Action {
  public readonly type = EnumHomesActions.GetHomeUsersSuccess;
  constructor(public payload: { id: string, users: {[key: string]: User} }) { }
}

export class GetHomeUsersFailure implements Action {
  public readonly type = EnumHomesActions.GetHomeUsersFailure;
  constructor(public payload: {}) { }
}

export class RemoveUserFromHome implements Action {
  public readonly type = EnumHomesActions.RemoveUserFromHome;
  constructor(public payload: {homeId: string, userId: string}) {}
}

export class RemoveUserFromHomeSuccess implements Action {
  public readonly type = EnumHomesActions.RemoveUserFromHomeSuccess;
  constructor(public payload: {homeId: string, userId: string}) {}
}

export class RemoveUserFromHomeFailure implements Action {
  public readonly type = EnumHomesActions.RemoveUserFromHomeFailure;
  constructor(public payload: {}) {}
}

export class UpdateHomeName implements Action {
  public readonly type = EnumHomesActions.UpdateHomeName;
  constructor(public payload: HomesNameUpdate) {}
}

export class UpdateHomeNameSuccess implements Action {
  public readonly type = EnumHomesActions.UpdateHomeNameSuccess;
  constructor(public payload: HomesNameUpdate) {}
}

export class UpdateHomeNameFailure implements Action {
  public readonly type = EnumHomesActions.UpdateHomeNameFailure;
  constructor(public payload: {}) {}
}

export class GetAdminAccessCode implements Action {
  public readonly type = EnumHomesActions.GetAdminAccessCode;
  constructor(public payload: string) {}
}

export class GetAdminAccessCodeSuccess implements Action {
  public readonly type = EnumHomesActions.GetAdminAccessCodeSuccess;
  constructor(public payload: {homeId: string, accessCode: string}) {}
}

export class GetAdminAccessCodeFailure implements Action {
  public readonly type = EnumHomesActions.GetAdminAccessCodeFailure;
  constructor(public payload: {}) {}
}

export class ShowSpinner implements Action {
  public readonly type = EnumHomesActions.ShowSpinner;
}

export class HideSpinner implements Action {
  public readonly type = EnumHomesActions.HideSpinner;
}

export class ShowErrorModal implements Action {
  public readonly type = EnumHomesActions.ShowErrorModal;
}

export class HideErrorModal implements Action {
  public readonly type = EnumHomesActions.HideErrorModal;
}

export class SetConfigSuccess implements Action {
  public readonly type = EnumHomesActions.SetConfigSuccess;
}
export class SetState implements Action {
  public readonly type = EnumHomesActions.SetState;
  constructor(public payload: SetStatePayload, public header?: { [key: string]: string }) { }
}

export class SetStateSuccess implements Action {
  public readonly type = EnumHomesActions.SetStateSuccess;
}
export class SetStateErrors implements Action {
  public readonly type = EnumHomesActions.SetStateErrors;
  constructor(public payload: {errors: SyncError[]}) { }

}
export class SetStateFailure implements Action {
  public readonly type = EnumHomesActions.SetStateFailure;
  constructor(public payload: {}) { }
}
export class SetTemperatureControlMode implements Action {
  public readonly type = EnumHomesActions.SetTemperatureControlMode;
  constructor(public payload: { home: Partial<Home> }) { }
}

export class SetTemperatureControlModeSuccess implements Action {
  public readonly type = EnumHomesActions.SetTemperatureControlModeSuccess;
  constructor(public payload: { home: Partial<Home> }) { }
}

export class SetTemperatureControlModeFailure implements Action {
  public readonly type = EnumHomesActions.SetTemperatureControlModeFailure;
  constructor(public payload: {}) { }
}

export class GetHomeAddressSuccess implements Action {
  public readonly type = EnumHomesActions.GetHomeAddressSuccess;
  constructor(public payload: {address: string, home_id: string}) {}
}

export class GetHomeAddressFailure implements Action {
  public readonly type = EnumHomesActions.GetHomeAddressFailure;
  constructor(public payload: any) {}
}

export class GetHomeNFA implements Action {
  public readonly type = EnumHomesActions.GetHomeNFA;
  constructor(public payload: {home_id: string, app_type: string}) {}
}

export class GetHomeNFASuccess implements Action {
  public readonly type = EnumHomesActions.GetHomeNFASuccess;
  constructor(public payload: any) {}
}

export class GetHomeNFAFailure implements Action {
  public readonly type = EnumHomesActions.GetHomeNFAFailure;
  constructor(public payload: any) {}
}

export class RemoveRoomFromHome implements Action {
  public readonly type = EnumHomesActions.RemoveRoomFromHome;
  constructor(public payload: {home_id: string, room_id: string}) {}
}

export class SetHomeType implements Action {
  public readonly type = EnumHomesActions.SetHomeType;
  constructor(public payload: {home: {id: string, home_type: string}}) { }
}

export class SetHomeTypeSuccess implements Action {
  public readonly type = EnumHomesActions.SetHomeTypeSuccess;
  constructor(public payload: {home: {id: string, home_type: string}}) { }
}

export class SetHomeTypeFailure implements Action {
  public readonly type = EnumHomesActions.SetHomeTypeFailure;
  constructor(public payload: {}) { }
}

export class RemoveDeviceFromHome implements Action {
  public readonly type = EnumHomesActions.RemoveDeviceFromHome;
  constructor(public payload: {home_id: string, device_id: string}) {}
}

export class RemoveDeviceFromHomeSuccess implements Action {
  public readonly type = EnumHomesActions.RemoveDeviceFromHomeSuccess;
  constructor(public payload: {home_id: string, device_id: string}) {}
}

export class RemoveDeviceFromHomeFailure implements Action {
  public readonly type = EnumHomesActions.RemoveDeviceFromHomeFailure;
  constructor(public payload: {}) {}
}

export class SetThermMode implements Action {
  public readonly type = EnumHomesActions.SetThermMode;
  constructor(public homeId: string, public therm_mode: string, public endtime?: number) {}
}

export class SetThermModeSuccess implements Action {
  public readonly type = EnumHomesActions.SetThermModeSuccess;
  constructor(public homeId: string, public therm_mode: string, public endtime?: number) {}
}

export class SetThermModeFailure implements Action {
  public readonly type = EnumHomesActions.SetThermModeFailure;
  constructor(public error) {}
}

export class SetThermPoint implements Action {
  public readonly type = EnumHomesActions.SetThermPoint;
  constructor(public params: {home_id: string, room_id: string, mode: string, endtime: number, temp: number}) {}
}

export class SetThermPointSuccess implements Action {
  public readonly type = EnumHomesActions.SetThermPointSuccess;
  constructor(public params: {home_id: string, room_id: string, mode: string, endtime: number, temp: number}) {}
}

export class SetThermPointFailure implements Action {
  public readonly type = EnumHomesActions.SetThermPointFailure;
  constructor(public error) {}
}

export class SetHeatingMode implements Action {
  public readonly type = EnumHomesActions.SetHeatingMode;
  constructor(public payload: { home: { id: string, temperature_control_mode: string, therm_mode: string, therm_mode_endtime?: number }}) {}
}
export class SetHeatingModeSuccess implements Action {
  public readonly type = EnumHomesActions.SetHeatingModeSuccess;
  constructor(public payload: {home: {id: string, temperature_control_mode: string, therm_mode: string, therm_mode_endtime?: number}}) {}
}
export class SetHeatingModeFailure implements Action {
  public readonly type = EnumHomesActions.SetHeatingModeFailure;
  constructor(public error) {}
}

export class SetCoolingMode implements Action {
  public readonly type = EnumHomesActions.SetCoolingMode;
  constructor(public payload: { home: { id: string, temperature_control_mode: string, cooling_mode: string, cooling_mode_endtime?: number }}) {}
}
export class SetCoolingModeSuccess implements Action {
  public readonly type = EnumHomesActions.SetCoolingModeSuccess;
  constructor(public payload: {home: {id: string, temperature_control_mode: string, cooling_mode: string, cooling_mode_endtime?: number}}) {}
}
export class SetCoolingModeFailure implements Action {
  public readonly type = EnumHomesActions.SetCoolingModeFailure;
  constructor(public error) {}
}


export class SetAutoMode implements Action {
  public readonly type = EnumHomesActions.SetAutoMode;
  constructor(public payload: { home: { id: string, temperature_control_mode: string, auto_temp_mode: string, auto_mode_endtime?: number }}) {}
}
export class SetAutoModeSuccess implements Action {
  public readonly type = EnumHomesActions.SetAutoModeSuccess;
  constructor(public payload: {home: {id: string, temperature_control_mode: string, auto_temp_mode: string, auto_mode_endtime?: number}}) {}
}
export class SetAutoModeFailure implements Action {
  public readonly type = EnumHomesActions.SetAutoModeFailure;
  constructor(public error) {}
}

export class AckLocalResources implements Action {
  public readonly type = EnumHomesActions.AckLocalResources;
  constructor(public payload: AckLocalResourcesPayload, public header?: { [key: string]: string }) { }
}

export class AckLocalResourcesSuccess implements Action {
  public readonly type = EnumHomesActions.AckLocalResourcesSuccess;
}

export class AckLocalResourcesFailure implements Action {
  public readonly type = EnumHomesActions.AckLocalResourcesFailure;
  constructor(public error) {}
}

export class LoadForecast implements Action {
  public readonly type = EnumHomesActions.LoadForecast;
  constructor(public payload: LoadForecastPayload) {}
}

export class LoadForecastSuccess implements Action {
  public readonly type = EnumHomesActions.LoadForecastSuccess;
  constructor(public payload: LoadForecastSuccessPayload) {}
}

export class LoadForecastFailure implements Action {
  public readonly type = EnumHomesActions.LoadForecastFailure;
  constructor(public error) {}
}

export type HomesActions = GetHomes
  | GetHomesSuccess
  | GetHomesFailure
  | SelectHome
  | UpdateHomePlace
  | UpdateHomePlaceSuccess
  | UpdateHomePlaceFailure
  | GetHomeStatus
  | GetHomeStatusSuccess
  | GetHomeStatusFailure
  | GetReadonlyDeviceState
  | GetReadonlyDeviceStateSuccess
  | GetReadonlyDeviceStateFailure
  | GetHomeConfig
  | GetHomeConfigFailure
  | GetHomeConfigSuccess
  | SetConfig
  | SetConfigFailure
  | SetConfigSuccess
  | GetHomeUsers
  | GetHomeUsersSuccess
  | GetHomeUsersFailure
  | RemoveUserFromHome
  | RemoveUserFromHomeSuccess
  | RemoveUserFromHomeFailure
  | UpdateHomeName
  | UpdateHomeNameSuccess
  | UpdateHomeNameFailure
  | GetAdminAccessCode
  | GetAdminAccessCodeSuccess
  | GetAdminAccessCodeFailure
  | ShowSpinner
  | HideSpinner
  | ShowErrorModal
  | HideErrorModal
  | SetTemperatureControlMode
  | SetTemperatureControlModeFailure
  | SetTemperatureControlModeSuccess
  | GetHomeAddressSuccess
  | GetHomeAddressFailure
  | GetHomeNFA
  | GetHomeNFASuccess
  | GetHomeNFAFailure
  | RemoveRoomFromHome
  | SetStateErrors
  | SetStateFailure
  | SetStateSuccess
  | SetState
  | SetHomeType
  | SetHomeTypeSuccess
  | SetHomeTypeFailure
  | RemoveDeviceFromHome
  | RemoveDeviceFromHomeSuccess
  | RemoveDeviceFromHomeFailure
  | SetThermMode
  | SetThermModeSuccess
  | SetThermModeFailure
  | SetThermPoint
  | SetThermPointSuccess
  | SetThermPointFailure
  | SetHeatingMode
  | SetHeatingModeSuccess
  | SetHeatingModeFailure
  | SetCoolingMode
  | SetCoolingModeSuccess
  | SetCoolingModeFailure
  | SetAutoMode
  | SetAutoModeSuccess
  | SetAutoModeFailure
  | AckLocalResources
  | AckLocalResourcesSuccess
  | AckLocalResourcesFailure;
