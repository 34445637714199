import { LangPipe } from '@library/utils/pipes/lang.pipe';
import { NgrxHttpClient } from '@library/utils/services/http-service';
import { GlobalErrorPayload } from './global-error.types';
import { GlobalError, EnumGlobalActions } from './global.actions';
import { merge, Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorService {

  globalError$: Observable<GlobalErrorPayload>;

  // tslint:disable-next-line: variable-name
  private _commonSettingsFileName = 'common-settings';

  constructor(
    private http: NgrxHttpClient,
    private actions: Actions,
    private langPipe: LangPipe
  ) {
    // listen to two kind of errors: http-related error and other action-based errors
    this.globalError$ = merge(
      this.http.errors$.pipe(
        filter(err => !!err),
        map(() => this.convertHttpError())
      ),
      this.actions.pipe(
        ofType<GlobalError>(EnumGlobalActions.GlobalError),
        map(action => this.checkPayload(action.payload))
      )
    );
  }

  set commonSettingsFileName(name: string) {
    this._commonSettingsFileName = name;
  }

  // check whether message.icon and actions exist, and fill in default values if not present
  checkPayload(payload: GlobalErrorPayload): GlobalErrorPayload {
    const res = {
      message: { ...payload.message },
      actions: payload.actions || []
    };
    if (!res.message.icon) {
      res.message.icon = 'fail';
    }
    return res;
  }

  // http errors don't need the user to choose an action
  convertHttpError(): GlobalErrorPayload {
    const res = {
      message: {
        icon: 'fail',
        title: this.langPipe.transform(this._commonSettingsFileName + '.__SETTINGS_ERROR_TITLE'),
        text: this.langPipe.transform(this._commonSettingsFileName + '.__UPDATE_FAILED')
      },
      actions: [ ]
    };
    return res;
  }
}
