import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { of } from 'rxjs';
import {
  switchMap,
  withLatestFrom,
  first,
} from 'rxjs/operators';
import { HomesFacadeVaillant } from './homes.facade';

@Injectable()
export class HomesResolver  {
  constructor(private facade: HomesFacadeVaillant) {}

  resolve(route: ActivatedRouteSnapshot) {
    return of(route.params.home_id as string).pipe(
      withLatestFrom(this.facade.homes$),
      switchMap(([homeId, homes]) => {
        if (!homes.some((h) => h.id === homeId)) {
          return this.facade.loadHome(homeId, { home_id: homeId, detailed_config: true });
        } else {
           this.facade.selectHome(homeId);
           return of(true);
        }
      }),
      first()
    );
  }
}
