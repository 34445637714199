import { Enums } from '@library/utils/constants/Enums.constant'
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { createSelector } from '@ngrx/store';
import { Room } from '../rooms/rooms.interface';
import { getcurrentHomeRooms } from '../rooms/rooms.selector';
import { Module, ElectricityPhase } from './modules.interface';

const modulesState = (state: SettingsState) => state.modules;
const homesState = (state: SettingsState) => state.homes;

export const getModules = createSelector(
  modulesState,
  state => {
    return state.all;
  }
);

export const getCurrentModuleId = createSelector(
  modulesState,
  state => {
    return state.currentModuleId;
  }
);

export const getCurrentModule = createSelector(
  modulesState,
  getCurrentModuleId,
  (state, moduleId) => {
    return state.all.find(module => module.id === moduleId);
  }
);

export const getCurrentHomeModules = createSelector(
  homesState,
  modulesState,
  (homes, modules) => {
    return modules?.all?.filter(mod => mod.homeId === homes.currentHomeId) ?? [];
  }
);

export const getModulesByType = (moduleTypes: string[]) => createSelector(
  modulesState,
  state => {
    return state.all.filter(mod => moduleTypes.includes(mod.type));
  }
);

export const getModulesById = (moduleIds: string[]) => createSelector(
  modulesState,
  state => {
    if (moduleIds) {
      return state.all.filter(module => moduleIds.includes(module.id));
    } else {
      return [];
    }
  }
);

export const isPowerLineEcometer = (m: Module) => m.type === 'NLE' && m.consumption_type === 'electrical' && m.customizable;
export const isPowerLineEnergyMeter = (m: Module) => m.type === 'NLPC' && m.electricity_phase ===	ElectricityPhase.NONE && m.measured_elec_type === 'consumption';
export const isPowerLineBnxm = (m: Module) => m.type === 'BNXM' && m.measured_elec_type === 'consumption' && m.measures_scope === 'device';
export const isPowerLineThreePhasesMeter = (module: Module) => module.type === 'NLY' && module.measures_scope === 'device';

export const getPowerLines = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    return modules.filter(m => isPowerLineEcometer(m) || isPowerLineEnergyMeter(m) || isPowerLineThreePhasesMeter(m) || isPowerLineBnxm(m));
  }
);

export const getPhases = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    const totalNLY = modules.find(m => m.type === 'NLY' && m.measures_scope === 'total');
    if (totalNLY) {
      return modules.filter(m => m.type === 'NLY' && m.id.includes(totalNLY.id) && m.id.includes('#'));
    }
    return [];
  }
);

export const getCurrentHomeModulesByRoomId = (roomId: string) => createSelector(
  getcurrentHomeRooms,
  getCurrentHomeModules,
  (rooms, modules) => {
    const room: Room = rooms?.find(room => room.id === roomId);
    return modules?.filter(module => room?.modules?.includes(module.id)) ?? [];
  }
)

export const currentHomeBNMH = createSelector(
  getCurrentHomeModules,
  ( modules) => {
    return modules?.find(module => module.type === Enums.ModuleType.BNMH)
  }
)

export const currentHomeBtGateway = createSelector(
  getCurrentHomeModules,
  (modules) => {
    const btGatewayList = [
      Enums.ModuleType.BNCX,
      Enums.ModuleType.BNMH,
      Enums.ModuleType.BNHY,
    ];
    const btGateway = modules.find((module) =>
      btGatewayList.includes(module.type)
    );
    return !!btGateway;
  }
)

const excludedModules = ['NLV', 'NLLV', 'NLIV', 'NLJ'];

export const getLiveAppliances = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    return modules.filter(m => !!m.appliance_type && !excludedModules.includes(m.type));
  }
);

export const getGraphsAppliances = createSelector(
  getCurrentHomeModules,
  (modules: Module[]) => {
    const graphsExcludedModules = ['BNSO']
    return modules.filter(m => !!m.appliance_type && !excludedModules.includes(m.type) && !graphsExcludedModules.includes(m.type));
  }
);
