import { Env } from 'typings';

export const environment: Partial<Env> = {
  production: true,
  domain: '.vg-thermostat.com',
  invitationUrl: 'https://authbeta.vg-thermostat.com/invitation/home/',
  authUrl: 'https://authbeta.vg-thermostat.com/access/login/',
  apiUrl: 'https://app.netatmo.net/api/',
  syncApiUrl: 'https://app.netatmo.net/syncapi/v1/',
  cookiePrefix: 'betavgthermostatcom',
  langsUrl: '/langs/',
  helpcenterUrl: 'https://helpcenterbeta.netatmo.com/hc/',
  appName: 'app_thermostat_vaillant',
  myAccountUrl: 'https://myconnectedaccountbeta.vg-thermostat.com/',
  faqUrl: 'https://settingsbeta.vg-thermostat.com/#/faq',
  app: 'eRelax',
  mapboxAccessToken: 'pk.eyJ1IjoibmV0YXRtbyIsImEiOiJjanh5Z2U3NmIwMDQ2M2xzOTFsaXA0M2k2In0.uxP5nswLVJvjcm-AsGEPfQ',
};
