<ng-container *ngIf="(viewModel$ | async) as vm">

  <lay-panel-block>

    <lay-title>

      <app-text class="is-family-semiBold" [text]="vm.home.name">
      </app-text>

    </lay-title>

  </lay-panel-block>

  <lay-panel-block>

    <lay-link (click)="getNextView({ nextRoute: 'device/', thermostat: vm.thermostat })" [resolve]="false">

      <app-icon class="has-text-primary is-size-4 is-family-semiBold is-spaced-right" name="info-vaillant">
      </app-icon>

      <app-text class="title" [text]="'common-app.__INFORMATION' | LANG"></app-text>

    </lay-link>

  </lay-panel-block>

  <lay-panel-block>

    <lay-link (click)="addDevice()">

      <app-icon class="has-text-primary is-size-4 is-family-semiBold is-spaced-right"
        name="navigation_plus-vaillant">
      </app-icon>

      <app-text class="title" [text]="'settings-therm.__ADD_THERM' | LANG">
      </app-text>

    </lay-link>

  </lay-panel-block>

  <lay-panel-block>
    <lay-link (click)="getNextView({ nextRoute: 'heatingsystem/', thermostat: vm.thermostat })" [resolve]="false">

      <app-icon class="has-text-primary is-size-4 is-family-semiBold is-spaced-right"
        name="heating-vaillant">
      </app-icon>

      <app-text class="title" class="home-name" [text]="'settings-all.__VAI_SETTINGS_MAIN_HEATING_PARAMS' | LANG">
      </app-text>

    </lay-link>

  </lay-panel-block>

  <lay-panel-block>

    <lay-link (click)="getNextView({ nextRoute: 'users/', thermostat: vm.thermostat})" [resolve]="false">

      <app-icon class="has-text-primary is-size-4 is-family-semiBold is-spaced-right"
        name="group-vaillant">
      </app-icon>

      <app-text class="title" class="home-name" [text]="'settings-therm.__USERS' | LANG">
      </app-text>

    </lay-link>

  </lay-panel-block>

</ng-container>
