import { EnumHomesActions, HomesActions } from '@library/store/homes/homes.action';
import { EnumHomesActionsVaillant, HomesActionsVaillant } from './homes.actions';
import { VaillantUser } from './homes.interface';
import { HomesStateVaillant, initialHomesStateVaillant } from './homes.state';


export function HomesReducersVaillant(state = initialHomesStateVaillant, action: HomesActionsVaillant | HomesActions ): HomesStateVaillant {
    switch (action.type) {

         // Updating Heating Type
         case EnumHomesActionsVaillant.UpdateHomeHeatingType: {
            const homes = state.homes.map(home => {
                if (home.id === action.payload.homeId) {
                    home = {
                        ...home,
                        therm_heating_type: action.payload.heating_type
                    };
                }
                return home;
            });

            return {
                ...state,
                homes
            };

        }

        case EnumHomesActionsVaillant.SetManualSetpointDefaultDurationSuccess: {
            const homes = state.homes.map(home => {
                if (home.id === action.payload.home.id) {
                    home = {
                        ...home,
                        therm_setpoint_default_duration: action.payload.home.therm_setpoint_default_duration
                    };
                }
                return home;
            });

            return {
                ...state,
                homes
            };
        }

        case EnumHomesActionsVaillant.SetAnticipationActivationSuccess: {
            const homes = state.homes.map(home => {
                if (home.id === action.payload.home.id) {
                    home = {
                        ...home,
                        anticipation: action.payload.home.anticipation
                    };
                }
                return home;
            });

            return {
                ...state,
                homes
            };
        }

        // Handling case to determine "Home Owner" for Vaillant
        // Users are given in order, so first user in object is "owner"
        case EnumHomesActions.GetHomeUsersSuccess: {
            const homes = state.homes.map(home => {

              if (home.id === action.payload.id) {

                const payloadKeys = Object.keys(action.payload.users);

                const users = home.users.map((user: VaillantUser) => {
                    if (user.mail === action.payload.users[payloadKeys[0]].mail) {
                        user = { ...user };
                        user.is_home_owner = true;
                    }
                    return user;
                });

                home = { ...home, users };

              }

              return home;
            });

            return {
              ...state,
              homes
            };

        }

        default:
            return state;
    }
}
