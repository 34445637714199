import { EnumLangActions, LangActions } from './lang.actions';
import { initialLangState, LangState } from './lang.state';

export function LangReducers(state = initialLangState, action: LangActions): LangState {
  switch (action.type) {
    case EnumLangActions.LoadLangs: {
      return {
        ...state
      };
    }

    case EnumLangActions.LoadLangsSuccess: {
      return {
        ...state,
        ...action.payload
      };
    }
    case EnumLangActions.LoadLangsFailure: {
      console.error(action.payload);
      return {
        ...state,
        // error: { ...action.payload },
      };
    }
    default:
      return state;
  }
}
