import { Home } from './homes.interface';

const emptyHomes: Home[] = [];

export interface HomesState {
  homes: Home[];
  currentHomeId: string;
  loading: boolean;
  /**
   * For network error 400
   * Used to show/hide the error modal
   */
  errorModalDisplay: boolean;
}

export const initialHomesState: HomesState = {
  homes: emptyHomes,
  currentHomeId: null,
  loading: false,
  errorModalDisplay: false,
};
