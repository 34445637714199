import { Action } from '@ngrx/store';

export enum EnumSchedulesActionsVaillant {
  CreateNewScheduleVaillant = '[Schedules] Create new schedule Vaillant',
  CreateNewScheduleVaillantSuccess = '[Schedules] Create new schedule Vaillant success',
  CreateNewScheduleVaillantFailure = '[Schedules] Create new schedule Vaillant failure',
}

export class CreateNewScheduleVaillant implements Action {
  public readonly type = EnumSchedulesActionsVaillant.CreateNewScheduleVaillant;
  constructor(public payload: any) { }
}

export class CreateNewScheduleVaillantSuccess implements Action {
  public readonly type = EnumSchedulesActionsVaillant.CreateNewScheduleVaillantSuccess;
  constructor(public payload: { schedule_id: string, schedule_info: any }) { }
}

export class CreateNewScheduleVaillantFailure implements Action {
  public readonly type = EnumSchedulesActionsVaillant.CreateNewScheduleVaillantFailure;
  constructor(public payload: any) { }
}

export type SchedulesActions = CreateNewScheduleVaillant
  | CreateNewScheduleVaillantSuccess
  | CreateNewScheduleVaillantFailure;
