import { Injectable, InjectionToken } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import * as UserSelectors from './user.selector';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { GetUser, GetUserSuccess } from './user.action'

export const USER_FACADE = new InjectionToken('USER_FACADE');
@Injectable()
export class UserFacade {
    currentUser$ = this.store.pipe(select(UserSelectors.getUser));
    userMail$ = this.store.pipe(select(UserSelectors.getUserEmail));
    locale$ = this.store.pipe(select(UserSelectors.getLocale));
    keychain$ = this.store.pipe(select(UserSelectors.getKeychain));

    constructor(
        protected store: Store<SettingsState>,
        protected actions$: Actions,
    ) { }

    /**
     * Loads data of the user
     * @returns Observable on action of type GetUserSuccess when it is fired
     */
    loadUser(): Observable<Action> {
        this.store.dispatch(GetUser());

        return this.actions$.pipe(
            ofType(GetUserSuccess),
            take(1)
        );
    }
}
