import { NgModule } from '@angular/core';
import { AtomsModule } from '@library/ui/atoms/atoms.module'
import { ActivableTextComponent } from './activable-text.component';

@NgModule({
  declarations: [
    ActivableTextComponent
  ],
  exports: [
    ActivableTextComponent
  ],
  imports: [
    AtomsModule,
  ]
})
export class ActivableTextModule { }
