export class Enums {

    public static SourceType = {
        'AIR_CONDITIONER': 'air_conditioner',
        'COLD_WATER': 'cold_water',
        'COOKTOP': 'cooktop',
        'DISHWASHER': 'dishwasher',
        'ELECTRIC_CHARGER': 'electric_charger',
        'FRIDGE': 'fridge',
        'GAS': 'gas',
        'HEATERS': 'heaters',
        'HOME': 'home',
        'HOT_WATER': 'hot_water',
        'LIGHTS': 'lights',
        'MACHINE': 'machine',
        'MULTIMEDIA': 'multimedia',
        'OTHER': 'other',
        'OVEN': 'oven',
        'ROLLER_SHUTTERS': 'roller_shutters',
        'SANITARY_HOT_WATER': 'sanitary_hot_water',
        'SOCKETS': 'sockets',
        'SOLAR_PANEL': 'solar_panel',
        'SWIMMING_POOL': 'swimming_pool',
        'TUMBLE_DRYER': 'tumble_dryer',
        'WASHING_MACHINE': 'washing_machine',
        'values': [
                'sockets',
                'lights',
                'heaters',
                'cooktop',
                'sanitary_hot_water',
                'air_conditioner',
                'roller_shutters',
                'cold_water',
                'hot_water',
                'gas',
                'other',
                'electric_charger',
                'fridge',
                'oven',
                'dishwasher',
                'washing_machine',
                'tumble_dryer',
                'multimedia',
                'machine',
                'swimming_pool',
                'solar_panel',
                'home'
        ]
    };
    public static DoorStatus = {
        'CALIBRATING': 'calibrating',
        'CALIBRATION_FAILED': 'calibration_failed',
        'CLOSED': 'closed',
        'MAINTENANCE': 'maintenance',
        'NO_NEWS': 'no_news',
        'OPEN': 'open',
        'UNDEFINED': 'undefined',
        'WEAK_SIGNAL': 'weak_signal',
        'values': [
                'no_news',
                'calibrating',
                'undefined',
                'closed',
                'open',
                'calibration_failed',
                'maintenance',
                'weak_signal'
        ]
    };
    public static AppType = {
        'APP_ALL': 'app_all',
        'APP_CAMERA': 'app_camera',
        'APP_ENERGY': 'app_energy',
        'APP_HOME': 'app_home',
        'APP_HOMECOACH': 'app_homecoach',
        'APP_JUNE': 'app_june',
        'APP_MAGELLAN': 'app_magellan',
        'APP_MULLER': 'app_muller',
        'APP_SECURITY': 'app_security',
        'APP_STATION': 'app_station',
        'APP_THERMOSTAT': 'app_thermostat',
        'APP_THERMOSTAT_SDBG': 'app_thermostat_sdbg',
        'APP_THERMOSTAT_VAILLANT': 'app_thermostat_vaillant',
        'APP_VELUX': 'app_velux',
        'values': [
                'app_thermostat_vaillant',
                'app_thermostat_sdbg',
                'app_station',
                'app_homecoach',
                'app_security',
                'app_energy',
                'app_home',
                'app_magellan',
                'app_velux',
                'app_all',
                'app_camera',
                'app_june',
                'app_thermostat',
                'app_muller'
        ]
    };
    public static CoolingModes = {
        'AWAY': 'away',
        'OFF': 'off',
        'SCHEDULE': 'schedule',
        'values': [
                'schedule',
                'away',
                'off'
        ]
    };
    public static RadiatorState = {
        'AUTO': 'auto',
        'DELESTAGE': 'delestage',
        'STAND_BY': 'stand_by',
        'values': [
                'auto',
                'delestage',
                'stand_by'
        ]
    };
    public static ElectricityPhase = {
        'NONE': 'none',
        'PHASE_1': '1',
        'PHASE_2': '2',
        'PHASE_3': '3',
        'values': [
                '1',
                '2',
                '3',
                'none'
        ]
    };
    public static AlarmUnreachableSubType = {
        'REACHABLE': 0,
        'UNREACHABLE': 1,
        'values': [
                0,
                1
        ]
    };
    public static FcuSystemTypes = {
        'FOUR_PIPES_FANCOIL': 'four_pipes_fancoil',
        'TWO_PIPES_FANCOIL': 'two_pipes_fancoil',
        'values': [
                'two_pipes_fancoil',
                'four_pipes_fancoil'
        ]
    };
    public static ModulePairingStatus = {
        'ALREADY_PAIRED': 3,
        'ERROR': 1,
        'OK': 0,
        'STARTED': 2,
        'values': [
                0,
                1,
                2,
                3
        ]
    };
    public static ThermHeatingAlgorithm = {
        'PID': 'pid_algo',
        'SIMPLE': 'simple_algo',
        'values': [
                'simple_algo',
                'pid_algo'
        ]
    };
    public static ScreenOrientation = {
        'POSITION_1': 1,
        'POSITION_2': 2,
        'POSITION_3': 3,
        'POSITION_4': 4,
        'values': [
                1,
                2,
                3,
                4
        ]
    };
    public static DimmerSetting = {
        'LEADINGEDGE': 'leading_edge',
        'TRAILINGEDGE': 'trailing_edge',
        'values': [
                'trailing_edge',
                'leading_edge'
        ]
    };
    public static Source = {
        'AIRING_CO2': 'airing_co2',
        'AIRING_HUMIDITY': 'airing_humidity',
        'AIRING_TEMPERATURE': 'airing_temperature',
        'AIR_RECYCLING': 'air_recycling',
        'ALGO_SCHEDULE_END': 'algo_schedule_end',
        'ANTICIPATION': 'anticipation',
        'AUTO_CLOSE': 'auto_close',
        'BATHROOM_VENTILATION': 'bathroom_ventilation',
        'COLLISION_AVOIDANCE': 'collision_avoidance',
        'COMMAND_ENGINE': 'command_engine',
        'DATA_SAMPLING': 'data_sampling',
        'GREEN_POWER': 'green_power',
        'HOMEKIT': 'homekit',
        'INTERNAL': 'internal',
        'INVALID': 'invalid',
        'LOCK': 'lock',
        'MANUAL_SETPOINT': 'manual_setpoint',
        'NETCOM': 'netcom',
        'NIGHT_VENTILATION': 'night_ventilation',
        'OFFLOAD': 'offload',
        'RADIATOR': 'radiator',
        'RAIN_WATCHDOG': 'rain_watchdog',
        'REACTIVE_VENTILATION': 'reactive_ventilation',
        'RESET': 'reset',
        'SCENARIO': 'scenario',
        'SCHEDULE': 'schedule',
        'SECONDARY_COVER_HANDLING': 'secondary_cover_handling',
        'SUN_SCREENING': 'sun_screening',
        'SWITCH': 'switch',
        'SYNC_BALANCING': 'sync_balancing',
        'THERMAL_CONFORT': 'thermal_confort',
        'THERMAL_LEARNING': 'thermal_learning',
        'TIC': 'tic',
        'WINDOWS_CLOSING': 'windows_closing',
        'ZIGBEE': 'zigbee',
        'values': [
                'invalid',
                'internal',
                'homekit',
                'reactive_ventilation',
                'airing_temperature',
                'airing_humidity',
                'airing_co2',
                'bathroom_ventilation',
                'air_recycling',
                'windows_closing',
                'sun_screening',
                'algo_schedule_end',
                'secondary_cover_handling',
                'night_ventilation',
                'switch',
                'netcom',
                'manual_setpoint',
                'scenario',
                'lock',
                'anticipation',
                'thermal_learning',
                'thermal_confort',
                'sync_balancing',
                'offload',
                'auto_close',
                'rain_watchdog',
                'radiator',
                'tic',
                'schedule',
                'collision_avoidance',
                'data_sampling',
                'reset',
                'command_engine',
                'zigbee',
                'green_power'
        ]
    };
    public static AlimStatus = {
        'DEFECT': 1,
        'OK': 2,
        'WEAK': 3,
        'values': [
                1,
                2,
                3
        ]
    };
    public static TomorrowElectricalMode = {
        'BLUE': 'blue',
        'DEFAULT': 'default',
        'EJP': 'ejp',
        'HN': 'hn',
        'RED': 'red',
        'UNKNOWN': 'unknown',
        'WHITE': 'white',
        'values': [
                'default',
                'unknown',
                'blue',
                'red',
                'white',
                'hn',
                'ejp'
        ]
    };
    public static FcuValveTypes = {
        'ONOFF_VALVE_CLOSED_DEFAULT': 'onoff_valve_closed_default',
        'ONOFF_VALVE_OPEN_DEFAULT': 'onoff_valve_open_default',
        'PROPORTIONAL_VALVE': 'proportional_valve',
        'THREE_POINTS_VALVE': 'three_points_valve',
        'values': [
                'onoff_valve_open_default',
                'onoff_valve_closed_default',
                'proportional_valve',
                'three_points_valve'
        ]
    };
    public static Floodlight = {
        'AUTO': 'auto',
        'OFF': 'off',
        'ON': 'on',
        'values': [
                'on',
                'off',
                'auto'
        ]
    };
    public static HomeType = {
        'CATERING': 'catering',
        'EDUCATION': 'education',
        'FLAT': 'flat',
        'HOSPITALITY': 'hospitality',
        'HOUSE': 'house',
        'OFFICE': 'office',
        'PRACTICE': 'practice',
        'SHOP': 'shop',
        'UNKNOWN': 'unknown',
        'values': [
                'flat',
                'house',
                'unknown',
                'office',
                'practice',
                'hospitality',
                'shop',
                'catering',
                'education'
        ]
    };
    public static AlgoStatus = {
        'AIRING_CO2': 102,
        'AIRING_HUMIDITY': 101,
        'AIRING_TO_COOL': 100,
        'ALGO_PAUSED': 306,
        'AWNING_BLIND_ALEXA': 333,
        'AWNING_BLIND_GOOGLE_HOME': 323,
        'AWNING_BLIND_HOMEKIT': 313,
        'AWNING_BLIND_MANUAL': 303,
        'AWNING_BLIND_SCHEDULE': 203,
        'BAD_WEATHER': 112,
        'BATHROOM_AIRING_TO_START': 116,
        'BLIND_ALEXA': 331,
        'BLIND_GOOGLE_HOME': 321,
        'BLIND_HOMEKIT': 311,
        'BLIND_MANUAL': 301,
        'BLIND_SCHEDULE': 201,
        'CLOSED_DUE_RAIN': 305,
        'COVERINGS_ALGO_IN_PROGRESS': 114,
        'GOOD_AIR_QUALITY': 113,
        'HOME_LOCKED': 304,
        'IMPROVE_AIR_QUALITY': 105,
        'KEEP_AIR_QUALITY': 106,
        'LET_IN_DAYLIGHT': 115,
        'LET_IN_DAYLIGHT_CLOUDY': 118,
        'LET_IN_DAYLIGHT_COLD_WEATHER': 120,
        'LET_IN_DAYLIGHT_TO_WARM': 117,
        'LET_IN_DAYLIGHT_WEAK_SUN': 119,
        'NIGHT_COOLING': 104,
        'NO_WEATHER': 121,
        'RAINING_ALGO': 111,
        'RAINING_SCHEDULE': 207,
        'REGULAR_AIRING': 103,
        'ROOM_AIRED_OUT': 107,
        'SHUTTER_ALEXA': 332,
        'SHUTTER_GOOGLE_HOME': 322,
        'SHUTTER_HOMEKIT': 312,
        'SHUTTER_MANUAL': 302,
        'SHUTTER_SCHEDULE': 202,
        'TOO_COLD_ALGO': 109,
        'TOO_COLD_SCHEDULE': 204,
        'TOO_HOT_ALGO': 108,
        'TOO_HOT_SCHEDULE': 205,
        'TOO_WINDY_ALGO': 110,
        'TOO_WINDY_SCHEDULE': 206,
        'UNKNOWN': 1,
        'WINDOW_ALEXA': 330,
        'WINDOW_GOOGLE_HOME': 320,
        'WINDOW_HOMEKIT': 310,
        'WINDOW_MANUAL': 300,
        'WINDOW_SCHEDULE': 200,
        'values': [
                1,
                100,
                101,
                102,
                103,
                104,
                105,
                106,
                107,
                108,
                109,
                110,
                111,
                112,
                113,
                114,
                115,
                116,
                117,
                118,
                119,
                120,
                121,
                200,
                201,
                202,
                203,
                204,
                205,
                206,
                207,
                300,
                301,
                302,
                303,
                304,
                305,
                306,
                310,
                311,
                312,
                313,
                320,
                321,
                322,
                323,
                330,
                331,
                332,
                333
        ]
    };
    public static MediaPlayerStatus = {
        'PAUSE': 'pause',
        'PLAY': 'play',
        'STOP': 'stop',
        'values': [
                'play',
                'pause',
                'stop'
        ]
    };
    public static AlarmEventSubType = {
        'ARMED': 2,
        'DISARMED': 0,
        'PARTIAL': 1,
        'PRE_ALARM': 3,
        'TAMPERED': 5,
        'TRIGGERED': 4,
        'values': [
                0,
                1,
                2,
                3,
                4,
                5
        ]
    };
    public static UserType = {
        'LEGRAND': 'legrand',
        'MULLER': 'muller',
        'NETATMO': 'netatmo',
        'SDBG': 'sdbg',
        'VAILLANT': 'vaillant',
        'VELUX': 'velux',
        'values': [
                'netatmo',
                'legrand',
                'velux',
                'vaillant',
                'muller',
                'sdbg'
        ]
    };
    public static ScreenBrightness = {
        'AUTO': -1,
        'AUTO_OFF': -2,
        'LEVEL_0': 0,
        'LEVEL_1': 1,
        'LEVEL_10': 10,
        'LEVEL_2': 2,
        'LEVEL_3': 3,
        'LEVEL_4': 4,
        'LEVEL_5': 5,
        'LEVEL_6': 6,
        'LEVEL_7': 7,
        'LEVEL_8': 8,
        'LEVEL_9': 9,
        'values': [
                -2,
                -1,
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10
        ]
    };
    public static OffloadAlgo = {
        'DYNAMIC': 'dynamic',
        'FIXED': 'fixed',
        'values': [
                'dynamic',
                'fixed'
        ]
    };
    public static BatteryType = {
        'ALKALINE': 'alkaline',
        'LITHIUM': 'lithium',
        'RECHARGEABLE': 'rechargeable',
        'values': [
                'alkaline',
                'lithium',
                'rechargeable'
        ]
    };
    public static AutoSetpointMode = {
        'HOME': 'home',
        'MANUAL': 'manual',
        'MAX': 'max',
        'OFF': 'off',
        'values': [
                'off',
                'manual',
                'max',
                'home'
        ]
    };
    public static WindUnit = {
        'BEAUFORT': 3,
        'KMH': 0,
        'KNOT': 4,
        'MPH': 1,
        'MS': 2,
        'values': [
                0,
                1,
                2,
                3,
                4
        ]
    };
    public static BatteryAlarmState = {
        'ALARM': 'alarm',
        'OK': 'ok',
        'PRE_ALARM': 'pre_alarm',
        'SILENT_ALARM': 'silent_alarm',
        'SNOOZEABLE_ALARM': 'snoozeable_alarm',
        'values': [
                'ok',
                'pre_alarm',
                'snoozeable_alarm',
                'silent_alarm',
                'alarm'
        ]
    };
    public static CameraRecordRule = {
        'ALWAYS': 'always',
        'HOME_EMPTY': 'empty',
        'NEVER': 'never',
        'values': [
                'never',
                'empty',
                'always'
        ]
    };
    public static ScenarioIds = {
        'AWAY': 'away',
        'BEDTIME': 'bedtime',
        'CUSTOM_1': 'custom_1',
        'CUSTOM_2': 'custom_2',
        'CUSTOM_3': 'custom_3',
        'CUSTOM_4': 'custom_4',
        'CUSTOM_5': 'custom_5',
        'HOME': 'home',
        'LOCK': 'lock',
        'PANIC': 'panic',
        'UNLOCK': 'unlock',
        'WAKE_UP': 'wake_up',
        'values': [
                'away',
                'home',
                'bedtime',
                'wake_up',
                'lock',
                'unlock',
                'custom_1',
                'custom_2',
                'custom_3',
                'custom_4',
                'custom_5',
                'panic'
        ]
    };
    public static ScenarioTypes = {
        'AWAY': 'away',
        'BEDTIME': 'bedtime',
        'GARAGE': 'garage',
        'HOLIDAYS': 'holidays',
        'HOME': 'home',
        'LIGHT': 'light',
        'MUSIC': 'music',
        'OTHER': 'other',
        'OUTLET': 'outlet',
        'PARTY': 'party',
        'READING': 'reading',
        'RELAXATION': 'relaxation',
        'ROMANTIC': 'romantic',
        'SHUTTER': 'shutter',
        'SPORT': 'sport',
        'SWIMMING_POOL': 'swimming_pool',
        'TV': 'tv',
        'VENTILATION': 'ventilation',
        'WAKE_UP': 'wake_up',
        'WORK': 'work',
        'values': [
                'away',
                'home',
                'bedtime',
                'wake_up',
                'party',
                'music',
                'tv',
                'work',
                'relaxation',
                'reading',
                'sport',
                'romantic',
                'holidays',
                'garage',
                'swimming_pool',
                'outlet',
                'light',
                'shutter',
                'ventilation',
                'other'
        ]
    };
    public static RadioState = {
        'FULL': 'full',
        'HIGH': 'high',
        'LOW': 'low',
        'MEDIUM': 'medium',
        'VERY_LOW': 'very_low',
        'values': [
                'very_low',
                'low',
                'medium',
                'high',
                'full'
        ]
    };
    public static AlertAction = {
        'DISABLED': 'disabled',
        'DOWN': 'down',
        'MAX_ENERGY': 'max_energy',
        'MAX_POWER': 'max_power',
        'MIN_ENERGY': 'min_energy',
        'MIN_POWER': 'min_power',
        'OFF': 'off',
        'ON': 'on',
        'UP': 'up',
        'values': [
                'disabled',
                'on',
                'off',
                'up',
                'down',
                'max_power',
                'min_power',
                'max_energy',
                'min_energy'
        ]
    };
    public static BrandName = {
        'AIRELEC_GSB': 'airelec_gsb',
        'AIRELEC_RESEAU': 'airelec_reseau',
        'APPLIMO_GSB': 'applimo_gsb',
        'APPLIMO_RESEAU': 'applimo_reseau',
        'CAMPA_GSB': 'campa_gsb',
        'CAMPA_RESEAU': 'campa_reseau',
        'CHAUFFELEC': 'chauffelec',
        'CONCORDE': 'concorde',
        'CONVECTAIR': 'convectair',
        'NOIROT_GSB': 'noirot_gsb',
        'NOIROT_RESEAU': 'noirot_reseau',
        'NONE': 'none',
        'RADIAL': 'radial',
        'values': [
                'none',
                'noirot_reseau',
                'applimo_reseau',
                'airelec_reseau',
                'campa_reseau',
                'noirot_gsb',
                'applimo_gsb',
                'airelec_gsb',
                'campa_gsb',
                'convectair',
                'radial',
                'concorde',
                'chauffelec'
        ]
    };
    public static UploadRule = {
        'ALWAYS': 'always',
        'HOME_EMPTY': 'empty',
        'NEVER': 'never',
        'values': [
                'never',
                'empty',
                'always'
        ]
    };
    public static MeasuresScope = {
        'DEVICE': 'device',
        'TOTAL': 'total',
        'values': [
                'total',
                'device'
        ]
    };
    public static DhwStates = {
        'AUTO': 'auto',
        'FORCED_ON': 'forced_on',
        'OFF': 'off',
        'values': [
                'off',
                'auto',
                'forced_on'
        ]
    };
    public static ColorMode = {
        'COLOR': 'color',
        'TEMPERATURE': 'temperature',
        'values': [
                'color',
                'temperature'
        ]
    };
    public static InstallationType = {
        'FLUSH_MOUNTED': 'flush_mounted',
        'WALL_MOUNTED': 'wall_mounted',
        'values': [
                'wall_mounted',
                'flush_mounted'
        ]
    };
    public static NetcomEventType = {
        'ACTIVATION': 'activation',
        'ALARM_EVENT': 'alarm_event',
        'ALARM_FAILURE': 'alarm_failure',
        'ALARM_TAMPERED': 'alarm_tampered',
        'ALERT_EVENT': 'alert_event',
        'ALGO_ACTIVITY': 'algo_activity',
        'ALGO_EVENT': 'algo_event',
        'APPARENT_POWER_EVENT': 'apparent_power_event',
        'AUTOMATION_ERROR': 'automation_error',
        'AUTO_SETPOINT_EVENT': 'auto_setpoint_event',
        'BATTERY_ALARM': 'battery_alarm',
        'BLOCKED_DOOR_EVENT': 'blocked_door_event',
        'BOILER_ERROR_EVENT': 'boiler_error_event',
        'BOILER_EVENT': 'boiler_event',
        'CALIBRATION_EVENT': 'calibration_event',
        'CAPABILITIES_UPDATE_EVENT': 'capabilities_update_event',
        'CHALLENGE_RESPONSE_EVENT': 'challenge_response_event',
        'CONFIGURATION_STATUS_EVENT': 'configuration_status_event',
        'CONNECTION_EVENT': 'connection_event',
        'COOLING_SETPOINT_EVENT': 'cooling_setpoint_event',
        'DETECT_MODULE_EVENT': 'detect_module_event',
        'DEVICE_INFO_EVENT': 'device_info_event',
        'DHW_EVENT': 'dhw_event',
        'DIAGNOSIS_EVENT': 'diagnosis_event',
        'DISCONNECTION_EVENT': 'disconnection_event',
        'DOOR_LOCK_STILL_OPEN': 'door_lock_still_open',
        'DOWNLOAD_STATUS_EVENT': 'download_status_event',
        'EBUS_ERROR_EVENT': 'ebus_error_event',
        'EBUS_EVENT': 'ebus',
        'ENDPOINT_CONFIGURED_EVENT': 'endpoint_configured_event',
        'END_SIMULATION_EVENT': 'end_simulation_event',
        'ENERGY_OVERCONSUMPTION_EVENT': 'energy_overconsumption_event',
        'EVENT_STREAMING': 'event_streaming',
        'FIRMWARE_UPGRADE': 'firmware_upgrade',
        'HARDWARE_ERROR_EVENT': 'hardware_error_event',
        'HEATING_POWER_REQUEST_EVENT': 'heating_power_request_event',
        'HOME_MODE': 'home_mode',
        'HUMIDITY_VARIATION_EVENT': 'humidity_variation_event',
        'IR_FRAME_DETECTION_EVENT': 'ir_frame_detection_event',
        'IR_FRAME_LEARNING_EVENT': 'ir_frame_learning_event',
        'IR_PROTOCOL_RECONSTRUCTION_RESPONSE': 'ir_protocol_reconstruction_response',
        'JSON_EVENT': 'json_event',
        'LEARNING_EVENT': 'learning_event',
        'LEGACY_NOTIFICATION': 'legacy_notification',
        'LOCK_EVENT': 'lock_event',
        'LONG_ABSENCE': 'long_absence',
        'MIGRATION_EVENT': 'migration_event',
        'MODULE_DISCOVERY_EVENT': 'module_discovery_event',
        'NEW_MODULE_EVENT': 'new_module_event',
        'OEM_INFO_EVENT': 'oem_info_event',
        'PANIC_BUTTON_PRESSED': 'panic_button_pressed',
        'PRESENCE': 'presence',
        'PROTOCOL_URL_REQUEST': 'protocol_url_request',
        'REACHABLE_MODULE_EVENT': 'reachable_module_event',
        'REBOOT_EVENT': 'reboot_event',
        'REMOVE_MODULE_EVENT': 'remove_module_event',
        'SCENARIO_PLAY_EVENT': 'scenario_play_event',
        'SENSOR_EVENT': 'sensor_event',
        'SETPOINT_EVENT': 'setpoint_event',
        'SNAPSHOT_EVENT': 'snapshot_event',
        'SWITCH_SCHEDULE_EVENT': 'switch_schedule_event',
        'SYNC_SCHEDULE': 'sync_schedule',
        'TEMPERATURE_CONTROL_MODE_EVENT': 'temperature_control_mode_event',
        'TEMPERATURE_VARIATION_EVENT': 'temperature_variation_event',
        'TODAY_ELECTRICAL_MODE': 'today_electrical_mode',
        'TOMORROW_ELECTRICAL_MODE': 'tomorrow_electrical_mode',
        'UNREACHABLE_MODULE_EVENT': 'unreachable_module_event',
        'UNSUPPORTED_SETPOINT_EVENT': 'unsupported_setpoint_event',
        'WEATHER_FORECAST_EVENT': 'weather_forecast',
        'WIFI_CHANGE_EVENT': 'wifi_change_event',
        'WINDOW_EVENT': 'window_event',
        'values': [
                'diagnosis_event',
                'json_event',
                'firmware_upgrade',
                'remove_module_event',
                'new_module_event',
                'module_discovery_event',
                'unreachable_module_event',
                'connection_event',
                'disconnection_event',
                'end_simulation_event',
                'heating_power_request_event',
                'calibration_event',
                'temperature_variation_event',
                'boiler_event',
                'home_mode',
                'setpoint_event',
                'unsupported_setpoint_event',
                'algo_event',
                'dhw_event',
                'energy_overconsumption_event',
                'lock_event',
                'event_streaming',
                'activation',
                'oem_info_event',
                'window_event',
                'automation_error',
                'long_absence',
                'reboot_event',
                'presence',
                'tomorrow_electrical_mode',
                'today_electrical_mode',
                'battery_alarm',
                'scenario_play_event',
                'challenge_response_event',
                'algo_activity',
                'detect_module_event',
                'alarm_event',
                'snapshot_event',
                'wifi_change_event',
                'boiler_error_event',
                'hardware_error_event',
                'sync_schedule',
                'alert_event',
                'temperature_control_mode_event',
                'cooling_setpoint_event',
                'humidity_variation_event',
                'apparent_power_event',
                'device_info_event',
                'ebus_error_event',
                'ebus',
                'weather_forecast',
                'endpoint_configured_event',
                'migration_event',
                'legacy_notification',
                'capabilities_update_event',
                'ir_frame_detection_event',
                'protocol_url_request',
                'ir_frame_learning_event',
                'ir_protocol_reconstruction_response',
                'learning_event',
                'alarm_failure',
                'alarm_tampered',
                'sensor_event',
                'door_lock_still_open',
                'panic_button_pressed',
                'configuration_status_event',
                'reachable_module_event',
                'switch_schedule_event',
                'auto_setpoint_event',
                'blocked_door_event',
                'download_status_event'
        ]
    };
    public static HomekitStatus = {
        'CONFIGURED': 'configured',
        'ENABLED': 'enabled',
        'HSV_CONFIGURED': 'hsv_configured',
        'NOT_SUPPORTED': 'not_supported',
        'UPGRADABLE': 'upgradable',
        'values': [
                'not_supported',
                'upgradable',
                'enabled',
                'configured',
                'hsv_configured'
        ]
    };
    public static ResetIds = {
        'ELECTRICAL': 'electrical',
        'FACILITY': 'facility',
        'FACTORY': 'factory',
        'HOMEKIT_SETUP': 'homekit_setup',
        'INSTALLATION': 'installation',
        'MODULES': 'modules',
        'SECURITY_KEYS': 'security_keys',
        'WIFI_SETUP': 'wifi_setup',
        'values': [
                'factory',
                'installation',
                'facility',
                'modules',
                'wifi_setup',
                'homekit_setup',
                'security_keys',
                'electrical'
        ]
    };
    public static WindowStatus = {
        'CLOSED': 'closed',
        'OPEN': 'open',
        'QUARANTINED': 'quarantined',
        'values': [
                'open',
                'closed',
                'quarantined'
        ]
    };
    public static TriggerSubtype = {
        'COLOR': 'color',
        'DIMMING_LEVEL': 'dimming_level',
        'FORECAST_SYMBOL': 'forecast_symbol',
        'ON_OFF': 'on_off',
        'OPEN_CLOSE': 'open_close',
        'OPEN_LEVEL': 'open_level',
        'OUTDOOR_HUMIDITY': 'outdoor_humidity',
        'OUTDOOR_TEMPERATURE': 'outdoor_temperature',
        'OUTDOOR_WIND_SPEED': 'outdoor_wind_speed',
        'OVERPRODUCTION': 'overproduction',
        'POWER': 'power',
        'ROOM_MEASURED_TEMPERATURE': 'room_measured_temperature',
        'VENTILATION_LEVEL': 'ventilation_level',
        'values': [
                'on_off',
                'dimming_level',
                'ventilation_level',
                'color',
                'open_level',
                'open_close',
                'room_measured_temperature',
                'power',
                'outdoor_temperature',
                'outdoor_humidity',
                'outdoor_wind_speed',
                'forecast_symbol',
                'overproduction'
        ]
    };
    public static ChipName = {
        'SAMR21': 'SAMR21',
        'STM32': 'STM32',
        'values': [
                'STM32',
                'SAMR21'
        ]
    };
    public static ProductionContractType = {
        'SURPLUS': 'surplus',
        'TOTAL': 'total',
        'values': [
                'total',
                'surplus'
        ]
    };
    public static TimelineEventType = {
        'ACCEPTED_CALL': 'accepted_call',
        'ADD_SMART_KEY': 'add_smart_key',
        'ALARM_STARTED': 'alarm_started',
        'ALARM_STOPPED': 'alarm_stopped',
        'ALIM': 'alim',
        'ANIMAL': 'animal',
        'BATTERY_STATUS': 'battery_status',
        'BOOT': 'boot',
        'CLOUD': 'cloud',
        'CONNECTION': 'connection',
        'CO_DETECTED': 'co_detected',
        'CREATE_INVITATION': 'create_invitation',
        'CU_DEFAULT': 'cu_default',
        'CU_TAMPERED': 'cu_tampered',
        'CU_UNREACHABLE': 'cu_unreachable',
        'DELETE_INVITATION': 'delete_invitation',
        'DETECTION_CHAMBER_STATUS': 'detection_chamber_status',
        'DISCONNECTION': 'disconnection',
        'DOOR_LOCK_STILL_OPEN': 'door_lock_still_open',
        'END_RECORDING': 'end_recording',
        'END_UPDATE': 'end_update',
        'FTP': 'ftp',
        'HOME_ALARM': 'home_alarm',
        'HOME_AWAY': 'home_away',
        'HUMAN': 'human',
        'HUSH': 'hush',
        'INCOMING_CALL': 'incoming_call',
        'MISSED_CALL': 'missed_call',
        'MODULE_CONNECT': 'module_connect',
        'MODULE_DISCONNECT': 'module_disconnect',
        'MODULE_END_UPDATE': 'module_end_update',
        'MODULE_FAIL_UPDATE': 'module_fail_update',
        'MODULE_LOW_BATTERY': 'module_low_battery',
        'MODULE_OFF': 'module_off',
        'MODULE_ON': 'module_on',
        'MODULE_START_UPDATE': 'module_start_update',
        'MOVEMENT': 'movement',
        'NEW_ALARM_STATUS': 'new_alarm_status',
        'NEW_DEVICE': 'new_device',
        'NEW_MODULE': 'new_module',
        'OFF': 'off',
        'ON': 'on',
        'OUTDOOR': 'outdoor',
        'PANIC_BUTTON_PRESSED': 'panic_button_pressed',
        'PERSON': 'person',
        'PERSON_AWAY': 'person_away',
        'PERSON_HOME': 'person_home',
        'POWER_RESTORED': 'power_restored',
        'QRCODE_DETECTED': 'qrcode_detected',
        'REMOVE_SMART_KEY': 'remove_smart_key',
        'SD': 'sd',
        'SENSOR_DEFAULT': 'sensor_default',
        'SIREN_SOUNDING': 'siren_sounding',
        'SIREN_STATE': 'siren_state',
        'SIREN_TAMPERED': 'siren_tampered',
        'SMOKE': 'smoke',
        'SOUND_TEST': 'sound_test',
        'START_UPDATE': 'start_update',
        'TAG_BIG_MOVE': 'tag_big_move',
        'TAG_OPEN': 'tag_open',
        'TAG_SMALL_MOVE': 'tag_small_move',
        'TAG_STATE': 'tag_state',
        'TAMPERED': 'tampered',
        'TEMP_STATUS': 'temp_status',
        'UNAVAILABLE': 'unavailable',
        'UPDATE_DL_FAIL': 'update_dl_fail',
        'UPDATE_DL_START': 'update_dl_start',
        'VEHICLE': 'vehicle',
        'WIFI_STATUS': 'wifi_status',
        'values': [
                'new_device',
                'connection',
                'disconnection',
                'update_dl_start',
                'update_dl_fail',
                'start_update',
                'end_update',
                'incoming_call',
                'accepted_call',
                'missed_call',
                'end_recording',
                'person',
                'person_away',
                'person_home',
                'home_away',
                'movement',
                'on',
                'off',
                'boot',
                'sd',
                'alim',
                'new_module',
                'module_connect',
                'module_disconnect',
                'module_on',
                'module_off',
                'module_low_battery',
                'module_start_update',
                'module_fail_update',
                'module_end_update',
                'tag_small_move',
                'tag_big_move',
                'tag_open',
                'alarm_started',
                'alarm_stopped',
                'ftp',
                'cloud',
                'outdoor',
                'human',
                'animal',
                'vehicle',
                'qrcode_detected',
                'temp_status',
                'siren_tampered',
                'siren_sounding',
                'tag_state',
                'siren_state',
                'battery_status',
                'power_restored',
                'hush',
                'smoke',
                'tampered',
                'wifi_status',
                'unavailable',
                'detection_chamber_status',
                'sound_test',
                'co_detected',
                'home_alarm',
                'new_alarm_status',
                'cu_unreachable',
                'cu_tampered',
                'cu_default',
                'sensor_default',
                'door_lock_still_open',
                'panic_button_pressed',
                'add_smart_key',
                'remove_smart_key',
                'create_invitation',
                'delete_invitation'
        ]
    };
    public static RebootKind = {
        'BATTERY': 'battery',
        'FW_UPDATE': 'fw_update',
        'LOW_POWER': 'low_power',
        'POR_PDR': 'por_pdr',
        'SOFTWARE': 'software',
        'SPIRIT': 'spirit',
        'UNKNOWN': 'unknown',
        'WATCHDOG': 'watchdog',
        'values': [
                'fw_update',
                'unknown',
                'battery',
                'spirit',
                'low_power',
                'watchdog',
                'software',
                'por_pdr'
        ]
    };
    public static ScenarioCategories = {
        'ADVANCED': 'advanced',
        'MASTER': 'master',
        'SIMPLE': 'simple',
        'values': [
                'master',
                'simple',
                'advanced'
        ]
    };
    public static PresenceSensor = {
        'DETECTED': 'detected',
        'NOT_DETECTED': 'not_detected',
        'NOT_REQUESTED': 'not_requested',
        'values': [
                'not_requested',
                'detected',
                'not_detected'
        ]
    };
    public static ShutterPositionState = {
        'CLOSED': 'closed',
        'CLOSING': 'closing',
        'OPEN': 'open',
        'OPENING': 'opening',
        'STOPPED': 'stopped',
        'UNDETERMINED': 'undetermined',
        'values': [
                'open',
                'closed',
                'opening',
                'closing',
                'stopped',
                'undetermined'
        ]
    };
    public static ProductsConfigurationStatus = {
        'CONFIGURING': 'configuring',
        'DONE': 'done',
        'IDLE': 'idle',
        'values': [
                'done',
                'idle',
                'configuring'
        ]
    };
    public static CameraSireneEntryDelayRule = {
        'EMPTY': 'empty',
        'NEVER': 'never',
        'values': [
                'never',
                'empty'
        ]
    };
    public static SystemUnit = {
        'IMPERIAL': 1,
        'METRIC': 0,
        'values': [
                0,
                1
        ]
    };
    public static CameraSireneSoundRule = {
        'ALWAYS': 'always',
        'EMPTY': 'empty',
        'NEVER': 'never',
        'OCCUPIED': 'occupied',
        'values': [
                'never',
                'empty',
                'always',
                'occupied'
        ]
    };
    public static MediaPlayerSourceType = {
        'FAVORITE': 3,
        'RADIO': 2,
        'TRACK': 1,
        'values': [
                1,
                2,
                3
        ]
    };
    public static EbusError = {
        'INTERNAL_ERROR': 'INTERNAL_ERROR',
        'INVALID_REQUEST': 'INVALID_REQUEST',
        'TIME_OUT': 'TIME_OUT',
        'UNKNOWN_EBUS_ERROR': 'UNKNOWN_EBUS_ERROR',
        'WHITELIST_ERROR': 'WHITELIST_ERROR',
        'values': [
                'WHITELIST_ERROR',
                'INVALID_REQUEST',
                'TIME_OUT',
                'UNKNOWN_EBUS_ERROR',
                'INTERNAL_ERROR'
        ]
    };
    public static ProductConfiguration = {
        'NETCOM_LOCAL_WITH_CODE': 'netcom_local_with_code',
        'NETCOM_LOCAL_WITH_CONFIRMATION': 'netcom_local_with_confirmation',
        'NO_CONFIGURATION': 'no_configuration',
        'values': [
                'no_configuration',
                'netcom_local_with_confirmation',
                'netcom_local_with_code'
        ]
    };
    public static ThermHeatingEnergy = {
        'ELEC': 'elec',
        'GAS': 'gas',
        'OIL': 'oil',
        'PAC': 'pac',
        'SUNHYBRID': 'sunhybrid',
        'UNKNOWN': 'unknown',
        'WOOD': 'wood',
        'values': [
                'gas',
                'oil',
                'unknown',
                'wood',
                'elec',
                'pac',
                'sunhybrid'
        ]
    };
    public static ThermSetpointDayColor = {
        'AWAY': 'away',
        'FROSTGUARD': 'frostguard',
        'NONE': 'none',
        'OFFSET': 'offset',
        'values': [
                'away',
                'frostguard',
                'offset',
                'none'
        ]
    };
    public static LearningAction = {
        'ABORT': 'abort',
        'START': 'start',
        'STOP': 'stop',
        'SUBSECTION': 'subsection',
        'values': [
                'start',
                'subsection',
                'stop',
                'abort'
        ]
    };
    public static PartitionStatus = {
        'ARMED': 'armed',
        'DISARMED': 'disarmed',
        'EXIT': 'exit',
        'PRE_ALARM': 'pre_alarm',
        'TAMPERED': 'tampered',
        'TRIGGERED': 'triggered',
        'values': [
                'disarmed',
                'armed',
                'exit',
                'pre_alarm',
                'tampered',
                'triggered'
        ]
    };
    public static ErgonomicsVariant = {
        'DEFAULT': 'default',
        'DETERMINISTIC_ON_OFF': 'deterministic_on_off',
        'HOLD_1_BUTTON': 'hold_1_button',
        'HOLD_2_BUTTONS': 'hold_2_buttons',
        'INVERTED_BUTTONS': 'inverted_buttons',
        'TOGGLE': 'toggle',
        'TWO_BUTTONS': 'two_buttons',
        'values': [
                'default',
                'hold_1_button',
                'hold_2_buttons',
                'inverted_buttons',
                'deterministic_on_off',
                'toggle',
                'two_buttons'
        ]
    };
    public static TempLoweringMode = {
        'DISABLED': 'disabled',
        'NORMAL': 'normal',
        'PROGRESSIVE': 'progressive',
        'values': [
                'disabled',
                'normal',
                'progressive'
        ]
    };
    public static SirenState = {
        'NO_NEWS': 'no_news',
        'NO_SOUND': 'no_sound',
        'PLAYING_RECORD_0': 'playing_record_0',
        'PLAYING_RECORD_1': 'playing_record_1',
        'PLAYING_RECORD_2': 'playing_record_2',
        'PLAYING_RECORD_3': 'playing_record_3',
        'SOUND': 'sound',
        'WARNING': 'warning',
        'values': [
                'no_news',
                'no_sound',
                'warning',
                'sound',
                'playing_record_0',
                'playing_record_1',
                'playing_record_2',
                'playing_record_3'
        ]
    };
    public static ScheduleZoneType = {
        'AWAY': 2,
        'BOOST': 8,
        'COMFORT': 0,
        'CUSTOM': 4,
        'ECO': 5,
        'FROSTGUARD': 3,
        'HOTWATEROFF': 7,
        'HOTWATERON': 6,
        'NIGHT': 1,
        'values': [
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8
        ]
    };
    public static CalibrationMode = {
        'CLASSIC': 'classic',
        'MANUAL_TEMPORAL': 'manual_temporal',
        'MANUAL_VENETIAN': 'manual_venetian',
        'SPECIFIC': 'specific',
        'UP_DOWN_STOP': 'up_down_stop',
        'values': [
                'classic',
                'specific',
                'up_down_stop',
                'manual_temporal',
                'manual_venetian'
        ]
    };
    public static AnticipationType = {
        'DEFAULT': 'default',
        'OPTIMIZED': 'optimized',
        'values': [
                'default',
                'optimized'
        ]
    };
    public static SensorEventSubType = {
        'MASK': 2,
        'TAMPERED': 1,
        'values': [
                1,
                2
        ]
    };
    public static EnableNotificationRangeRule = {
        'ALWAYS': 'always',
        'HOME_EMPTY': 'empty',
        'values': [
                'empty',
                'always'
        ]
    };
    public static FPSetpoint = {
        'AWAY': 'away',
        'COMFORT': 'comfort',
        'FROST_GUARD': 'frost_guard',
        'STAND_BY': 'stand_by',
        'values': [
                'comfort',
                'frost_guard',
                'away',
                'stand_by'
        ]
    };
    public static DocumentType = {
        'PRIVACY_POLICY': 'privacy_policy',
        'TERMS_OF_USE': 'terms_of_use',
        'values': [
                'privacy_policy',
                'terms_of_use'
        ]
    };
    public static WebrtcErrorCodes = {
        'MAX_PEERS_REACHED': 1,
        'SESSION_EXPIRED': 2,
        'values': [
                1,
                2
        ]
    };
    public static PowerDayRate = {
        'BLUE': 'blue',
        'RED': 'red',
        'WHITE': 'white',
        'values': [
                'blue',
                'white',
                'red'
        ]
    };
    public static TemperatureStatus = {
        'OK': 0,
        'REDUIT': 1,
        'STOP': 3,
        'STOP_LED': 2,
        'STOP_TOO_HIGH': 4,
        'STOP_TOO_LOW': 5,
        'values': [
                0,
                1,
                2,
                3,
                4,
                5
        ]
    };
    public static SetpointMode = {
        'AWAY': 'away',
        'HG': 'hg',
        'HOME': 'home',
        'MANUAL': 'manual',
        'MAX': 'max',
        'OFF': 'off',
        'SCHEDULE': 'schedule',
        'UNKNOWN': 'unknown',
        'values': [
                'manual',
                'max',
                'off',
                'home',
                'hg',
                'unknown',
                'schedule',
                'away'
        ]
    };
    public static HeatingZoneType = {
        'AWAY': 2,
        'CONFORT': 8,
        'DAY': 0,
        'ECO': 5,
        'FROST_GUARD': 3,
        'NIGHT': 1,
        'PERSO': 4,
        'values': [
                0,
                1,
                2,
                3,
                4,
                5,
                8
        ]
    };
    public static SoundTestReminder = {
        'ANNUAL': 'annual',
        'MONTHLY': 'monthly',
        'NEVER': 'never',
        'SEMIANNUAL': 'semiannual',
        'values': [
                'never',
                'monthly',
                'semiannual',
                'annual'
        ]
    };
    public static UnreachabilityReason = {
        'NOT_SEEN': 'not_seen',
        'TIMEOUT': 'timeout',
        'values': [
                'timeout',
                'not_seen'
        ]
    };
    public static DiagnosisErrorType = {
        'FPN_UPDATE_INIT_FAILED': 'fpn_update_init_failed',
        'FPN_UPDATE_START_FAILED': 'fpn_update_start_failed',
        'FPN_UPDATE_STOP_RECEIVED': 'fpn_update_stop_received',
        'FPN_UPDATE_TOO_MANY_RETRIES': 'fpn_update_too_many_retries',
        'values': [
                'fpn_update_start_failed',
                'fpn_update_stop_received',
                'fpn_update_init_failed',
                'fpn_update_too_many_retries'
        ]
    };
    public static AwayTemperatureMode = {
        'OFF': 'off',
        'values': [
                'off'
        ]
    };
    public static ThermSetpointFp = {
        'AWAY': 'away',
        'COMFORT': 'comfort',
        'COMFORT_1': 'comfort_1',
        'COMFORT_2': 'comfort_2',
        'FROST_GUARD': 'frost_guard',
        'STAND_BY': 'stand_by',
        'values': [
                'comfort',
                'away',
                'frost_guard',
                'stand_by',
                'comfort_1',
                'comfort_2'
        ]
    };
    public static RoomTypes = {
        'BATHROOM': 'bathroom',
        'BEDROOM': 'bedroom',
        'CORRIDOR': 'corridor',
        'CUSTOM': 'custom',
        'DINING_ROOM': 'dining_room',
        'ELECTRICAL_CABINET': 'electrical_cabinet',
        'GARAGE': 'garage',
        'HOME_OFFICE': 'home_office',
        'KITCHEN': 'kitchen',
        'LIVINGROOM': 'livingroom',
        'LOBBY': 'lobby',
        'OUTDOOR': 'outdoor',
        'STAIRS': 'stairs',
        'TOILETS': 'toilets',
        'values': [
                'kitchen',
                'bedroom',
                'livingroom',
                'bathroom',
                'lobby',
                'custom',
                'outdoor',
                'toilets',
                'garage',
                'home_office',
                'dining_room',
                'corridor',
                'stairs',
                'electrical_cabinet'
        ]
    };
    public static AlimError = {
        'BLEEDER': 'bleeder',
        'WIRING': 'wiring',
        'values': [
                'bleeder',
                'wiring'
        ]
    };
    public static Unit = {
        'IMPERIAL': 1,
        'METRIC': 0,
        'values': [
                0,
                1
        ]
    };
    public static EventVideoStatus = {
        'AVAILABLE': 'available',
        'DELETED': 'deleted',
        'ERROR': 'error',
        'RECORDING': 'recording',
        'values': [
                'recording',
                'deleted',
                'available',
                'error'
        ]
    };
    public static HorizontalFanSwingDirections = {
        'LEFT': 'left',
        'RIGHT': 'right',
        'values': [
                'left',
                'right'
        ]
    };
    public static GarageDoorState = {
        'CLOSED': 'closed',
        'CLOSING': 'closing',
        'OPEN': 'open',
        'OPENING': 'opening',
        'STOPPED': 'stopped',
        'values': [
                'opening',
                'closing',
                'open',
                'closed',
                'stopped'
        ]
    };
    public static PeakRangeType = {
        'BASIC': 'basic',
        'OFF_PEAK': 'off_peak',
        'PEAK': 'peak',
        'values': [
                'off_peak',
                'peak',
                'basic'
        ]
    };
    public static MeasuredElecType = {
        'CONSUMPTION': 'consumption',
        'PRODUCTION': 'production',
        'values': [
                'consumption',
                'production'
        ]
    };
    public static ConsumptionType = {
        'ELECTRICAL': 'electrical',
        'FLUID': 'fluid',
        'values': [
                'fluid',
                'electrical'
        ]
    };
    public static ContractPowerUnit = {
        'KVA': 'kVA',
        'KW': 'kW',
        'values': [
                'kVA',
                'kW'
        ]
    };
    public static ModuleType = {
        'BAC': 'BAC',
        'BCEPA': 'BCEPA',
        'BCEPD': 'BCEPD',
        'BCEPK': 'BCEPK',
        'BCEPS': 'BCEPS',
        'BDIY': 'BDIY',
        'BFIC': 'BFIC',
        'BFII': 'BFII',
        'BFIO': 'BFIO',
        'BFIS': 'BFIS',
        'BIRE': 'BIRE',
        'BN3L': 'BN3L',
        'BN3P': 'BN3P',
        'BNAB': 'BNAB',
        'BNAS': 'BNAS',
        'BNBT': 'BNBT',
        'BNC1': 'BNC1',
        'BNCS': 'BNCS',
        'BNCU': 'BNCU',
        'BNCX': 'BNCX',
        'BNDL': 'BNDL',
        'BNEU': 'BNEU',
        'BNFC': 'BNFC',
        'BNHY': 'BNHY',
        'BNIL': 'BNIL',
        'BNLC': 'BNLC',
        'BNLD': 'BNLD',
        'BNLO': 'BNLO',
        'BNMH': 'BNMH',
        'BNMS': 'BNMS',
        'BNOT': 'BNOT',
        'BNPL': 'BNPL',
        'BNS': 'BNS',
        'BNSE': 'BNSE',
        'BNSL': 'BNSL',
        'BNSO': 'BNSO',
        'BNTH': 'BNTH',
        'BNTR': 'BNTR',
        'BNTVCC': 'BNTVCC',
        'BNXM': 'BNXM',
        'BPAC': 'BPAC',
        'BPVC': 'BPVC',
        'EBU': 'EBU',
        'ELG': 'ELG',
        'ELK': 'ELK',
        'LFCA': 'LFCA',
        'LIP': 'LIP',
        'MFH': 'MFH',
        'NAC': 'NAC',
        'NACAMDOORTAG': 'NACamDoorTag',
        'NACAMERA': 'NACamera',
        'NAJUNE': 'NAJune',
        'NAMAIN': 'NAMain',
        'NAMODULE1': 'NAModule1',
        'NAMODULE2': 'NAModule2',
        'NAMODULE3': 'NAModule3',
        'NAMODULE4': 'NAModule4',
        'NAPLUG': 'NAPlug',
        'NAS': 'NAS',
        'NATHERM1': 'NATherm1',
        'NATHERMVAILLANT': 'NAThermVaillant',
        'NAVAILLANT': 'NAVaillant',
        'NBG': 'NBG',
        'NBO': 'NBO',
        'NBR': 'NBR',
        'NBS': 'NBS',
        'NCO': 'NCO',
        'NDB': 'NDB',
        'NDL': 'NDL',
        'NEL': 'NEL',
        'NHC': 'NHC',
        'NIS': 'NIS',
        'NLAHA': 'NLAHA',
        'NLAO': 'NLAO',
        'NLAS': 'NLAS',
        'NLAV': 'NLAV',
        'NLAWS': 'NLAWS',
        'NLB': 'NLB',
        'NLC': 'NLC',
        'NLD': 'NLD',
        'NLDC': 'NLDC',
        'NLDD': 'NLDD',
        'NLDP': 'NLDP',
        'NLE': 'NLE',
        'NLEVSE': 'NLEVSE',
        'NLF': 'NLF',
        'NLFE': 'NLFE',
        'NLFN': 'NLFN',
        'NLFO': 'NLFO',
        'NLG': 'NLG',
        'NLGS': 'NLGS',
        'NLH': 'NLH',
        'NLIS': 'NLIS',
        'NLIV': 'NLIV',
        'NLJ': 'NLJ',
        'NLL': 'NLL',
        'NLLF': 'NLLF',
        'NLLV': 'NLLV',
        'NLM': 'NLM',
        'NLOC': 'NLOC',
        'NLP': 'NLP',
        'NLPC': 'NLPC',
        'NLPD': 'NLPD',
        'NLPM': 'NLPM',
        'NLPO': 'NLPO',
        'NLPS': 'NLPS',
        'NLPT': 'NLPT',
        'NLR': 'NLR',
        'NLT': 'NLT',
        'NLTS': 'NLTS',
        'NLUF': 'NLUF',
        'NLUI': 'NLUI',
        'NLUNKNOWN': 'NLunknown',
        'NLUO': 'NLUO',
        'NLUP': 'NLUP',
        'NLV': 'NLV',
        'NLWO': 'NLWO',
        'NLY': 'NLY',
        'NMG': 'NMG',
        'NMH': 'NMH',
        'NMR': 'NMR',
        'NMT': 'NMT',
        'NMW': 'NMW',
        'NOC': 'NOC',
        'NPC': 'NPC',
        'NRV': 'NRV',
        'NSD': 'NSD',
        'NXB': 'NXB',
        'NXD': 'NXD',
        'NXG': 'NXG',
        'NXO': 'NXO',
        'NXS': 'NXS',
        'OTH': 'OTH',
        'OTM': 'OTM',
        'TPSG': 'TPSG',
        'TPSGD': 'TPSGD',
        'TPSRS': 'TPSRS',
        'Z3L': 'Z3L',
        'Z3V': 'Z3V',
        'ZBX': 'Zbx',
        'values': [
                'NATherm1',
                'NAThermVaillant',
                'NAVaillant',
                'NRV',
                'NAPlug',
                'NACamera',
                'NPC',
                'NOC',
                'NACamDoorTag',
                'NSD',
                'NAJune',
                'NLG',
                'NLGS',
                'NLP',
                'NLC',
                'NLF',
                'NLFE',
                'NLFN',
                'NLFO',
                'NLT',
                'NLTS',
                'NLOC',
                'NLDC',
                'NLDP',
                'NLDD',
                'NLD',
                'NLAO',
                'NLAV',
                'NLM',
                'NLV',
                'NLL',
                'NLLV',
                'NLLF',
                'NLPC',
                'NLPS',
                'NLPM',
                'NLPT',
                'NLPO',
                'NLPD',
                'LIP',
                'NLAS',
                'NLAWS',
                'NLAHA',
                'NLIS',
                'NLIV',
                'NLH',
                'NLY',
                'NLJ',
                'NLunknown',
                'NLUI',
                'NLUF',
                'NLUP',
                'NLUO',
                'NLR',
                'NXG',
                'NXO',
                'NXB',
                'NXD',
                'NXS',
                'NMG',
                'NMR',
                'NMH',
                'NMT',
                'NMW',
                'NBG',
                'NBO',
                'NBR',
                'NBS',
                'NIS',
                'BNS',
                'BAC',
                'BIRE',
                'LFCA',
                'NDB',
                'NLE',
                'OTH',
                'OTM',
                'NAC',
                'MFH',
                'TPSG',
                'TPSRS',
                'TPSGD',
                'BNCX',
                'BNEU',
                'BNDL',
                'BNSL',
                'BNOT',
                'BNTVCC',
                'BNIL',
                'BNTR',
                'NCO',
                'NAMain',
                'NAModule1',
                'NAModule2',
                'NAModule3',
                'NAModule4',
                'NDL',
                'NHC',
                'Zbx',
                'ELG',
                'ELK',
                'NEL',
                'EBU',
                'Z3L',
                'Z3V',
                'NLWO',
                'BNAB',
                'BNAS',
                'BNMS',
                'BNLD',
                'BNLO',
                'BNCS',
                'BFII',
                'BFIO',
                'BFIS',
                'BFIC',
                'NAS',
                'BNMH',
                'BNFC',
                'BNTH',
                'BN3L',
                'BNXM',
                'BN3P',
                'BNSE',
                'BNBT',
                'BNCU',
                'BPAC',
                'BPVC',
                'BNC1',
                'BNHY',
                'BNPL',
                'BDIY',
                'NLEVSE',
                'BCEPA',
                'BCEPD',
                'BCEPK',
                'BCEPS',
                'NLB',
                'BNSO',
                'BNLC'
        ]
    };
    public static FanModes = {
        'AUTO': 'auto',
        'MANUAL': 'manual',
        'values': [
                'auto',
                'manual'
        ]
    };
    public static CapabilityName = {
        'AUTOMATISM': 'automatism',
        'BSO_ERGO': 'bso_ergo',
        'ELECTRICITY_CONTRACT': 'electricity_contract',
        'GREEN_POWER': 'green_power',
        'HEATING_SCHEDULE': 'heating_schedule',
        'HOME_ALARM': 'home_alarm',
        'MANUAL_TEMPORAL_CALIBRATION': 'manual_temporal_calibration',
        'MANUAL_VENETIAN_CALIBRATION': 'manual_venetian_calibration',
        'MEMORY_LEVEL': 'memory_level',
        'PEAK_AND_OFF_PEAK_ELECTRICITY_TIMES': 'peak_and_off_peak_electricity_times',
        'SCENARIOS': 'scenarios',
        'SMART_ALERTS': 'smart_alerts',
        'TIMEDOFF': 'timedoff',
        'Z3V_SHUTTERS': 'z3v_shutters',
        'values': [
                'scenarios',
                'automatism',
                'peak_and_off_peak_electricity_times',
                'electricity_contract',
                'heating_schedule',
                'manual_temporal_calibration',
                'home_alarm',
                'manual_venetian_calibration',
                'memory_level',
                'z3v_shutters',
                'green_power',
                'smart_alerts',
                'timedoff',
                'bso_ergo'
        ]
    };
    public static HomeAlarm = {
        'NO_SOUND': 'no_sound',
        'SOUND': 'sound',
        'WARNING': 'warning',
        'values': [
                'no_sound',
                'sound',
                'warning'
        ]
    };
    public static MeterType = {
        'DEFAULT': 'default',
        'MONO': 'mono',
        'TRI': 'tri',
        'values': [
                'default',
                'mono',
                'tri'
        ]
    };
    public static ComparisonOperator = {
        'DIFFERENT': 'different',
        'EQUAL': 'equal',
        'HIGHER': 'higher',
        'LOWER': 'lower',
        'values': [
                'higher',
                'lower',
                'equal',
                'different'
        ]
    };
    public static FcuAlgorithmTypes = {
        'HYSTERESIS': 'hysteresis',
        'PROPORTIONAL_INTEGRAL': 'proportional_integral',
        'values': [
                'hysteresis',
                'proportional_integral'
        ]
    };
    public static AlimSource = {
        'BATTERY': 'battery',
        'UNKNOWN': 'unknown',
        'USB': 'usb',
        'values': [
                'unknown',
                'usb',
                'battery'
        ]
    };
    public static CalibrationErrors = {
        'BAD_CALIBRATION': '5',
        'BAD_CALIBRATION_FIRST_TIME': '9',
        'CRITICAL_BATTERY': '3',
        'FAR_CALIBRATION': '6',
        'LOW_BATTERY': '4',
        'MOTOR_BASE_NOT_REACHED': '1',
        'NOT_CALIBRATED': '10',
        'NO_MIN_DETECTED': '7',
        'OK': '0',
        'VALVE_DISCONNECTED': '2',
        'VALVE_OBSTRUCTED': '8',
        'values': [
                '0',
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '10'
        ]
    };
    public static AutomationType = {
        'PRESENCE_SIMULATION': 'presence_simulation',
        'SCENARIO': 'scenario',
        'SCHEDULE': 'schedule',
        'values': [
                'presence_simulation',
                'schedule',
                'scenario'
        ]
    };
    public static Movements = {
        'ALL': 'all',
        'values': [
                'all'
        ]
    };
    public static DetectionChamberStatus = {
        'ALARM': 'alarm',
        'OK': 'ok',
        'OUT_OF_ORDER': 'out_of_order',
        'PRE_ALARM': 'pre_alarm',
        'values': [
                'ok',
                'pre_alarm',
                'alarm',
                'out_of_order'
        ]
    };
    public static ScheduleType = {
        'ALGO': 'algo',
        'AUTO': 'auto',
        'COOLING': 'cooling',
        'ELECTRICITY': 'electricity',
        'ELECTRICITY_PRODUCTION': 'electricity_production',
        'EVENT': 'event',
        'HOTWATER': 'hotwater',
        'THERM': 'therm',
        'values': [
                'therm',
                'hotwater',
                'event',
                'cooling',
                'electricity',
                'electricity_production',
                'algo',
                'auto'
        ]
    };
    public static CoAlarmStatus = {
        'ALARM_100_PPM': 'alarm_100_ppm',
        'ALARM_300_PPM': 'alarm_300_ppm',
        'ALARM_50_PPM': 'alarm_50_ppm',
        'NO_ALARM': 'no_alarm',
        'values': [
                'no_alarm',
                'alarm_50_ppm',
                'alarm_100_ppm',
                'alarm_300_ppm'
        ]
    };
    public static UploadQuality = {
        'EXCEL': 'h',
        'HIGH': 'm',
        'LOW': 'p',
        'MEDIUM': 'l',
        'values': [
                'p',
                'l',
                'm',
                'h'
        ]
    };
    public static WifiStatus = {
        'ARP_ERROR': 13,
        'AUTH_ERROR': 12,
        'DHCP_ERROR': 9,
        'DNS_ERROR': 10,
        'INTERNAL_ERROR': 7,
        'JOIN_TIMEOUT': 6,
        'LOW_BATTERY_ERROR': 14,
        'NOT_FOUND': 5,
        'NO_CONFIGURATION': 0,
        'OK': 3,
        'PASSWORD_ERROR': 4,
        'PENDING': 1,
        'RESERVED_ERROR': 255,
        'TCP_ERROR': 11,
        'UNKNOWN_ERROR': 2,
        'WEAK_SIGNAL': 8,
        'values': [
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                255
        ]
    };
    public static ThermoregulationScheduling = {
        'EXTERNAL': 'external',
        'INTERNAL': 'internal',
        'NONE': 'none',
        'values': [
                'none',
                'internal',
                'external'
        ]
    };
    public static ConnectionStatus = {
        'CONNECTED': 'connected',
        'DISCONNECTED': 'disconnected',
        'PENDING': 'pending',
        'UNKNOWN': 'unknown',
        'values': [
                'unknown',
                'disconnected',
                'pending',
                'connected'
        ]
    };
    public static HardwareError = {
        'HUMIDITY_SENSOR': 'humidity_sensor',
        'TEMPERATURE_SENSOR': 'temperature_sensor',
        'values': [
                'temperature_sensor',
                'humidity_sensor'
        ]
    };
    public static CurrentElectricalMode = {
        'DEFAULT': 'default',
        'EJP': 'ejp',
        'HC': 'hc',
        'HCJB': 'hcjb',
        'HCJR': 'hcjr',
        'HCJW': 'hcjw',
        'HN': 'hn',
        'HP': 'hp',
        'HPJB': 'hpjb',
        'HPJR': 'hpjr',
        'HPJW': 'hpjw',
        'TH': 'th',
        'values': [
                'default',
                'th',
                'hc',
                'hp',
                'hn',
                'ejp',
                'hcjb',
                'hcjw',
                'hcjr',
                'hpjb',
                'hpjw',
                'hpjr'
        ]
    };
    public static BoilerError = {
        'AIR_PRESSURE': 'air_pressure',
        'BOILER_ERROR_INTERNAL': 'boiler_error_internal',
        'BOILER_FLAME': 'boiler_flame',
        'BOILER_NOT_RESPONDING': 'boiler_not_responding',
        'BOILER_RESPONDING': 'boiler_responding',
        'BOILER_TEMPERATURE': 'boiler_temperature',
        'MAINTENANCE': 'maintenance',
        'WATER_PRESSURE': 'water_pressure',
        'values': [
                'boiler_responding',
                'boiler_not_responding',
                'maintenance',
                'water_pressure',
                'boiler_flame',
                'air_pressure',
                'boiler_temperature',
                'boiler_error_internal'
        ]
    };
    public static ImageRotationType = {
        'NONE': 'none',
        'ROTATE180': 'rotate180',
        'values': [
                'none',
                'rotate180'
        ]
    };
    public static DayNightMode = {
        'AUTO': 'auto',
        'FORCED_DAY': 'forced_day',
        'FORCED_NIGHT': 'forced_night',
        'values': [
                'auto',
                'forced_day',
                'forced_night'
        ]
    };
    public static TemperatureControlModeChange = {
        'FORCED': 'forced',
        'MANUAL': 'manual',
        'values': [
                'manual',
                'forced'
        ]
    };
    public static AlarmStatus = {
        'ARMED': 'armed',
        'DISARMED': 'disarmed',
        'PARTIAL': 'partial',
        'PRE_ALARM': 'pre_alarm',
        'TAMPERED': 'tampered',
        'TRIGGERED': 'triggered',
        'values': [
                'disarmed',
                'armed',
                'partial',
                'pre_alarm',
                'tampered',
                'triggered'
        ]
    };
    public static SdStatus = {
        'ABSENT': 1,
        'DEFECT': 5,
        'FORMATTED': 3,
        'INCOMPATIBLE': 6,
        'INSERTED': 2,
        'OK': 4,
        'TOO_SMALL': 7,
        'values': [
                1,
                2,
                3,
                4,
                5,
                6,
                7
        ]
    };
    public static ModuleService = {
        'GATEWAY': 'gateway',
        'LIGHT': 'light',
        'METER': 'meter',
        'SHUTTER': 'shutter',
        'SOCKET': 'socket',
        'values': [
                'gateway',
                'socket',
                'light',
                'shutter',
                'meter'
        ]
    };
    public static SyncScheduleOperation = {
        'RESYNC': 'resync',
        'UPDATE': 'update',
        'values': [
                'resync',
                'update'
        ]
    };
    public static ApiErrorCode = {
        'ACCESS_TOKEN_EXPIRED': 3,
        'ACCESS_TOKEN_MISSING': 1,
        'ALREADY_DEVICE_OWNER': 28,
        'APPLICATION_DEACTIVATED': 5,
        'APPLICATION_NAME_ALREADY_EXISTS': 14,
        'APPLICATION_NOT_FOUND': 22,
        'BAD_MGT_KEY': 17,
        'BAD_PASSWORD': 32,
        'BLOB_ALREADY_EXISTS': 55,
        'BLOB_NOT_FOUND': 56,
        'CAMERA_NO_SPACE_AVAILABLE': 37,
        'DEVICE_BUSY': 54,
        'DEVICE_ID_ALREADY_EXISTS': 18,
        'DEVICE_NOT_FOUND': 9,
        'DEVICE_NOT_INSTALLED': 58,
        'DEVICE_OR_SECRET_NO_MATCH': 12,
        'DEVICE_UNREACHABLE': 41,
        'EMAIL_ALREADY_EXISTS': 8,
        'FORCE_ASSOCIATE': 33,
        'INCONSISTENCY_ERROR': 4,
        'INTERNAL_ERROR': 11,
        'INVALID_ACCESS_TOKEN': 2,
        'INVALID_ACCESS_TOKEN_DEVICE': 46,
        'INVALID_ARG': 21,
        'INVALID_DATE': 25,
        'INVALID_EMAIL': 6,
        'INVALID_IP': 29,
        'INVALID_REFRESH_TOKEN': 30,
        'INVALID_THIRD_PARTY_ACCESS_TOKEN': 47,
        'INVALID_TIMEZONE': 24,
        'IP_NOT_FOUND': 19,
        'JSON_INVALID_ENCODING': 40,
        'KEY_ALREADY_EXISTS': 57,
        'MAX_PEERS_REACHED': 52,
        'MAX_USAGE_REACHED': 26,
        'MEASURE_ALREADY_EXISTS': 27,
        'MGT_KEY_MISSING': 16,
        'MISSING_ARGS': 10,
        'MODULE_ALREADY_PAIRED': 34,
        'NOTHING_TO_MODIFY': 7,
        'NOT_FOUND': 31,
        'NOT_SUPPORTED': 50,
        'NO_PLACES_IN_DEVICE': 15,
        'OPERATION_FORBIDDEN': 13,
        'PASSWORD_COMPLEXITY_TOO_LOW': 38,
        'PROHIBITTED_STRING': 36,
        'REQUIRED_TFA': 43,
        'ROOM_NOT_EMPTY': 42,
        'SECURITY_KEY_ERASED': 45,
        'SECURITY_KEY_LAST_RETRY': 44,
        'THIRD_PARTY_ACCESS_TOKEN_EXPIRED': 48,
        'THIRD_PARTY_AUTH_ERROR': 53,
        'TOO_MANY_CONNECTION_FAILURE': 39,
        'TOO_MANY_ROOMS': 49,
        'TOO_MANY_USER_WITH_IP': 20,
        'UNABLE_TO_EXECUTE': 35,
        'USER_NOT_FOUND': 23,
        'VERSION_CONFLICT': 51,
        'values': [
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
                22,
                23,
                24,
                25,
                26,
                27,
                28,
                29,
                30,
                31,
                32,
                33,
                34,
                35,
                36,
                37,
                38,
                39,
                40,
                41,
                42,
                43,
                44,
                45,
                46,
                47,
                48,
                49,
                50,
                51,
                52,
                53,
                54,
                55,
                56,
                57,
                58
        ]
    };
    public static LearningErrorCodes = {
        'ACTION_SUCCESS': 1,
        'FRAME_COUNT_MAX_ERROR': 4,
        'FRAME_COUNT_MIN_ERROR': 3,
        'FRAME_IDENT_ERROR': 5,
        'FRAME_LEN_ERROR': 2,
        'FRAME_VALID': 0,
        'SYNC_BROKEN': 6,
        'TIMEOUT': 7,
        'values': [
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                7
        ]
    };
    public static ElectricalSubscription = {
        'BASE': 'base',
        'DEFAULT': 'default',
        'EJP': 'ejp',
        'HC': 'hc',
        'TEMPO': 'tempo',
        'values': [
                'default',
                'base',
                'hc',
                'ejp',
                'tempo'
        ]
    };
    public static SoundTest = {
        'FAILED': 'failed',
        'NEVER': 'never',
        'OK': 'ok',
        'TO_DO': 'to_do',
        'values': [
                'never',
                'to_do',
                'ok',
                'failed'
        ]
    };
    public static HomekitMonitoringStatus = {
        'DETECT_ACTIVITY': 'detect_activity',
        'OFF': 'off',
        'STREAM': 'stream',
        'STREAM_RECORD': 'stream_record',
        'values': [
                'off',
                'detect_activity',
                'stream',
                'stream_record'
        ]
    };
    public static OutdoorTemperatureSourceType = {
        'GEFS': 'gefs',
        'STATION': 'station',
        'values': [
                'gefs',
                'station'
        ]
    };
    public static ShutterErgonomics = {
        'DEFAULT': 'default',
        'VENETIAN': 'venetian',
        'values': [
                'default',
                'venetian'
        ]
    };
    public static RtcStatus = {
        'OFFER': 0,
        'RESCIND': 1,
        'TERMINATE': 2,
        'values': [
                0,
                1,
                2
        ]
    };
    public static Connection = {
        'ETHERNET': 'ethernet',
        'WIFI': 'wifi',
        'values': [
                'wifi',
                'ethernet'
        ]
    };
    public static UpdateType = {
        'FPN': 'fpn',
        'ZIGBEE': 'zigbee',
        'values': [
                'zigbee',
                'fpn'
        ]
    };
    public static EventsTtl = {
        'ONE_MONTH': 'one_month',
        'ONE_WEEK': 'one_week',
        'SIX_MONTHS': 'six_months',
        'THREE_MONTHS': 'three_months',
        'TWO_DAYS': 'two_days',
        'values': [
                'two_days',
                'one_week',
                'one_month',
                'three_months',
                'six_months'
        ]
    };
    public static SmartAutomationsCapabilities = {
        'STATE_AND_MEASUREMENTS_V1': 'state_and_measurements_v1',
        'STATE_AND_MEASUREMENTS_V2': 'state_and_measurements_v2',
        'WEATHER_V1': 'weather_v1',
        'values': [
                'state_and_measurements_v1',
                'state_and_measurements_v2',
                'weather_v1'
        ]
    };
    public static CameraNotifyRule = {
        'ALWAYS': 'always',
        'HOME_EMPTY': 'empty',
        'NEVER': 'never',
        'values': [
                'never',
                'empty',
                'always'
        ]
    };
    public static DiagnosisDataType = {
        'BATTERY_VOLTAGE_MV': 'battery_voltage_mv',
        'BLOCK_CNT': 'block_cnt',
        'CRITICAL_BATTERY_THRESHOLD_CNT': 'critical_battery_threshold_cnt',
        'CYCLE_CNT': 'cycle_cnt',
        'FAILED_MOTOR_CALIBRATION_CNT': 'failed_motor_calibration_cnt',
        'MEASURED_STOP_B': 'measured_stop_b',
        'MECH_ANOMALY_CNT': 'mech_anomaly_cnt',
        'MOTOR_CALIBRATION_CNT': 'motor_calibration_cnt',
        'NFC_IQ_CALIBRATION_CNT': 'nfc_iq_calibration_cnt',
        'REBOOT_CNT': 'reboot_cnt',
        'SMART_KEY_FALSE_DEPARTURE_CNT': 'smart_key_false_departure_cnt',
        'VMOT_SAMPLES': 'vmot_samples',
        'WATCHDOG_CNT': 'watchdog_cnt',
        'values': [
                'block_cnt',
                'motor_calibration_cnt',
                'nfc_iq_calibration_cnt',
                'cycle_cnt',
                'reboot_cnt',
                'smart_key_false_departure_cnt',
                'watchdog_cnt',
                'battery_voltage_mv',
                'measured_stop_b',
                'vmot_samples',
                'mech_anomaly_cnt',
                'failed_motor_calibration_cnt',
                'critical_battery_threshold_cnt'
        ]
    };
    public static BatteryState = {
        'FULL': 'full',
        'HIGH': 'high',
        'LOW': 'low',
        'MEDIUM': 'medium',
        'UNKNOWN': 'unknown',
        'VERY_LOW': 'very_low',
        'values': [
                'unknown',
                'very_low',
                'low',
                'medium',
                'high',
                'full'
        ]
    };
    public static TemperatureControlMode = {
        'AUTO': 'auto',
        'COOLING': 'cooling',
        'HEATING': 'heating',
        'values': [
                'heating',
                'cooling',
                'auto'
        ]
    };
    public static ApplianceType = {
        'CEILING_FAN': 'ceiling_fan',
        'CONTACTOR': 'contactor',
        'COOKING': 'cooking',
        'CURTAINS': 'curtains',
        'DISHWASHER': 'dishwasher',
        'DOTS_2': 'dots_2',
        'DOTS_3': 'dots_3',
        'DOTS_4': 'dots_4',
        'DOT_1': 'dot_1',
        'DRYER': 'dryer',
        'ELECTRIC_CHARGER': 'electric_charger',
        'EXTRACTOR_HOOD': 'extractor_hood',
        'FRIDGE_FREEZER': 'fridge_freezer',
        'GARAGE_DOOR': 'garage_door',
        'GATE': 'gate',
        'LIGHT': 'light',
        'MULTIMEDIA': 'multimedia',
        'ORIENTABLE_SUN_SHADE': 'orientable_sun_shade',
        'OTHER': 'other',
        'OVEN': 'oven',
        'RADIATOR': 'radiator',
        'RADIATOR_WITHOUT_PILOT_WIRE': 'radiator_without_pilot_wire',
        'ROLLING_SHUTTER': 'rolling_shutter',
        'ROUTER': 'router',
        'SHADE': 'shade',
        'SPRINKLING': 'sprinkling',
        'STAR': 'star',
        'SWING_SHUTTER': 'swing_shutter',
        'TUMBLE_DRYER': 'tumble_dryer',
        'WASHING_MACHINE': 'washing_machine',
        'WATER_HEATER': 'water_heater',
        'values': [
                'light',
                'fridge_freezer',
                'oven',
                'washing_machine',
                'tumble_dryer',
                'dishwasher',
                'multimedia',
                'router',
                'other',
                'cooking',
                'radiator',
                'radiator_without_pilot_wire',
                'water_heater',
                'extractor_hood',
                'contactor',
                'dryer',
                'electric_charger',
                'rolling_shutter',
                'orientable_sun_shade',
                'shade',
                'curtains',
                'swing_shutter',
                'sprinkling',
                'ceiling_fan',
                'gate',
                'garage_door',
                'star',
                'dot_1',
                'dots_2',
                'dots_3',
                'dots_4'
        ]
    };
    public static AutoTempModes = {
        'AWAY': 'away',
        'OFF': 'off',
        'SCHEDULE': 'schedule',
        'values': [
                'schedule',
                'away',
                'off'
        ]
    };
    public static Oauth2Error = {
        'API_HTTP_CLIENT': 'api_http_client',
        'API_HTTP_SERVER': 'api_http_server',
        'API_OTHER': 'api_other',
        'INTERNAL': 'internal',
        'values': [
                'internal',
                'api_http_client',
                'api_http_server',
                'api_other'
        ]
    };
    public static SmartAlertsTrigger = {
        'OFF': 'off',
        'ON': 'on',
        'OVER': 'over',
        'UNDER': 'under',
        'values': [
                'on',
                'off',
                'under',
                'over'
        ]
    };
    public static ThermHeatingPriority = {
        'COMFORT': 'comfort',
        'ECO': 'eco',
        'values': [
                'comfort',
                'eco'
        ]
    };
    public static RtcType = {
        'ANSWER': 'answer',
        'CANDIDATE': 'candidate',
        'OFFER': 'offer',
        'PLAYER': 'player',
        'RESCIND': 'rescind',
        'TERMINATE': 'terminate',
        'values': [
                'offer',
                'answer',
                'candidate',
                'rescind',
                'terminate',
                'player'
        ]
    };
    public static HeaterNature = {
        'ACCUMULATOR_RADIATOR': 'accumulator_radiator',
        'CONVECTOR': 'convector',
        'RADIATING_PANEL': 'radiating_panel',
        'RADIATOR': 'radiator',
        'ROOM_RADIATOR': 'room_radiator',
        'TOWEL_DRYER': 'towel_dryer',
        'values': [
                'convector',
                'radiating_panel',
                'radiator',
                'towel_dryer',
                'accumulator_radiator',
                'room_radiator'
        ]
    };
    public static CoolingSetpointMode = {
        'HOME': 'home',
        'MANUAL': 'manual',
        'MAX': 'max',
        'OFF': 'off',
        'values': [
                'off',
                'manual',
                'max',
                'home'
        ]
    };
    public static PersonRecordRule = {
        'ALWAYS': 'always',
        'NEVER': 'never',
        'ON_ARRIVAL': 'on_arrival',
        'values': [
                'never',
                'always',
                'on_arrival'
        ]
    };
    public static EnergyConsumptionState = {
        'CRITICAL': 'critical',
        'HIGH': 'high',
        'NORMAL': 'normal',
        'values': [
                'normal',
                'high',
                'critical'
        ]
    };
    public static ForecastSymbol = {
        'CLOUDY': 'cloudy',
        'FOGGY': 'foggy',
        'RAIN': 'rain',
        'SNOW': 'snow',
        'STORM': 'storm',
        'SUNNY': 'sunny',
        'values': [
                'sunny',
                'cloudy',
                'rain',
                'storm',
                'snow',
                'foggy'
        ]
    };
    public static DiagnosisEventType = {
        'AGGREGATED_DATA': 'aggregated_data',
        'CONTEXT_REPORT': 'context_report',
        'DIAGNOSIS_ERROR': 'diagnosis_error',
        'DISCOVERY_DATA': 'discovery_data',
        'EFM_TIME_INCONSISTENT': 'efm_time_inconsistent',
        'EFM_UPDATE_FAIL': 'efm_update_fail',
        'FAULT_HANDLER': 'fault_handler',
        'MOTOR_DATA': 'motor_data',
        'OTA_LIMIT_REACHED': 'ota_limit_reached',
        'PRESENCE_DATA': 'presence_data',
        'REBOOT_DATA': 'reboot_data',
        'UNREACHABLE_REVIVE': 'unreachable_revive',
        'values': [
                'aggregated_data',
                'discovery_data',
                'diagnosis_error',
                'context_report',
                'fault_handler',
                'efm_update_fail',
                'motor_data',
                'reboot_data',
                'efm_time_inconsistent',
                'presence_data',
                'unreachable_revive',
                'ota_limit_reached'
        ]
    };
    public static SmartAlertsType = {
        'ELEC_CONSUMPTION': 'elec_consumption',
        'ON_OFF': 'on_off',
        'OPENING_LEVEL': 'opening_level',
        'OVERPRODUCTION': 'overproduction',
        'POWER': 'power',
        'values': [
                'on_off',
                'opening_level',
                'power',
                'elec_consumption',
                'overproduction'
        ]
    };
    public static ChallengeVersion = {
        'HMAC_SHA512': 'hmac_sha512',
        'values': [
                'hmac_sha512'
        ]
    };
    public static CoDetectedSubType = {
        'ALARM': 2,
        'OK': 0,
        'PRE_ALARM': 1,
        'values': [
                0,
                1,
                2
        ]
    };
    public static SensorStatus = {
        'IDLE': 'idle',
        'MASK': 'mask',
        'TAMPERED': 'tampered',
        'TRIGGERED': 'triggered',
        'values': [
                'idle',
                'tampered',
                'triggered',
                'mask'
        ]
    };
    public static ReconstructionStatusCodes = {
        'ACQUIRED_FRAME_NOT_RECONSTRUCTED': 204,
        'BAD_IDENTIFICATION_OF_EXCEPTION_MODE': 203,
        'CHECKSUM_ERROR_BYPASSED': 201,
        'DETECTION': 1,
        'DROPPED_WRONG_EXCEPTION_MODES': 206,
        'EMPTY_DECODED_FRAME': 403,
        'EMPTY_FRAME': 305,
        'EMPTY_SECTION': 311,
        'INTERNAL_ERROR': 301,
        'INVALID_ACQUISION_VERSION': 302,
        'INVALID_JSON': 303,
        'INVALID_RECONSTRUCTION': 300,
        'LATE_DETECTION_OF_MUTLIPLE_LENGTHS': 307,
        'MANUAL_RECONSTRUCTION': 207,
        'MISMATCHED_MODES_IN_ANNOTATION': 308,
        'MISSING_METADATA': 313,
        'MISSING_SECTION': 312,
        'MISSING_VALUE_IN_ACQUISITION': 202,
        'MULTIPLE_LENGTHS': 401,
        'NON_HEXADECIMAL_CHARACTERS_IN_FRAMES': 304,
        'NOT_ENOUGH_FRAMES': 402,
        'NO_CHECKSUM_FOUND': 306,
        'PROCESSING': 101,
        'PROCESSUS_INTERRUPTED': 309,
        'RESET_CMD_WITH_FULL_EXC_MODE': 205,
        'SUCCESS': 200,
        'TOO_MANY_LEVELS_IN_RANGE': 310,
        'UNCOMPLETE_FRAMES': 400,
        'values': [
                1,
                101,
                200,
                201,
                202,
                203,
                204,
                205,
                206,
                207,
                300,
                301,
                302,
                303,
                304,
                305,
                306,
                307,
                308,
                309,
                310,
                311,
                312,
                313,
                400,
                401,
                402,
                403
        ]
    };
    public static PairingMode = {
        'IO_1WAY': 'io_1way',
        'IO_2WAY_CP': 'io_2way_cp',
        'IO_2WAY_RCV': 'io_2way_rcv',
        'MULLER_HEATER': 'muller_heater',
        'NETATMO': 'netatmo',
        'SECURITY_DOORTAG': 'security_doortag',
        'SECURITY_SIREN': 'security_siren',
        'STOP': 'stop',
        'values': [
                'netatmo',
                'io_1way',
                'io_2way_cp',
                'io_2way_rcv',
                'stop',
                'muller_heater',
                'security_doortag',
                'security_siren'
        ]
    };
    public static VerticalFanSwingDirections = {
        'DOWN': 'down',
        'UP': 'up',
        'values': [
                'up',
                'down'
        ]
    };
    public static ThermModes = {
        'AWAY': 'away',
        'HG': 'hg',
        'OFF': 'off',
        'SCHEDULE': 'schedule',
        'values': [
                'schedule',
                'away',
                'hg',
                'off'
        ]
    };
    public static SyncErrorCodes = {
        'BATTERY_LEVEL': 8,
        'BUSY': 14,
        'COMMAND_ERROR': 7,
        'COMMAND_INVALID_PARAMS': 5,
        'COMMAND_OUTDATED': 28,
        'COMMAND_UNKNOWN_NODE': 4,
        'DECIPHER_ERROR': 31,
        'DEVICE_UNREACHABLE': 6,
        'GATEWAY_LIMIT_REACHED': 17,
        'INTERNAL_ERROR': 2,
        'INVALID_THIRD_PARTY_ACCESS_TOKEN': 25,
        'LIMITATION_BY_RAIN': 12,
        'MANUALLY_OPERATED': 13,
        'MODULE_LIMIT_REACHED': 16,
        'MODULE_UNREACHABLE': 19,
        'MOVEMENT_BLOCKED': 10,
        'NETWORK_LOCKED': 29,
        'NOTHING_TO_MODIFY': 23,
        'OPERATION_NOT_ALLOWED': 18,
        'OPERATION_NOT_PERMITTED': 30,
        'PARSER_ERROR': 3,
        'SECURITY_KEY_ERASED': 21,
        'SECURITY_KEY_LAST_RETRY': 20,
        'SIGNATURE_ERROR': 9,
        'TEMPORARILY_BANNED': 27,
        'THIRD_PARTY_MODULE_NOT_FOUND': 26,
        'UNHANDLED_IO_HC_ERROR': 22,
        'UNKNOWN': 1,
        'UNSUPPORTED_MODULE': 15,
        'WRONG_PINCODE': 24,
        'WRONG_SECURITY_KEY': 11,
        'values': [
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
                22,
                23,
                24,
                25,
                26,
                27,
                28,
                29,
                30,
                31
        ]
    };
    public static DoorType = {
        'OTHER': 'other',
        'ROTARY': 'rotary',
        'ROTARY_SWING': 'rotary_swing',
        'SLIDING': 'sliding',
        'SWING': 'swing',
        'UNKNOWN': 'unknown',
        'values': [
                'unknown',
                'rotary',
                'sliding',
                'swing',
                'rotary_swing',
                'other'
        ]
    };
    public static ModuleSubType = {
        'BTICINO': 'bticino',
        'FAN_CONTROL_REMOTE': 'fan_control_remote',
        'HOME_AWAY_REMOTE': 'home_away_remote',
        'LEGACY': 'legacy',
        'NLT_COLOR_DIMMER_REMOTE': 'nlt_color_dimmer_remote',
        'ON_OFF_REMOTE': 'on_off_remote',
        'ROLLER_SHUTTER_REMOTE': 'roller_shutter_remote',
        'SDBG': 'NAEbusSdbg',
        'STANDALONE': 'standalone',
        'TOGGLE_REMOTE': 'toggle_remote',
        'VAILLANT': 'NAEbusVaillant',
        'WAKEUP_SLEEP_REMOTE': 'wakeup_sleep_remote',
        'values': [
                'bticino',
                'standalone',
                'home_away_remote',
                'wakeup_sleep_remote',
                'roller_shutter_remote',
                'on_off_remote',
                'toggle_remote',
                'fan_control_remote',
                'nlt_color_dimmer_remote',
                'NAEbusVaillant',
                'NAEbusSdbg',
                'legacy'
        ]
    };
    public static CoPreAlarmStatus = {
        'NO_PRE_ALARM': 'no_pre_alarm',
        'PRE_ALARM_100_PPM': 'pre_alarm_100_ppm',
        'PRE_ALARM_300_PPM': 'pre_alarm_300_ppm',
        'PRE_ALARM_50_PPM': 'pre_alarm_50_ppm',
        'values': [
                'no_pre_alarm',
                'pre_alarm_50_ppm',
                'pre_alarm_100_ppm',
                'pre_alarm_300_ppm'
        ]
    };
    public static TriggersOperator = {
        'AND': 'and',
        'OR': 'or',
        'values': [
                'and',
                'or'
        ]
    };
    public static DhwControlTypes = {
        'INSTANTANEOUS': 'instantaneous',
        'NONE': 'none',
        'WATER_TANK': 'water_tank',
        'values': [
                'none',
                'instantaneous',
                'water_tank'
        ]
    };
    public static AlgoAction = {
        'CLOSING_COVERS': 'closing_covers',
        'CLOSING_WINDOWS': 'closing_windows',
        'OPENING_COVERS': 'opening_covers',
        'OPENING_WINDOWS': 'opening_windows',
        'values': [
                'opening_windows',
                'closing_windows',
                'opening_covers',
                'closing_covers'
        ]
    };
    public static FeelLikeAlgorithm = {
        'HEAT': 1,
        'HUMIDEX': 0,
        'values': [
                0,
                1
        ]
    };
    public static ThermHeatingType = {
        'RADIATOR': 'radiator',
        'SUBFLOOR': 'subfloor',
        'UNKNOWN': 'unknown',
        'values': [
                'unknown',
                'subfloor',
                'radiator'
        ]
    };
    public static FPXProtocol = {
        'FP4': 'FP4',
        'FPN': 'FPN',
        'values': [
                'FPN',
                'FP4'
        ]
    };
    public static Mode = {
        'ALGO_ACTIVE': 'algo_active',
        'ALGO_AVAILABLE': 'algo_available',
        'ALGO_DISABLED': 'algo_disabled',
        'MANUAL': 'manual',
        'UNAVAILABLE': 'unavailable',
        'values': [
                'manual',
                'algo_disabled',
                'algo_active',
                'algo_available',
                'unavailable'
        ]
    };
    public static FanSetpointFrom = {
        'MODULE': 'module',
        'PARENT': 'parent',
        'values': [
                'module',
                'parent'
        ]
    };
    public static BatterySubType = {
        'LOW': 1,
        'VERY_LOW': 2,
        'values': [
                1,
                2
        ]
    };
    public static DownloadStatus = {
        'FILE_ERROR': 5,
        'NETW_ERROR': 4,
        'SUCCESS': 1,
        'TIMEOUT_ERROR': 3,
        'UNKNOWN_ERROR': 6,
        'URL_ERROR': 2,
        'values': [
                1,
                2,
                3,
                4,
                5,
                6
        ]
    };
    public static SpecificationTypes = {
        'ENUM': 'enum',
        'MAX': 'max',
        'MIN': 'min',
        'NUMBER': 'number',
        'STEP': 'step',
        'VALUES': 'values',
        'values': [
                'enum',
                'values',
                'number',
                'min',
                'max',
                'step'
        ]
    };
    public static E2ESecurityCapabilities = {
        'LOCAL_NC_RETRIEVE_KEY': 'local_nc_retrieve_key',
        'values': [
                'local_nc_retrieve_key'
        ]
    };
    public static FcuFanTypes = {
        'PROPORTIONAL_0_10V_SPEED': 'proportional_0_10V_speed',
        'THREE_STAGES_SPEED': 'three_stages_speed',
        'values': [
                'proportional_0_10V_speed',
                'three_stages_speed'
        ]
    };
    public static DiagnosisArrayType = {
        'RESPONSE_RSSI': 'response_rssi',
        'RETRY': 'retry',
        'TIMING': 'timing',
        'values': [
                'timing',
                'retry',
                'response_rssi'
        ]
    };
    public static AlarmCodeStatus = {
        'ABSENT': 'absent',
        'AVAILABLE': 'available',
        'INVALID': 'invalid',
        'values': [
                'available',
                'invalid',
                'absent'
        ]
    };
    public static TriggerType = {
        'MEASUREMENT': 'measurement',
        'STATE': 'state',
        'WEATHER_FORECAST': 'weather_forecast',
        'values': [
                'state',
                'weather_forecast',
                'measurement'
        ]
    };
    public static PowerSource = {
        'BATTERY': 'battery',
        'GREEN': 'green',
        'MAINS': 'mains',
        'UNKNOWN': 'unknown',
        'values': [
                'battery',
                'mains',
                'green',
                'unknown'
        ]
    };
    public static PackagingVariant = {
        'DCL': 'dcl',
        'DEFAULT': 'default',
        'DIN_1_MODULE': 'din_1_module',
        'DIN_2_MODULES': 'din_2_modules',
        'MOBILE': 'mobile',
        'MOUNT_1_5_MODULE': 'mount_1_5_module',
        'MOUNT_1_MODULE': 'mount_1_module',
        'MOUNT_1_MODULE_US': 'mount_1_module_US',
        'MOUNT_2_MODULES': 'mount_2_modules',
        'MOUNT_BS': 'mount_bs',
        'NEMA_5_X': 'nema_5_x',
        'POCKET_REMOTE': 'pocket_remote',
        'SHUTTER_BOX': 'shutter_box',
        'SWITCH_US': 'switch_us',
        'values': [
                'default',
                'shutter_box',
                'din_1_module',
                'din_2_modules',
                'dcl',
                'mount_1_module',
                'mount_1_5_module',
                'mount_2_modules',
                'mount_bs',
                'pocket_remote',
                'mobile',
                'mount_1_module_US',
                'nema_5_x',
                'switch_us'
        ]
    };
    public static InvitationMode = {
        'FAVORITE': 'favorite',
        'FRIEND': 'friend',
        'values': [
                'favorite',
                'friend'
        ]
    };
    public static ChimeSoundMode = {
        'DEFAULT': 'default',
        'FIVE_SECONDS': '5s',
        'FOUR_SECONDS': '4s',
        'ONE_SECOND': '1s',
        'THREE_SECONDS': '3s',
        'TWO_SECONDS': '2s',
        'values': [
                'default',
                '1s',
                '2s',
                '3s',
                '4s',
                '5s'
        ]
    };
    public static Monitoring = {
        'OFF': 'off',
        'ON': 'on',
        'values': [
                'on',
                'off'
        ]
    };
    public static OperatingModes = {
        'DETECTION': 'detection',
        'DOWNLOADING': 'downloading',
        'IDLE': 'idle',
        'INITIALIZING': 'initializing',
        'LEARNING': 'learning',
        'NOT_READY': 'not_ready',
        'READY': 'ready',
        'TEST': 'test',
        'WAIT_PROTOCOL': 'wait_protocol',
        'values': [
                'idle',
                'learning',
                'detection',
                'initializing',
                'test',
                'not_ready',
                'ready',
                'wait_protocol',
                'downloading'
        ]
    };
    public static TemperatureUnit = {
        'CELSIUS': 0,
        'FAHRENHEIT': 1,
        'values': [
                0,
                1
        ]
    };
    public static VeluxType = {
        'AWNING_BLIND': 'awning_blind',
        'BLIND': 'blind',
        'DEFAULT': 'default',
        'SHUTTER': 'shutter',
        'VENETIAN_BLIND': 'venetian_blind',
        'WINDOW': 'window',
        'values': [
                'default',
                'window',
                'blind',
                'venetian_blind',
                'shutter',
                'awning_blind'
        ]
    };
    public static ControlledOpeners = {
        'EXTERNAL_COVERS': 'external_covers',
        'INTERNAL_COVERS': 'internal_covers',
        'WINDOWS': 'windows',
        'values': [
                'windows',
                'internal_covers',
                'external_covers'
        ]
    };
    public static ContactorMode = {
        'AUTO': 'auto',
        'FG': 'fg',
        'OFF': 'off',
        'PERMANENT_ON': 'permanent_on',
        'TEMPORARY_ON': 'temporary_on',
        'values': [
                'off',
                'auto',
                'permanent_on',
                'temporary_on',
                'fg'
        ]
    };
    public static BoilerControl = {
        'DETECTING': 'detecting',
        'ONOFF': 'onoff',
        'OPENTHERM': 'opentherm',
        'values': [
                'onoff',
                'opentherm',
                'detecting'
        ]
    };
    public static AbsenceDetection = {
        'AWAY': 'away',
        'DISABLED': 'disabled',
        'NOTIFY': 'notify',
        'values': [
                'disabled',
                'notify',
                'away'
        ]
    };
    public static LinkedUserType = {
        'LEGRAND': 'legrand',
        'NETATMO': 'netatmo',
        'VELUX': 'velux',
        'values': [
                'netatmo',
                'legrand',
                'velux'
        ]
    };
    public static QuietMode = {
        'OFF': 'off',
        'ON': 'on',
        'UNAVAILABLE': 'unavailable',
        'values': [
                'on',
                'off',
                'unavailable'
        ]
    };
    public static BoostStatus = {
        'DISABLED': 'disabled',
        'IN_PROGRESS': 'in_progress',
        'STABILISING': 'stabilising',
        'values': [
                'disabled',
                'in_progress',
                'stabilising'
        ]
    };
    public static WifiState = {
        'FULL': 'full',
        'HIGH': 'high',
        'LOW': 'low',
        'MEDIUM': 'medium',
        'values': [
                'low',
                'medium',
                'high',
                'full'
        ]
    };
    public static PressureUnit = {
        'MBAR': 0,
        'MERCURY': 1,
        'TORR': 2,
        'values': [
                0,
                1,
                2
        ]
    };
}
