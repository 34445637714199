import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { Notification } from '@library/utils/interfaces/general-interfaces.interface';
import { LangPipe } from '../pipes/lang.pipe';

/**
 * Injection Token for Notification values. Loaded in via Provividers in main App module
 */
export const NOTIFICATIONS = new InjectionToken('NOTIFICATIONS');

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    // Private emitter of notifications
    private notification = new BehaviorSubject<Notification>(null);

    // Public observable to subscribe to retrieve latest notifications, if any exist
    public notification$ = this.notification.pipe(
        tap((notif) => {
            if (notif) {
                setTimeout(() => {
                    this.nextNotification();
                }, notif.timeToDisplay || this.displayTimeDefault);
            }
        }),
        share()
    );

    private queue: Notification[] = []; // Queue of Notifications
    private displayTimeDefault = 10000; // 10 seconds in milliseconds

    /**
     * Initializes Notifications And Subscribes To App Actions To Listen For Specified Actions
     */
    constructor(
        @Inject(NOTIFICATIONS) private notifications: Notification[],
        private actions: Actions,
        private langPipe: LangPipe
    ) {
        const notificationActions = this.getActions(this.notifications);
        this.notifications = this.parseCrowdinKeys(this.notifications);
        this.actions.pipe(
            ofType(...notificationActions)
        ).subscribe( (action) => {
            const toDisplay = this.notifications.filter(notif => notif.actionType === action.type);
            this.queue = [...this.queue, ...toDisplay];
            this.nextNotification();
        });
    }

    /**
     * Parses crowdin keys passed as message in notification
     */
    private parseCrowdinKeys(notifications: Notification[]): Notification[] {
        return notifications.map( (notif) => {
            notif.message = this.langPipe.transform(notif.message);
            return notif;
        });
    }

    /**
     * Retrieves All Actions From Notifications List
     */
    private getActions(notifications: Notification[]): string[] {
        const actions = notifications.map(notif => notif.actionType);
        return actions;
    }

    /**
     * Gets next notification from queue, else emit null
     */
    public nextNotification() {
        this.notification.next(this.queue.pop() || null);
    }

}
