import { Action } from '@ngrx/store';
import { ConfigInterface } from './config.interface';

export enum EnumConfigActions {
    GetConfig = '[Config] Get Config',
    GetConfigSuccess = '[Config] Get Config Success',
    SetDarkModeStatus = '[Config] Set Dark Mode',
    SetWebappMode = '[Config] Set webapp mode'
}

export class GetConfig implements Action {
    public readonly type = EnumConfigActions.GetConfig;
}

export class GetConfigSuccess implements Action {
    public readonly type = EnumConfigActions.GetConfigSuccess;
    constructor(public payload: ConfigInterface) { }
}

export class SetDarkModeStatus implements Action {
    public readonly type = EnumConfigActions.SetDarkModeStatus;
    constructor(public payload: boolean) {}
}

export class SetWebappMode implements Action {
    public readonly type = EnumConfigActions.SetWebappMode;
    constructor() {}
}

export type ConfigActions =
    GetConfig |
    GetConfigSuccess |
    SetDarkModeStatus |
    SetWebappMode
;
