import { Component, HostBinding, Input, HostListener, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { UserAgentService } from '@library/utils/services/user-agent.service';

@Component({
  selector: 'lay-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnChanges {
  @Input()
  resolve = true;

  @Input() direction = 'row';

  public hasClick = false;
  public showSpinner = false;

  @HostBinding('class') classes = 'lay-link';

  @HostListener('click', ['$event'])
  isLoadingButton(event) {
    if (!this.resolve) {
      event.preventDefault();
      event.stopPropagation();
      this.showSpinner = true;
      this.hasClick = true;
    }
  }

  constructor(
    public userAgent: UserAgentService,
    private el: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.hasClick && changes.resolve && changes.resolve.currentValue) {
      this.el.nativeElement.click();
      this.showSpinner = false;
    }
  }
}
