import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, mergeMap, switchMap, take } from 'rxjs/operators';
import { ModulesFacadeVaillant } from './modules.facade';
import {
  EnumModulesActionsVaillant,
  SetHeatingCurve,
  SetHeatingCurveFailure,
  SetHeatingCurveSuccess,
  SetHeatingType,
  SetHeatingTypeFailure,
  SetHeatingTypeSuccess,
  ResetHeatingCurve,
  ResetHeatingCurveFailure,
  ResetHeatingCurveSuccess,
  ChangeContactService,
  ChangeContactServiceSuccess,
  ChangeContactServiceFailure,
  SetHeatingAlgo,
  SetHeatingAlgoSuccess,
  SetHeatingAlgoFailure,
  SetPeriod,
  SetPeriodSuccess,
  SetPeriodFailure,
  SetDomesticHotWaterTemperature,
  SetDomesticHotWaterTemperatureSuccess,
  SetDomesticHotWaterTemperatureFailure,
} from './modules.actions';
import { ModulesServiceVaillant } from './modules.service';
import { of } from 'rxjs';
import { HomesService } from '@library/store/homes/homes.service';
import { LangFacade } from '@library/store/lang/lang.facade';
import { EnumHomesActions, GetHomesSuccess } from '@library/store/homes/homes.action';
import { Module } from '@library/store/modules/modules.interface';
import { Room } from '@library/store/rooms/rooms.interface';
import { SelectModuleAction, UpdateModuleNameSuccess } from '@library/store/modules/modules.action';
import { ModuleVaillant } from './modules.interface';
import { GlobalError } from '@library/ui/layout/modal/modal-global-error/global.actions'

@Injectable()
export class ModulesEffectsVaillant {

  constructor(
    private actions$: Actions,
    private facadeVaillant: ModulesFacadeVaillant,
    private moduleServiceVaillant: ModulesServiceVaillant,
    private homeService: HomesService,
    private lang: LangFacade
  ) { }

  /**
   * An effect that is started after GetHomesSuccess
   * It selects the vaillant thermostat
   */
  selectModule$ = createEffect(() => this.actions$.pipe(
    ofType<GetHomesSuccess>(EnumHomesActions.GetHomesSuccess),
    switchMap((action) => {
      const data = JSON.parse(JSON.stringify(action.payload));
      // Find the vaillant thermostat to select it
      const module = data[0].modules.find((m: Module) => m.type === 'NAThermVaillant') as ModuleVaillant;
      const room = data[0].rooms.find((r: Room) => r.module_ids?.includes(module.id));
      if (module && room) {
        this.facadeVaillant.selectRoom(room.id);
        return this.facadeVaillant.selectModule(module.id).pipe(
          switchMap(() => {
            const actions = [new SelectModuleAction(module.id)] as any[];
            // Needed to handle case where some Thermostats are not named. Fill with default product name
            if (!module.name) {
              let defaultName;
              this.lang.translate('settings-therm.__THERMOSTAT').pipe(take(1)).subscribe((val: string) => {
                defaultName = val;
              });
              actions.push(
                new UpdateModuleNameSuccess(
                  {
                    module_id: module.id,
                    name: defaultName,
                    home_id: module.homeId,
                    device_id: module.bridge
                  }
                )
              )
            }
            return actions;
          })
        );
      }
    }),
  ));

  setHeatingCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumModulesActionsVaillant.SetHeatingCurve),
      switchMap((action: SetHeatingCurve) => {
        return this.moduleServiceVaillant.setHeatingCurve(action.payload).pipe(
          switchMap(() => [
            new SetHeatingCurveSuccess(action.payload)
          ]),
          catchError(({ error }) => of(new SetHeatingCurveFailure(error, action.payload)))
        );
      })
    )
  );

  setHeatingType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumModulesActionsVaillant.SetHeatingType),
      switchMap((action: SetHeatingType) => {
        return this.moduleServiceVaillant.setHeatingType(action.payload).pipe(
          switchMap(() => [
            new SetHeatingTypeSuccess(action.payload)
          ]),
          catchError(err => of(new SetHeatingTypeFailure(err)))
        );
      })
    )
  );

  resetHeatingCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumModulesActionsVaillant.ResetHeatingCurve),
      switchMap((action: ResetHeatingCurve) => {
        return this.moduleServiceVaillant.setHeatingCurve(action.payload).pipe(
          switchMap(() => [
            new ResetHeatingCurveSuccess(action.payload)
          ]),
          catchError(({ error }) => of(new ResetHeatingCurveFailure(error, action.payload)))
        );
      })
    )
  );

  changeContactService$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ChangeContactService>(EnumModulesActionsVaillant.ChangeContactService),
      switchMap((action) => {
        return this.moduleServiceVaillant.changeContactService(action.payload).pipe(
          switchMap(() => {
            return [new ChangeContactServiceSuccess(action.payload)];
          }),
          catchError(({ error }) =>
            of(new ChangeContactServiceFailure(error))
          )
        );
      })
    )
  );

  setHeatingAlgo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumModulesActionsVaillant.SetHeatingAlgo),
      switchMap((action: SetHeatingAlgo) => {
        return this.moduleServiceVaillant.setHeatingAlgo(action.payload).pipe(
          switchMap(() => [
            new SetHeatingAlgoSuccess(action.payload)
          ]),
          catchError(({ error }) => of(new SetHeatingAlgoFailure(error, action.payload)))
        );
      })
    )
  );

  setPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnumModulesActionsVaillant.SetPeriod),
      switchMap((action: SetPeriod) => {
        return this.moduleServiceVaillant.setPeriod(action.payload).pipe(
          switchMap(() => [
            new SetPeriodSuccess(action.payload)
          ]),
          catchError(({ error }) => of(new SetPeriodFailure(error)))
        );
      })
    )
  );



  setDomesticHotWaterTemperature$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetDomesticHotWaterTemperature>(EnumModulesActionsVaillant.SetDomesticHotWaterTemperature),
      switchMap((action) => {
        return this.homeService.setHomeConfig(action.payload).pipe(
          switchMap((res) => {
            return [new SetDomesticHotWaterTemperatureSuccess(action.payload)];
          }),
          catchError(({ error }) => {
            return this.lang.translate('common-app.__UNKNOWN_ERROR_OCCURED').pipe(
              take(1),
              mergeMap((val: string) => {
                const payload = { message: {title: val, text: ''}};
                return [new GlobalError(payload), new SetDomesticHotWaterTemperatureFailure(error)];
              })
            );
          })
        );
      })
    )
  );

}
