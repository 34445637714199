import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { CallbackService } from './callback.service';
import { GLOBAL_NAVCOUNTER, NavigationCounter } from './navigation-counter.service';
import { NavigationEnd, NavigationStart } from '@angular/router';

interface Header {
  title: string;
  needBack?: boolean;
  needClose?: boolean;
  currentChanged?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  /**
   * Array containing history of navigation headers
   */
  protected headersList: Header[] = [];

  /**
   * Header subject
   */
  protected header = new Subject<Header>();

  /**
   * Header observer
   */
  public header$ = this.header.asObservable();

  /**
   * Previous header stored in case we want to do a changeCurrent
   */
  protected tempHeader: Header;

  /**
   * If location.back(), then pop headersList
   */
  constructor(
    private callBack: CallbackService,
    @Inject(GLOBAL_NAVCOUNTER) private navCounter: NavigationCounter
  ) {
    const popHeaderOnBack = () => {
      if (this.headersList.length > 1) {
        for( let i = this.headersList.length; i > this.navCounter.nbHistoryEntries; i--) {
          this.pop();
        }
      }
    };

    this.navCounter.counter$.subscribe( (events: [NavigationStart, NavigationEnd]) => {
      if (events[0].navigationTrigger === 'popstate') {
        popHeaderOnBack();
      }
    })
  }

  /**
   * Push new title object to headersList and notify the subject
   */
  push(header: Header) {
    // Send setTitle callback to Native Apps
    this.callBack.call(this.callBack.list.setTitle, header);

    // If headers are the same, don't push a new one
    if (this.headersList.length > 0 && header.title === this.lastItem().title) {
      return;
    }

    // By default needBack is set to true
    if (typeof header.needBack === 'undefined') {
      header.needBack = true;
    }

    // On first call, force needBack to false
    if (this.headersList.length === 0) {
      header.needBack = false;
    }

    // Store the header
    this.headersList.push(header);

    this.dispatchLastHeader();
  }

  /**
   * Pop last title object from titleList and notify the subject
   */
  protected pop() {
    this.headersList.pop();
    this.dispatchLastHeader();
  }

  /**
   * Dispatch last header stored
   */
  protected dispatchLastHeader() {
    this.header.next(this.headersList[this.headersList.length - 1]);
  }

  private lastItem() {
    return this.headersList[this.headersList.length - 1];
  }

}
