import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import 'hammerjs';
import { HammerGestureConfig } from '@angular/platform-browser';

import { SwitchComponent } from './switch.component';
import { FormsModule } from '@angular/forms';

export class MyHammerConfig extends HammerGestureConfig  {
  overrides = {
    pan: {threshold: 20} // override default settings
  } as any;
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    SwitchComponent,
  ],
  declarations: [SwitchComponent]
})
export class SwitchModule { }
