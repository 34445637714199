export class Const {
    public static NARestErrorCode = {
        'ACCESS_TOKEN_MISSING' : 1,
        'INVALID_ACCESS_TOKEN' : 2,
        'ACCESS_TOKEN_EXPIRED' : 3,
        'INCONSISTENCY_ERROR' : 4,
        'APPLICATION_DEACTIVATED' : 5,
        'INVALID_EMAIL' : 6,
        'NOTHING_TO_MODIFY' : 7,
        'EMAIL_ALREADY_EXISTS' : 8,
        'DEVICE_NOT_FOUND' : 9,
        'MISSING_ARGS' : 10,
        'INTERNAL_ERROR' : 11,
        'DEVICE_OR_SECRET_NO_MATCH' : 12,
        'OPERATION_FORBIDDEN' : 13,
        'APPLICATION_NAME_ALREADY_EXISTS' : 14,
        'NO_PLACES_IN_DEVICE' : 15,
        'MGT_KEY_MISSING' : 16,
        'BAD_MGT_KEY' : 17,
        'DEVICE_ID_ALREADY_EXISTS' : 18,
        'IP_NOT_FOUND' : 19,
        'TOO_MANY_USER_WITH_IP' : 20,
        'INVALID_ARG' : 21,
        'APPLICATION_NOT_FOUND' : 22,
        'USER_NOT_FOUND' : 23,
        'INVALID_TIMEZONE' : 24,
        'INVALID_DATE' : 25,
        'MAX_USAGE_REACHED' : 26,
        'MEASURE_ALREADY_EXISTS' : 27,
        'ALREADY_DEVICE_OWNER' : 28,
        'INVALID_IP' : 29,
        'INVALID_REFRESH_TOKEN' : 30,
        'NOT_FOUND' : 31,
        'BAD_PASSWORD' : 32,
        'FORCE_ASSOCIATE' : 33,
        'MODULE_ALREADY_PAIRED' : 34,
        'UNABLE_TO_EXECUTE' : 35,
        'PROHIBITTED_STRING' : 36,
        'CAMERA_NO_SPACE_AVAILABLE' : 37,
        'PASSWORD_COMPLEXITY_TOO_LOW' : 38,
        'TOO_MANY_CONNECTION_FAILURE' : 39,
        'JSON_INVALID_ENCODING' : 40,
        'DEVICE_UNREACHABLE' : 41,
        'ROOM_NOT_EMPTY' : 42,
        'REQUIRED_TFA' : 43,
        'SECURITY_KEY_LAST_RETRY' : 44,
        'SECURITY_KEY_ERASED' : 45,
        'INVALID_ACCESS_TOKEN_DEVICE' : 46,
        'INVALID_THIRD_PARTY_ACCESS_TOKEN' : 47,
        'THIRD_PARTY_ACCESS_TOKEN_EXPIRED' : 48,
        'TOO_MANY_ROOMS' : 49,
    };
    public static NAClientErrorCode = {
        'OAUTH_INVALID_GRANT' : -1,
        'OAUTH_OTHER' : -2,
    };
    public static NAScopes = {
        'SCOPE_READ_STATION' : 'read_station',
        'SCOPE_READ_THERM' : 'read_thermostat',
        'SCOPE_WRITE_THERM' : 'write_thermostat',
        'SCOPE_READ_CAMERA' : 'read_camera',
        'SCOPE_WRITE_CAMERA' : 'write_camera',
        'SCOPE_ACCESS_CAMERA' : 'access_camera',
        'SCOPE_READ_PRESENCE' : 'read_presence',
        'SCOPE_WRITE_PRESENCE' : 'write_presence',
        'SCOPE_ACCESS_PRESENCE' : 'access_presence',
        'SCOPE_READ_SMOKEDETECTOR' : 'read_smokedetector',
        'SCOPE_WRITE_SMOKEDETECTOR' : 'write_smokedetector',
        'SCOPE_READ_MAGELLAN' : 'read_magellan',
        'SCOPE_WRITE_MAGELLAN' : 'write_magellan',
        'SCOPE_READ_BUBENDORFF' : 'read_bubendorff',
        'SCOPE_WRITE_BUBENDORFF' : 'write_bubendorff',
        'SCOPE_READ_VELUX' : 'read_velux',
        'SCOPE_WRITE_VELUX' : 'write_velux',
        'SCOPE_ACCESS_VELUX' : 'access_velux',
        'SCOPE_READ_MULLER' : 'read_muller',
        'SCOPE_WRITE_MULLER' : 'write_muller',
        'SCOPE_READ_JUNE' : 'read_june',
        'SCOPE_WRITE_JUNE' : 'write_june',
        'SCOPE_READ_HC' : 'read_homecoach',
        'SCOPE_WRITE_HC' : 'write_homecoach',
        'SCOPE_READ_HOME' : 'read_home',
        'SCOPE_WRITE_HOME' : 'write_home',
        'SCOPE_READ_DOORBELL' : 'read_doorbell',
        'SCOPE_WRITE_DOORBELL' : 'write_doorbell',
        'SCOPE_ACCESS_DOORBELL' : 'access_doorbell',
        'SCOPE_GOOGLE_HOME' : 'googlge_home_scopes',
        'SCOPE_SECURITY' : 'security_scopes',
        'SCOPE_ENERGY' : 'energy_scopes',
        'SCOPE_WEATHER' : 'weather_scopes',
        'SCOPE_AIRCAIRE' : 'aircare_scopes',
        'SCOPE_VELUX' : 'velux_scopes',
        'SCOPE_MULLER' : 'muller_scopes',
        'SCOPE_VAILLANT' : 'vaillant_scopes',
        'SCOPE_MAGELLAN' : 'magellan_scopes',
        'SCOPE_MAGELLAN_LOW' : 'magellan_low_scopes',
        'scopesMapping' : {
            'googlge_home_scopes' : [
                'read_thermostat',
                'write_thermostat',
                'read_station',
                'read_homecoach',
                'read_camera',
                'access_camera',
                'read_presence',
                'access_presence',
            ],
            'security_scopes' : [
                'read_camera',
                'write_camera',
                'access_camera',
                'read_presence',
                'write_presence',
                'access_presence',
                'read_smokedetector',
                'write_smokedetector',
                'read_doorbell',
                'write_doorbell',
                'access_doorbell',
            ],
            'energy_scopes' : [
                'read_station',
                'read_thermostat',
                'write_thermostat',
            ],
            'weather_scopes' : [
                'read_station',
                'read_thermostat',
                'write_thermostat',
            ],
            'aircare_scopes' : [
                'read_homecoach',
                'write_homecoach',
            ],
            'velux_scopes' : [
                'read_velux',
                'write_velux',
                'access_velux',
            ],
            'muller_scopes' : [
                'read_muller',
                'write_muller',
            ],
            'vaillant_scopes' : [
                'read_thermostat',
                'write_thermostat',
            ],
            'magellan_scopes' : [
                'read_magellan',
                'write_magellan',
                'read_bubendorff',
                'write_bubendorff',
                'read_thermostat',
                'write_thermostat',
            ],
            'magellan_low_scopes' : [
                'read_magellan',
                'write_magellan',
                'read_bubendorff',
                'write_bubendorff',
            ],
            'all_scopes' : [
                'read_station',
                'read_thermostat',
                'write_thermostat',
                'read_camera',
                'write_camera',
                'access_camera',
                'read_presence',
                'write_presence',
                'access_presence',
                'read_june',
                'write_june',
                'read_home',
                'write_home',
                'read_homecoach',
                'write_homecoach',
                'read_magellan',
                'write_magellan',
                'read_bubendorff',
                'write_bubendorff',
                'read_velux',
                'write_velux',
                'access_velux',
                'read_smokedetector',
                'write_smokedetector',
                'read_muller',
                'write_muller',
                'read_doorbell',
                'write_doorbell',
                'access_doorbell',
            ],
        },
        'defaultScopes' : [
            'read_station',
        ],
        'validScopes' : [
            'read_station',
            'read_thermostat',
            'write_thermostat',
            'read_camera',
            'write_camera',
            'access_camera',
            'read_presence',
            'write_presence',
            'access_presence',
            'read_june',
            'write_june',
            'read_home',
            'write_home',
            'read_homecoach',
            'write_homecoach',
            'read_magellan',
            'write_magellan',
            'read_bubendorff',
            'write_bubendorff',
            'read_velux',
            'write_velux',
            'access_velux',
            'read_smokedetector',
            'write_smokedetector',
            'read_muller',
            'write_muller',
            'read_doorbell',
            'write_doorbell',
            'access_doorbell',
        ],
        'basicScopes' : [
            'read_station',
            'read_thermostat',
            'write_thermostat',
            'read_camera',
            'write_camera',
            'read_presence',
            'write_presence',
            'read_june',
            'write_june',
            'read_homecoach',
            'read_magellan',
            'write_magellan',
            'read_bubendorff',
            'write_bubendorff',
            'read_velux',
            'write_velux',
            'read_smokedetector',
            'write_smokedetector',
            'read_muller',
            'write_muller',
            'read_doorbell',
            'write_doorbell',
        ],
        'thirdPartyScopes' : [
            'read_station',
            'read_thermostat',
            'write_thermostat',
            'read_camera',
            'read_presence',
            'read_homecoach',
        ],
        'LeGrandUserScopes' : [
            'read_magellan',
            'write_magellan',
            'read_bubendorff',
            'write_bubendorff',
            'read_home',
            'write_home',
        ],
        'VeluxUserScopes' : [
            'read_velux',
            'write_velux',
            'access_velux',
            'read_home',
            'write_home',
        ],
        'MullerUserScopes' : [
            'read_muller',
            'write_muller',
            'read_home',
            'write_home',
        ],
        'readHomeScopes' : [
            'read_thermostat',
            'read_camera',
            'read_presence',
            'read_magellan',
            'read_bubendorff',
            'read_velux',
            'read_smokedetector',
            'read_muller',
            'read_doorbell',
        ],
        'writeHomeScopes' : [
            'write_thermostat',
            'write_camera',
            'write_presence',
            'write_magellan',
            'write_bubendorff',
            'write_velux',
            'write_smokedetector',
            'write_muller',
            'read_doorbell',
        ],
    };
    public static NAWebhook = {
        'UNIT_TEST' : 'test',
        'LOW_BATTERY' : 'low_battery',
        'BOILER_NOT_RESPONDING' : 'boiler_not_responding',
        'BOILER_RESPONDING' : 'boiler_responding',
        'EBUS_EBUS_ERROR' : 'ebus_error',
        'EBUS_BOILER_ERROR' : 'boiler_error',
        'EBUS_MAINTENANCE_STATUS' : 'maintenance_status',
        'EBUS_REFILL_WATER' : 'refill_water',
        'DISCONNECTED_24H' : 'no_connect_24h',
        'FIRST_CONNECTION_WITH_OEM_SERIAL' : 'oem_serial_info',
        'INFO_MESSAGE_SEEN' : 'info_message_seen',
        'STATE_CHANGED' : 'state_changed',
    };
    public static NAPushFilter = {
        'PF_ALL' : 'all',
        'PF_NORMAL' : 'normal',
        'PF_SILENT' : 'silent',
    };
    public static NAPublicConst = {
        'UNIT_METRIC' : 0,
        'UNIT_US' : 1,
        'UNIT_TYPE_NUMBER' : 2,
        'UNIT_WIND_KMH' : 0,
        'UNIT_WIND_MPH' : 1,
        'UNIT_WIND_MS' : 2,
        'UNIT_WIND_BEAUFORT' : 3,
        'UNIT_WIND_KNOT' : 4,
        'UNIT_WIND_NUMBER' : 5,
        'UNIT_PRESSURE_MBAR' : 0,
        'UNIT_PRESSURE_MERCURY' : 1,
        'UNIT_PRESSURE_TORR' : 2,
        'UNIT_PRESSURE_NUMBER' : 3,
        'FEEL_LIKE_HUMIDEX_ALGO' : 0,
        'FEEL_LIKE_HEAT_ALGO' : 1,
        'FEEL_LIKE_NUMBER' : 2,
        'KIND_READ_TIMELINE' : 0,
        'KIND_NOT_READ_TIMELINE' : 1,
        'KIND_BOTH_TIMELINE' : 2,
    };
    public static NAConstants = {
        'FAVORITES_MAX' : 5,
    };
    public static NAStationSensorsMinMax = {
        'TEMP_MIN' : -40,
        'TEMP_MAX' : 60,
        'HUM_MIN' : 1,
        'HUM_MAX' : 99,
        'CO2_MIN' : 300,
        'CO2_MAX' : 4000,
        'PRESSURE_MIN' : 700,
        'PRESSURE_MAX' : 1300,
        'NOISE_MIN' : 10,
        'NOISE_MAX' : 120,
        'RAIN_MIN' : 2,
        'RAIN_MAX' : 300,
        'WIND_MIN' : 5,
        'WIND_MAX' : 150,
    };
    public static NAScheduleTime = {
        'WEEK_WAKEUP_TIME_DEFAULT' : 420,
        'WEEK_SLEEP_TIME_DEFAULT' : 1320,
        'WEEK_WORK_TIME_DEFAULT' : 480,
        'WEEK_WORK_TIME_BACK_DEFAULT' : 1140,
        'WEEK_WORK_LUNCH_TIME_DEFAULT' : 720,
        'WEEK_WORK_LUNCH_TIME_BACK_DEFAULT' : 810,
    };
    public static NAWifiRssiThreshold = {
        'RSSI_THRESHOLD_0' : 86,
        'RSSI_THRESHOLD_1' : 71,
        'RSSI_THRESHOLD_2' : 56,
    };
    public static NACameraWifiRssiThreshold = {
        'CWRT_THRESHOLD_0' : 75,
        'CWRT_THRESHOLD_1' : 65,
        'CWRT_THRESHOLD_2' : 50,
    };
    public static NARadioRssiTreshold = {
        'RADIO_THRESHOLD_0' : 90,
        'RADIO_THRESHOLD_1' : 80,
        'RADIO_THRESHOLD_2' : 70,
        'RADIO_THRESHOLD_3' : 60,
    };
    public static NABatteryLevelThermostat = {
        'THERMOSTAT_UPPER_BOUND' : 4500,
        'THERMOSTAT_LOWER_BOUND' : 3000,
        'THERMOSTAT_BATTERY_LEVEL_0' : 4100,
        'THERMOSTAT_BATTERY_LEVEL_1' : 3600,
        'THERMOSTAT_BATTERY_LEVEL_2' : 3200,
        'THERMOSTAT_BATTERY_LEVEL_3' : 3000,
    };
    public static NABatteryLevelNRVModule = {
        'NRV_UPPER_BOUND' : 2950,
        'NRV_LOWER_BOUND' : 2500,
        'NRV_BATTERY_LEVEL_0' : 2950,
        'NRV_BATTERY_LEVEL_1' : 2750,
        'NRV_BATTERY_LEVEL_2' : 2600,
        'NRV_BATTERY_LEVEL_3' : 2500,
    };
    public static NABatteryLevelWindGaugeModule = {
        'WG_UPPER_BOUND' : 6000,
        'WG_LOWER_BOUND' : 3950,
        'WG_BATTERY_LEVEL_0' : 5590,
        'WG_BATTERY_LEVEL_1' : 5180,
        'WG_BATTERY_LEVEL_2' : 4770,
        'WG_BATTERY_LEVEL_3' : 4360,
    };
    public static NABatteryLevelIndoorModule = {
        'INDOOR_UPPER_BOUND' : 6000,
        'INDOOR_LOWER_BOUND' : 4200,
        'INDOOR_BATTERY_LEVEL_0' : 5640,
        'INDOOR_BATTERY_LEVEL_1' : 5280,
        'INDOOR_BATTERY_LEVEL_2' : 4920,
        'INDOOR_BATTERY_LEVEL_3' : 4560,
    };
    public static NABatteryLevelModule = {
        'BATTERY_UPPER_BOUND' : 6000,
        'BATTERY_LOWER_BOUND' : 3600,
        'BATTERY_LEVEL_0' : 5500,
        'BATTERY_LEVEL_1' : 5000,
        'BATTERY_LEVEL_2' : 4500,
        'BATTERY_LEVEL_3' : 4000,
    };
    public static NABatteryLevelTag = {
        'BATTERY_UPPER_BOUND' : 6000,
        'BATTERY_LOWER_BOUND' : 4200,
        'BATTERY_LEVEL_0' : 5500,
        'BATTERY_LEVEL_1' : 5100,
        'BATTERY_LEVEL_2' : 4700,
        'BATTERY_LEVEL_3' : 4400,
    };
    public static NABatteryLevelMagellan = {
        'NLT_UPPER_BOUND' : 3400,
        'NLT_LOWER_BOUND' : 1800,
        'NLT_LEVEL_0' : 2799,
        'NLT_LEVEL_1' : 2599,
        'NLT_LEVEL_2' : 2099,
        'NLT_LEVEL_3' : 1999,
    };
    public static NABatteryLevelNXS = {
        'NXS_UPPER_BOUND' : 4800,
        'NXS_LOWER_BOUND' : 3000,
        'NXS_LEVEL_0' : 4250,
        'NXS_LEVEL_1' : 4000,
        'NXS_LEVEL_2' : 3650,
        'NXS_LEVEL_3' : 3350,
    };
    public static NABatteryLevelNXD = {
        'NXD_UPPER_BOUND' : 3200,
        'NXD_LOWER_BOUND' : 2150,
        'NXD_LEVEL_0' : 2830,
        'NXD_LEVEL_1' : 2630,
        'NXD_LEVEL_2' : 2430,
        'NXD_LEVEL_3' : 2260,
    };
    public static NATimeBeforeDataExpire = {
        'TIME_BEFORE_UNKNONWN_THERMOSTAT' : 7200,
        'TIME_BEFORE_UNKNONWN_STATION' : 7200,
        'TIME_BEFORE_UNKNONWN_CAMERA' : 46800,
    };
    public static NAHeatingEnergy = {
        'THERMOSTAT_HEATING_ENERGY_UNKNOWN' : 'unknown',
        'THERMOSTAT_HEATING_ENERGY_GAS' : 'gas',
        'THERMOSTAT_HEATING_ENERGY_OIL' : 'oil',
        'THERMOSTAT_HEATING_ENERGY_WOOD' : 'wood',
        'THERMOSTAT_HEATING_ENERGY_ELEC' : 'elec',
        'THERMOSTAT_HEATING_ENERGY_PAC' : 'pac',
        'THERMOSTAT_HEATING_ENERGY_SUNHYBRID' : 'sunhybrid',
    };
    public static NAHeatingType = {
        'THERMOSTAT_HEATING_TYPE_UNKNOWN' : 'unknown',
        'THERMOSTAT_HEATING_TYPE_SUBFLOOR' : 'subfloor',
        'THERMOSTAT_HEATING_TYPE_RADIATOR' : 'radiator',
    };
    public static NAHomeType = {
        'THERMOSTAT_HOME_TYPE_UNKNOWN' : 'unknown',
        'THERMOSTAT_HOME_TYPE_HOUSE' : 'house',
        'THERMOSTAT_HOME_TYPE_FLAT' : 'flat',
    };
    public static NAPluvioLevel = {
        'RAIN_NULL' : 0.1,
        'RAIN_WEAK' : 3,
        'RAIN_MIDDLE' : 8,
        'RAIN_STRONG' : 15,
    };
    public static NAPluvioCalibration = {
        'RAIN_SCALE_MIN' : 0.01,
        'RAIN_SCALE_MAX' : 0.25,
        'RAIN_SCALE_ML_MIN' : 0,
        'RAIN_SCALE_ML_MAX' : 3,
    };
    public static NAEventType = {
        'ET_START_UPDATE' : 'start_update',
        'ET_END_UPDATE' : 'end_update',
        'ET_UPDATE_DL_START' : 'update_dl_start',
        'ET_UPDATE_DL_FAIL' : 'update_dl_fail',
        'ET_NEW_DEVICE' : 'new_device',
    };
    public static NACameraType = {
        'CT_PRESENCE' : 'NOC',
        'CT_WELCOME' : 'NACamera',
    };
    public static NACameraEventType = {
        'CET_PERSON' : 'person',
        'CET_PERSON_AWAY' : 'person_away',
        'CET_PERSON_HOME' : 'person_home',
        'CET_HOME_AWAY' : 'home_away',
        'CET_MODEL_IMPROVED' : 'model_improved',
        'CET_MOVEMENT' : 'movement',
        'CET_CONNECTION' : 'connection',
        'CET_DISCONNECTION' : 'disconnection',
        'CET_ON' : 'on',
        'CET_OFF' : 'off',
        'CET_END_RECORDING' : 'end_recording',
        'CET_LIVE' : 'live_rec',
        'CET_BOOT' : 'boot',
        'CET_SD' : 'sd',
        'CET_ALIM' : 'alim',
        'CET_START_UPDATE' : 'start_update',
        'CET_UPDATE_DL_START' : 'update_dl_start',
        'CET_UPDATE_DL_FAIL' : 'update_dl_fail',
        'CET_END_UPDATE' : 'end_update',
        'CET_MODULE_NEW' : 'new_module',
        'CET_MODULE_CONNECT' : 'module_connect',
        'CET_MODULE_DISCONNECT' : 'module_disconnect',
        'CET_MODULE_ON' : 'module_on',
        'CET_MODULE_OFF' : 'module_off',
        'CET_MODULE_LOW_BATTERY' : 'module_low_battery',
        'CET_MODULE_START_UPDATE' : 'module_start_update',
        'CET_MODULE_FAIL_UPDATE' : 'module_fail_update',
        'CET_MODULE_END_UPDATE' : 'module_end_update',
        'CET_MODULE_PAIRING' : 'module_pairing',
        'CET_TAG_SMALL_MOVE' : 'tag_small_move',
        'CET_TAG_BIG_MOVE' : 'tag_big_move',
        'CET_TAG_UNINSTALLED' : 'tag_uninstalled',
        'CET_TAG_OPEN' : 'tag_open',
        'CET_NEW_USER' : 'new_user',
        'CET_SPECIAL' : 'special',
        'CET_ALARM_STARTED' : 'alarm_started',
        'CET_ALARM_STOPPED' : 'alarm_stopped',
        'CET_FTP' : 'ftp',
        'CET_NEW_FEATURE_AVAILABLE' : 'new_feature_available',
        'CET_CLOUD' : 'cloud',
        'CET_OUTDOOR' : 'outdoor',
        'CET_HUMAN' : 'human',
        'CET_VEHICLE' : 'vehicle',
        'CET_ANIMAL' : 'animal',
        'CET_QRCODE_DETECTED' : 'qrcode_detected',
        'CET_QRCODE_OK' : 'qrcode_ok',
        'CET_QRCODE_BAD' : 'bad_qrcode',
        'CET_LIGHT_MODE' : 'light_mode',
        'CET_TIMELAPSE_STATUS' : 'timelapse_status',
        'CET_DAILYSUMMARY' : 'daily_summary',
        'CET_TEMPERATURE_STATUS' : 'temp_status',
        'CET_INDOOR' : 'indoor',
        'CET_MODULE_TAMPERED' : 'module_tampered',
        'CET_MODULE_SOUNDING' : 'module_sounding',
        'CET_PAIRING' : 'pairing',
        'CET_MODULE_REMOVE' : 'remove_module',
        'presence_outdoor_subevents' : [
            'human',
            'vehicle',
            'animal',
        ],
        'validEvents' : [
            'person',
            'person_away',
            'person_home',
            'home_away',
            'model_improved',
            'movement',
            'connection',
            'disconnection',
            'on',
            'off',
            'end_recording',
            'live_rec',
            'boot',
            'sd',
            'alim',
            'start_update',
            'update_dl_start',
            'update_dl_fail',
            'end_update',
            'new_module',
            'module_connect',
            'module_disconnect',
            'module_on',
            'module_off',
            'module_low_battery',
            'module_start_update',
            'module_fail_update',
            'module_end_update',
            'module_pairing',
            'tag_small_move',
            'tag_big_move',
            'tag_uninstalled',
            'tag_open',
            'new_user',
            'special',
            'alarm_started',
            'alarm_stopped',
            'ftp',
            'new_feature_available',
            'cloud',
            'outdoor',
            'human',
            'vehicle',
            'animal',
            'qrcode_detected',
            'qrcode_ok',
            'bad_qrcode',
            'light_mode',
            'timelapse_status',
            'daily_summary',
            'temp_status',
            'indoor',
            'module_tampered',
            'module_sounding',
            'pairing',
            'remove_module',
        ],
    };
    public static NACameraEventInfo = {
        'CEI_ID' : 'id',
        'CEI_TYPE' : 'type',
        'CEI_TIME' : 'time',
        'CEI_PERSON_ID' : 'person_id',
        'CEI_SNAPSHOT' : 'snapshot',
        'CEI_VIDEO_ID' : 'video_id',
        'CEI_VIDEO_STATUS' : 'video_status',
        'CEI_CAMERA_ID' : 'camera_id',
        'CEI_DEVICE_ID' : 'device_id',
        'CEI_MESSAGE' : 'message',
        'CEI_SUB_TYPE' : 'sub_type',
        'CEI_IS_ARRIVAL' : 'is_arrival',
        'CEI_ALARM_ID' : 'alarm_id',
        'CEI_ALARM_TYPE' : 'alarm_type',
        'CEI_SUB_MESSAGE' : 'sub_message',
        'CEI_MODULE_ID' : 'module_id',
        'CEI_CLOUD_STATUS' : 'cloud_status',
        'CEI_CLOUD_OK' : 'ok',
        'CEI_CLOUD_UNREACHABLE' : 'unreachable',
        'CEI_CLOUD_SHARED_LINK' : 'shared_link',
        'CEI_CLOUD_PROVIDER' : 'cloud_provider',
        'CEI_EVENT_LIST' : 'event_list',
        'CEI_VIGNETTE' : 'vignette',
        'CEI_OFFSET' : 'offset',
        'CEI_TRACKER_ID' : 'tracker_id',
        'CEI_CATEGORY' : 'category',
        'CEI_VERIFIED' : 'verified',
        'CEI_PREDICTIONS' : 'predictions',
        'CEI_SPOT_ERROR' : 'spot_error',
        'CEI_HIGHLIGHT' : 'highlight',
    };
    public static NACameraPersonInfo = {
        'CPI_ID' : 'id',
        'CPI_LAST_SEEN' : 'last_seen',
        'CPI_FACE' : 'face',
        'CPI_URL' : 'url',
        'CPI_OUT_OF_SIGHT' : 'out_of_sight',
        'CPI_PSEUDO' : 'pseudo',
        'CPI_RECORD' : 'record',
        'CPI_NOTIFY_ON_ARRIVAL' : 'notify_on_arrival',
        'CPI_NOTIFICATION_BEGIN' : 'notification_begin',
        'CPI_NOTIFICATION_END' : 'notification_end',
        'CPI_IS_CURRENT_USER' : 'is_current_user',
        'CPI_UIDS' : 'uids',
        'CPI_POSSIBLE_KNOWN' : 'possible_known',
        'CPI_SHOW_PK_SCREEN' : 'show_pk_screen',
        'CPI_MODEL_STRENGTH' : 'model_strength',
    };
    public static NACameraImageInfo = {
        'CII_ID' : 'id',
        'CII_VERSION' : 'version',
        'CII_KEY' : 'key',
        'CII_FILENAME' : 'filename',
        'CII_URL' : 'url',
    };
    public static NACameraHomeInfo = {
        'CHI_ID' : 'id',
        'CHI_NAME' : 'name',
        'CHI_GONE_AFTER' : 'gone_after',
        'CHI_PLACE' : 'place',
        'CHI_PERSONS' : 'persons',
        'CHI_EVENTS' : 'events',
        'CHI_CAMERAS' : 'cameras',
        'CHI_PRESENCES' : 'presences',
        'CHI_ADMIN_ACCESS_CODE' : 'admin_access_code',
        'CHI_SHARE_INFO' : 'share_info',
        'CHI_NOTIFY_UNKNOWNS' : 'notify_unknowns',
        'CHI_NOTIFY_MOVEMENTS' : 'notify_movements',
        'CHI_RECORD_MOVEMENTS' : 'record_movements',
        'CHI_SMART_NOTIFS' : 'smart_notifs',
        'CHI_RECORD_ALARMS' : 'record_alarms',
        'CHI_RECORD_ANIMALS' : 'record_animals',
        'CHI_NOTIFY_ANIMALS' : 'notify_animals',
        'CHI_EVENTS_TTL' : 'events_ttl',
        'CHI_CLOUD_RECORD_SETTINGS' : 'cloud_record_settings',
        'CHI_PRESENCE_RECORD_HUMANS' : 'presence_record_humans',
        'CHI_PRESENCE_RECORD_VEHICLES' : 'presence_record_vehicles',
        'CHI_PRESENCE_RECORD_ANIMALS' : 'presence_record_animals',
        'CHI_PRESENCE_RECORD_ALARMS' : 'presence_record_alarms',
        'CHI_PRESENCE_RECORD_MOVEMENTS' : 'presence_record_movements',
        'CHI_PRESENCE_NOTIFY_FROM' : 'presence_notify_from',
        'CHI_PRESENCE_NOTIFY_TO' : 'presence_notify_to',
        'CHI_PRESENCE_ENABLE_NOTIFY_FROM_TO' : 'presence_enable_notify_from_to',
        'CHI_PENDING_OWNERS' : 'pending_owners',
        'CHI_SIRENE_SOUND_UNKNOWNS' : 'siren_sound_unknowns',
        'CHI_SIRENE_SOUND_MOVEMENTS' : 'siren_sound_movements',
        'CHI_SIRENE_SOUND_ANIMALS' : 'siren_sound_animals',
        'CHI_RECORD_HUMANS' : 'record_humans',
        'CHI_NOTIFY_HUMANS' : 'notify_humans',
    };
    public static NAPresenceHomeInfo = {
        'PHI_PRESENCE_RECORD_HUMANS' : 'presence_record_humans',
        'PHI_PRESENCE_RECORD_VEHICLES' : 'presence_record_vehicles',
        'PHI_PRESENCE_RECORD_ANIMALS' : 'presence_record_animals',
        'PHI_PRESENCE_RECORD_ALARMS' : 'presence_record_alarms',
        'PHI_PRESENCE_NOTIFY_FROM' : 'presence_notify_from',
        'PHI_PRESENCE_NOTIFY_TO' : 'presence_notify_to',
        'PHI_PRESENCE_ENABLE_NOTIFY_FROM_TO' : 'presence_enable_notify_from_to',
    };
    public static NACameraInfo = {
        'CI_ID' : 'id',
        'CI_TYPE' : 'type',
        'CI_NAME' : 'name',
        'CI_LIVE_URL' : 'live_url',
        'CI_STATUS' : 'status',
        'CI_STATUS_LOCKED' : 'status_locked',
        'CI_SD_STATUS' : 'sd_status',
        'CI_ALIM_STATUS' : 'alim_status',
        'CI_IS_LOCAL' : 'is_local',
        'CI_VPN_URL' : 'vpn_url',
        'CI_FIRMWARE' : 'firmware',
        'CI_CAMERA_CONNECTION' : 'connection',
        'CI_UPDATING' : 'updating',
        'CI_MODULES' : 'modules',
        'CI_LAST_FTP_EVENT' : 'last_ftp_event',
        'CI_CLOUD_PROVIDER' : 'cloud_provider',
        'CI_LIGHT_MODE' : 'light_mode_status',
        'CI_TIMELAPSE_AVAILABLE' : 'timelapse_available',
        'CI_TEMPERATURE_STATUS' : 'temp_status',
        'CI_LAST_SD_OK' : 'last_sd_ok',
        'CI_USE_PINCODE' : 'use_pin_code',
        'CI_DATE_SETUP' : 'date_setup',
        'CI_LAST_SETUP' : 'last_setup',
        'CI_HOMEKIT_STATUS' : 'homekit_status',
        'CI_PENDING_OWNERS' : 'pending_owners',
    };
    public static NACameraModuleInfo = {
        'CMI_ID' : 'id',
        'CMI_NAME' : 'name',
        'CMI_TYPE' : 'type',
        'CMI_BATTERY' : 'battery',
        'CMI_BATTERY_PERCENT' : 'battery_percent',
        'CMI_STATUS' : 'status',
        'CMI_RF' : 'rf',
        'CMI_FIRMWARE' : 'firmware',
        'CMI_UPDATING' : 'updating',
        'CMI_UPDATE_TYPE' : 'update_type',
        'CMI_LOW_BATTERY' : 'low_battery',
        'CMI_LAST_SEEN' : 'last_seen',
        'CMI_DATE_SETUP' : 'date_setup',
        'CMI_ROOM_ID' : 'room',
        'CMI_MONITORING' : 'monitoring',
    };
    public static NACameraTagInfo = {
        'CTI_NOTIFY_RULE' : 'notify_rule',
        'CTI_RECORD_RULE' : 'record_rule',
        'CTI_CATEGORY' : 'category',
        'CTI_LAST_ACTIVITY' : 'last_activity',
        'CTI_SIRENE_SOUND_RULE' : 'siren_sound_rule',
    };
    public static NACameraStatus = {
        'CS_ON' : 'on',
        'CS_OFF' : 'off',
        'CS_DISCONNECTED' : 'disconnected',
    };
    public static NACameraTagStatus = {
        'CTS_OPEN' : 'open',
        'CTS_CLOSED' : 'closed',
        'CTS_UNDEFINED' : 'undefined',
        'CTS_NO_NEWS' : 'no_news',
        'CTS_CALIBRATING' : 'calibrating',
        'CTS_CALIBRATION_FAILED' : 'calibration_failed',
        'CTS_MAINTENANCE' : 'maintenance',
        'CTS_WEAK_SIGNAL' : 'weak_signal',
    };
    public static NACameraSireneStatus = {
        'CSS_NO_NEWS' : 'no_news',
        'CSS_UNDEFINED' : 'undefined',
        'CSS_NO_SOUND' : 'no_sound',
        'CSS_WARNING' : 'warning',
        'CSS_SOUND' : 'sound',
        'CSS_PLAYING_RECORD_0' : 'playing_record_0',
        'CSS_PLAYING_RECORD_1' : 'playing_record_1',
        'CSS_PLAYING_RECORD_2' : 'playing_record_2',
        'validValues' : [
            'no_news',
            'undefined',
            'no_sound',
            'warning',
            'sound',
            'playing_record_0',
            'playing_record_1',
            'playing_record_2',
        ],
    };
    public static NACameraModuleSoundingSubStatus = {
        'CMSSS_SOUNDING' : 1,
        'CMSSS_STOPPED' : 0,
    };
    public static NACameraModuleTamperedSubStatus = {
        'CMTSS_TAMPERED' : 1,
        'CMTSS_READY' : 0,
    };
    public static NACameraRecordRule = {
        'CRR_EMPTY' : 'empty',
        'CRR_ALWAYS' : 'always',
        'CRR_NEVER' : 'never',
        'CRR_ON_ARRIVAL' : 'on_arrival',
    };
    public static NACameraNotifyRule = {
        'CNR_EMPTY' : 'empty',
        'CNR_ALWAYS' : 'always',
        'CNR_NEVER' : 'never',
    };
    public static NACameraNotifyUnknowns = {
        'CNU_EMPTY' : 'empty',
        'CNU_ALWAYS' : 'always',
    };
    public static NACameraNotifyMovements = {
        'CNM_NEVER' : 'never',
        'CNM_EMPTY' : 'empty',
        'CNM_ALWAYS' : 'always',
    };
    public static NACameraRecordMovements = {
        'CRM_NEVER' : 'never',
        'CRM_EMPTY' : 'empty',
        'CRM_ALWAYS' : 'always',
    };
    public static NACameraRecordAlarms = {
        'CRA_NEVER' : 'never',
        'CRA_EMPTY' : 'empty',
        'CRA_ALWAYS' : 'always',
    };
    public static NACameraTagNotifyRule = {
        'CTNR_NEVER' : 'never',
        'CTNR_EMPTY' : 'empty',
        'CTNR_ALWAYS' : 'always',
    };
    public static NACameraTagRecordRule = {
        'CTRR_NEVER' : 'never',
        'CTRR_EMPTY' : 'empty',
        'CTRR_ALWAYS' : 'always',
    };
    public static NACameraSireneSoundRule = {
        'CSSR_NEVER' : 'never',
        'CSSR_EMPTY' : 'empty',
        'CSSR_ALWAYS' : 'always',
    };
    public static NACameraTagSireneSoundRule = {
        'CTSSR_NEVER' : 'never',
        'CTSSR_EMPTY' : 'empty',
        'CTSSR_ALWAYS' : 'always',
    };
    public static NACameraPresenceRecordSettings = {
        'CPRS_IGNORE' : 'ignore',
        'CPRS_RECORD' : 'record',
        'CPRS_RECORD_AND_NOTIFY' : 'record_and_notify',
        'CPRS_EMPTY' : 'empty',
        'CPRS_ALWAYS' : 'always',
        'CPRS_FROM_TIME' : 0,
        'CPRS_TO_TIME' : 86399,
    };
    public static NACameraEventsTimeToLive = {
        'CETTL_NEVER' : 'never',
        'CETTL_ONE_WEEK' : 'one_week',
        'CETTL_ONE_MONTH' : 'one_month',
        'CETTL_ONE_YEAR' : 'one_year',
    };
    public static NACameraQrCodeCommand = {
        'CQCC_INSTALL' : 'START_INSTALL',
        'CQCC_RESET' : 'RESET_HOMEKIT',
    };
    public static APIResponseFields = {
        'APIRF_SYNC_ORDER' : 'sync',
        'APIRF_SYNC_REPORT_ORDER' : 'sync_report',
        'APIRF_KEEP_RECORD_ORDER' : 'keep_record',
        'APIRF_VIDEO_ID' : 'video_id',
        'APIRF_SYNC_ORDER_LIST' : 'sync_order_list',
        'APIRF_EVENTS_LIST' : 'events_list',
        'APIRF_PERSONS_LIST' : 'persons_list',
        'APIRF_HOMES' : 'homes',
        'APIRF_USER' : 'user',
        'APIRF_GLOBAL_INFO' : 'global_info',
        'APIRF_INSTALLED' : 'installed',
        'APIRF_DEVICES' : 'devices',
        'APIRF_UPLOAD_CLOUD' : 'upload_cloud',
        'APIRF_SHOULD_UPDATE_FIRMWARE' : 'should_update',
    };
    public static NAGlobalInfo = {
        'GI_SHOW_TAGS' : 'show_tags',
    };
    public static NASyncCameraOrder = {
        'SCO_DROP' : 'drop',
        'SCO_REPORTED' : 'reported',
        'SCO_KNOWN' : 'known',
        'SCO_UNKNOWN' : 'unknown',
    };
    public static NACameraVideoStatus = {
        'CVS_RECORDING' : 'recording',
        'CVS_DELETED' : 'deleted',
        'CVS_AVAILABLE' : 'available',
        'CVS_ERROR' : 'error',
    };
    public static NACameraSDEvent = {
        'CSDE_ABSENT' : 1,
        'CSDE_INSERTED' : 2,
        'CSDE_FORMATED' : 3,
        'CSDE_OK' : 4,
        'CSDE_DEFECT' : 5,
        'CSDE_INCOMPATIBLE' : 6,
        'CSDE_TOO_SMALL' : 7,
        'issueEvents' : [
            1,
            5,
            6,
            7,
        ],
    };
    public static NACameraPossibleKnownAnswer = {
        'CPKA_YES' : 1,
        'CPKA_MAYBE' : 2,
        'CPKA_NO' : 3,
    };
    public static NACameraAlimSubStatus = {
        'CASS_DEFECT' : 1,
        'CASS_OK' : 2,
    };
    public static NACameraFTPSubStatus = {
        'CFTPSS_OK' : 0,
        'CFTPSS_AUTH_ERROR' : 1,
        'CFTPSS_UPLOAD_ERROR' : 2,
        'CFTPSS_NETWORK_ERROR' : 3,
        'CFTPSS_OTHER_ERROR' : 4,
    };
    public static NACameraUploadCloudSubStatus = {
        'CUCSS_OK' : 0,
        'CUCSS_AUTH_ERROR' : 1,
        'CUCSS_UPLOAD_ERROR' : 2,
        'CUCSS_SLOW_ERROR' : 3,
        'CUCSS_OTHER_ERROR' : 4,
        'CUCSS_FILE_CONFLICT_ERROR' : 5,
        'CUCSS_PERMISSION_ISSUE_ERROR' : 6,
        'CUCSS_FULL_ERROR' : 7,
        'CUCSS_DISALLOWED_NAME_ERROR' : 8,
        'CUCSS_SUCCESS' : 9,
    };
    public static NACameraLightModeStatus = {
        'CLMS_ON' : 0,
        'CLMS_OFF' : 1,
        'CLMS_AUTO' : 2,
        'LIGHT_MODE_ON' : 'on',
        'LIGHT_MODE_OFF' : 'off',
        'LIGHT_MODE_AUTO' : 'auto',
    };
    public static NACameraTimelapseStatus = {
        'CTS_ON' : 0,
        'CTS_OFF' : 1,
    };
    public static NACameraTemperatureStatus = {
        'CTS_OK' : 0,
        'CTS_REDUIT' : 1,
        'CTS_STOPLED' : 2,
        'CTS_STOP' : 3,
    };
    public static NACameraModulePairingSubStatus = {
        'CMPSS_OK' : 0,
        'CMPSS_ERROR' : 1,
        'CMPSS_STARTED' : 2,
        'CMPSS_ALREADY_PAIRED' : 3,
    };
    public static NACameraPairingStatus = {
        'CPS_STOP' : 'stop',
        'CPS_SIREN' : 'security_siren',
        'CPS_DOORTAG' : 'security_doortag',
        'validValues' : [
            'stop',
            'security_siren',
            'security_doortag',
        ],
    };
    public static NACameraModuleUpdateType = {
        'CMUT_RF' : 'RF',
        'CMUT_BLE' : 'BLE',
    };
    public static NACameraTagCategory = {
        'CTCT_DOOR' : 'door',
        'CTCT_WINDOW' : 'window',
        'CTCT_FURNITURE' : 'furniture',
        'CTCT_GATE' : 'gate',
        'CTCT_GARAGE' : 'garage',
        'CTCT_MAIL_BOX' : 'mail_box',
        'CTCT_OTHER' : 'other',
    };
    public static NACameraUploadCloud = {
        'CUC_NEVER' : 'never',
        'CUC_EMPTY' : 'empty',
        'CUC_ALWAYS' : 'always',
        'CUC_ENABLE' : 1,
        'CUC_DISABLE' : 0,
        'allowed_cloud_list' : [
            'dropbox',
        ],
    };
    public static NACameraSupportedCloud = {
        'DROPBOX' : 'dropbox',
    };
    public static NACameraUploadCloudQuality = {
        'CUCQ_LOW' : 'p',
        'CUCQ_MEDIUM' : 'l',
        'CUCQ_HIGH' : 'm',
        'CUCQ_EXCEL' : 'h',
    };
    public static NACameraCTRValues = {
        'CTRV_SUBSCRIBE' : 0,
        'CTRV_LATER' : 1,
        'CTRV_NO' : 2,
        'CTRV_EXIT' : 3,
    };
    public static NACameraQRCodeSubStatus = {
        'CQRCSS_OK' : 0,
        'CQRCSS_NOT_OK' : 1,
    };
    public static NACameraHomekitStatus = {
        'CHS_NOT_SUPPORTED' : 'not_supported',
        'CHS_UPGRADABLE' : 'upgradable',
        'CHS_ENABLED' : 'enabled',
        'CHS_CONFIGURED' : 'configured',
    };
    public static NASmokeDetectorInfo = {
        'SDI_ID' : 'id',
        'SDI_TYPE' : 'type',
        'SDI_NAME' : 'name',
        'SDI_FIRMWARE' : 'firmware',
        'SDI_DATE_SETUP' : 'date_setup',
        'SDI_LAST_SETUP' : 'last_setup',
        'SDI_UPDATING' : 'updating',
        'SDI_STATUS_ACTIVE' : 'status_active',
        'SDI_STATUS_TAMPERED' : 'status_tampered',
        'SDI_TEST_MODE' : 'test_mode',
        'SDI_HUSH_MODE' : 'hush_mode',
        'SDI_SMOKE_DETECTED' : 'smoke_detected',
        'SDI_DETECTION_CHAMBER_STATUS' : 'detection_chamber_status',
        'SDI_BATTERY_ALARM_STATE' : 'battery_alarm_state',
        'SDI_BATTERY_PERCENT' : 'battery_percent',
        'SDI_WIFI_STATUS' : 'wifi_status',
        'SDI_WIFI_STRENGTH' : 'wifi_strength',
        'SDI_LAST_SMOKE_DETECTED_START_TIME' : 'last_smoke_detected_start_time',
        'SDI_LAST_SMOKE_DETECTED_END_TIME' : 'last_smoke_detected_end_time',
        'SDI_LAST_SOUND_TEST_TIME' : 'last_sound_test',
        'SDI_SOUND_TEST_REMINDER' : 'sound_test_reminder',
        'SDI_SOUND_TEST' : 'sound_test',
        'SDI_UPDATE' : 'update',
        'SDI_LAST_WIFI_CONNECTION' : 'last_wifi_connection',
        'SDI_LAST_SEEN' : 'last_seen',
        'SDI_LAST_WIFI_CONNECTION_TRY' : 'last_wifi_connection_try',
        'SDI_CHARACTERISTICS_STATE_NUMBER' : 'characteristics_state_number',
        'SDI_STATUS_LOW_BATTERY' : 'status_low_battery',
        'SDI_USER_ACK_ALERT' : 'user_ack_alert',
    };
    public static NASmokeDetectorEventType = {
        'SDET_HUSH' : 'hush',
        'SDET_SMOKE' : 'smoke',
        'SDET_TEST' : 'test',
        'SDET_TAMPERED' : 'tampered',
        'SDET_WIFI_STATUS' : 'wifi_status',
        'SDET_UNAVAILABLE' : 'unavailable',
        'SDET_BATTERY_STATUS' : 'battery_status',
        'SDET_DETECTION_CHAMBER_STATUS' : 'detection_chamber_status',
        'SDET_SOUND_TEST' : 'sound_test',
    };
    public static NASmokeDetectorHushSubStatus = {
        'SDHSS_HUSH' : 1,
        'SDHSS_READY' : 0,
    };
    public static NASmokeDetectorTamperedSubStatus = {
        'SDTSS_TAMPERED' : 1,
        'SDTSS_READY' : 0,
    };
    public static NASmokeDetectorDetectionChamberSubStatus = {
        'SDDCSS_DUSTY' : 1,
        'SDDCSS_CLEAN' : 0,
    };
    public static NASmokeDetectorWifiSubStatus = {
        'SDWSS_OK' : 1,
        'SDWSS_ERROR' : 0,
    };
    public static NASmokeDetectorBatterySubStatus = {
        'SDBSS_LOW' : 0,
        'SDBSS_VERY_LOW' : 1,
    };
    public static NASmokeDetectorSmokeDetectedSubStatus = {
        'SDSDSS_OK' : 0,
        'SDSDSS_DETECTED' : 1,
    };
    public static NASmokeDetectorSoundTestSubStatus = {
        'SDSTSS_OK' : 0,
        'SDSTSS_ERROR' : 1,
        'SDSTSS_STARTED' : 2,
    };
    public static NASmokeDetectorSoundTest = {
        'SDST_NEVER' : 'never',
        'SDST_TO_DO' : 'to_do',
        'SDST_OK' : 'ok',
        'SDST_FAILED' : 'failed',
    };
    public static NASmokeDetectorSoundTestReminder = {
        'SDSTR_NEVER' : 'never',
        'SDSTR_MONTHLY' : 'monthly',
        'SDSTR_SEMIANNUAL' : 'semiannual',
        'SDSTR_ANNUAL' : 'annual',
        'validValues' : [
            'never',
            'monthly',
            'semiannual',
            'annual',
        ],
    };
    public static NAStationInfo = {
        'SI_ID' : '_id',
        'SI_INSTALLATION_DATE' : 'date_setup',
        'SI_BEHAVIOR' : 'behavior',
        'SI_SERVICE' : 'service',
        'SI_LAST_STATUS_STORED' : 'last_status_store',
        'SI_REACHABLE' : 'reachable',
        'SI_LAST_UPGRADE' : 'last_upgrade',
        'SI_STATION_NAME' : 'station_name',
        'SI_FIRMWARE' : 'firmware',
        'SI_INVITATION_DISABLED' : 'invitation_disable',
        'SI_CIPHER_ID' : 'cipher_id',
        'SI_CO2_CALIBRATING' : 'co2_calibrating',
        'SI_MAIN_MODULE_NAME' : 'module_name',
        'SI_MODULES' : 'modules',
        'SI_NEW_FEATURE_AVAILABLE' : 'new_feature_available',
        'SI_PLACE' : 'place',
        'SI_TYPE' : 'type',
        'SI_WIFI_STATUS' : 'wifi_status',
        'SI_READ_ONLY' : 'read_only',
        'SI_FAVORITE' : 'favorite',
        'SI_DASHBOARD_DATA' : 'dashboard_data',
        'SI_DATA_TYPE' : 'data_type',
        'SI_PARTNER_APP' : 'partner_app',
        'SI_PARTNER_APP_LINK' : 'partner_app_link',
        'SI_PARTNER_APP_LOGO_LINK' : 'partner_app_logo_link',
        'SI_OWNERS' : 'user_owner',
        'SI_FRIENDS' : 'friend_users',
        'SI_ACCESS_CODE' : 'access_code',
        'SI_ADMIN_ACCESS_CODE' : 'admin_access_code',
        'SI_DATE_LAST_SETUP' : 'last_setup',
        'SI_AIRQ_AVAILABLE' : 'air_quality_available',
        'SI_PUBLIC_EXT_DATA' : 'public_ext_data',
        'SI_ALARM_CONFIG' : 'alarm_config',
        'SI_METEO_ALARMS' : 'meteo_alarms',
        'SI_CO2_ABC_STATUS' : 'co2_abc_status',
        'SI_CO2_ABC_ORDER' : 'co2_abc_order',
    };
    public static NAStationModuleInfo = {
        'SMI_ID' : '_id',
        'SMI_NAME' : 'module_name',
        'SMI_TYPE' : 'type',
        'SMI_FIRMWARE' : 'firmware',
        'SMI_LAST_MESSAGE' : 'last_message',
        'SMI_LAST_SEEN' : 'last_seen',
        'SMI_REACHABLE' : 'reachable',
        'SMI_RADIO_STATUS' : 'rf_status',
        'SMI_BATTERY' : 'battery_vp',
        'SMI_DASHBOARD_DATA' : 'dashboard_data',
        'SMI_DATA_TYPE' : 'data_type',
        'SMI_BATTERY_PERCENT' : 'battery_percent',
        'SMI_DATE_LAST_SETUP' : 'last_setup',
        'SMI_BEHAVIOR' : 'behavior',
        'SMI_PLUVIO_SCALE_AUGET_TO_MM' : 'pluvio_scale_auget_to_mm',
    };
    public static NAStationModuleType = {
        'SMT_MAIN' : 'NAMain',
        'SMT_OUTDOOR' : 'NAModule1',
        'SMT_WINDGAUGE' : 'NAModule2',
        'SMT_RAINGAUGE' : 'NAModule3',
        'SMT_INDOOR' : 'NAModule4',
    };
    public static NAStationOutdoorModuleInfo = {
        'SOMI_MEASURE_TIME' : 'time_utc',
        'SOMI_TEMPERATURE' : 'Temperature',
        'SOMI_HUMIDITY' : 'Humidity',
        'SOMI_DATE_MAX_TEMP' : 'date_max_temp',
        'SOMI_DATE_MIN_TEMP' : 'date_min_temp',
        'SOMI_TREND_TEMP' : 'temp_trend',
        'SOMI_MAX_TEMP' : 'max_temp',
        'SOMI_MIN_TEMP' : 'min_temp',
    };
    public static NAStationIndoorModuleInfo = {
        'SIMI_MEASURE_TIME' : 'time_utc',
        'SIMI_TEMPERATURE' : 'Temperature',
        'SIMI_DATE_MAX_TEMP' : 'date_max_temp',
        'SIMI_DATE_MIN_TEMP' : 'date_min_temp',
        'SIMI_TREND_TEMP' : 'temp_trend',
        'SIMI_MAX_TEMP' : 'max_temp',
        'SIMI_MIN_TEMP' : 'min_temp',
        'SIMI_HUMIDITY' : 'Humidity',
    };
    public static NAStationMainModuleInfo = {
        'SMMI_PRESSURE' : 'Pressure',
        'SMMI_ABSOLUTE_PRESSURE' : 'AbsolutePressure',
        'SMMI_MEASURE_TIME' : 'time_utc',
        'SMMI_NOISE' : 'Noise',
        'SMMI_TEMPERATURE' : 'Temperature',
        'SMMI_HUMIDITY' : 'Humidity',
        'SMMI_CO2' : 'CO2',
        'SMMI_DATE_MAX_TEMP' : 'date_max_temp',
        'SMMI_DATE_MIN_TEMP' : 'date_min_temp',
        'SMMI_MAX_TEMP' : 'max_temp',
        'SMMI_MIN_TEMP' : 'min_temp',
        'SMMI_TREND_TEMP' : 'temp_trend',
        'SMMI_TREND_PRESS' : 'pressure_trend',
    };
    public static NAStationRainModuleInfo = {
        'SRMI_RAIN' : 'Rain',
        'SRMI_LAST_HOUR_RAIN' : 'sum_rain_1',
        'SRMI_LAST_DAY_RAIN' : 'sum_rain_24',
        'SRMI_MEASURE_TIME' : 'time_utc',
    };
    public static NAStationWindModuleInfo = {
        'SWMI_WIND_ANGLE' : 'WindAngle',
        'SWMI_WIND_STRENGTH' : 'WindStrength',
        'SWMI_GUST_ANGLE' : 'GustAngle',
        'SWMI_GUST_STRENGTH' : 'GustStrength',
        'SWMI_MEASURE_TIME' : 'time_utc',
        'SWMI_HISTORIC' : 'WindHistoric',
        'SWMI_WIND_MAX_STRENGTH' : 'max_wind_str',
        'SWMI_WIND_MAX_ANGLE' : 'max_wind_angle',
        'SWMI_WIND_DATE_MAX_STRENGH' : 'date_max_wind_str',
    };
    public static NAStationDataType = {
        'SDT_TEMPERATURE' : 'Temperature',
        'SDT_CO2' : 'CO2',
        'SDT_HUMIDITY' : 'Humidity',
        'SDT_NOISE' : 'Noise',
        'SDT_PRESSURE' : 'Pressure',
        'SDT_WIND' : 'Wind',
        'SDT_RAIN' : 'Rain',
    };
    public static NAHomecoachDataType = {
        'SDT_HEALTH_IDX' : 'health_idx',
    };
    public static NAHomecoachModuleInfo = {
        'HC_HEALTH_IDX' : 'health_idx',
    };
    public static NAStationTrend = {
        'TREND_TEMPERATURE' : 'temp_trend',
        'TREND_PRESSURE' : 'pressure_trend',
        'STABLE' : 'stable',
        'UP' : 'up',
        'DOWN' : 'down',
    };
    public static NAUserInfo = {
        'UI_FEEL_LIKE_ALGO' : 'feel_like_algo',
        'UI_MAIL' : 'mail',
        'UI_ADMINISTRATIVE' : 'administrative',
        'UI_REG_LOCALE' : 'reg_locale',
        'UI_LANG' : 'lang',
        'UI_UNIT' : 'unit',
        'UI_WIND_UNIT' : 'windunit',
        'UI_PRESSURE_UNIT' : 'pressureunit',
    };
    public static NAHomeInfo = {
        'HI_ID' : 'id',
        'HI_NAME' : 'name',
        'HI_PLACE' : 'place',
        'HI_ROOMS' : 'rooms',
        'HI_ADMIN_ACCESS_CODE' : 'admin_access_code',
        'HI_THERM_RELAYS' : 'therm_relays',
        'HI_THERM_MODULE_NO_ROOM' : 'therm_module_no_room',
        'HI_MAGELLAN_GATEWAYS' : 'gateways',
        'HI_MAGELLAN_MODULE_NO_ROOM' : 'gateways_module_no_room',
        'HI_THERM_STATE' : 'therm_state',
        'HI_THERM_STATE_MODE' : 'mode',
        'HI_THERM_STATE_ENDTIME' : 'endtime',
        'HI_THERM_PROGRAMS' : 'therm_programs',
        'HI_THERM_PROGRAM_CURRENT' : 'therm_program_current',
        'HI_DEVICES' : 'devices',
        'HI_THERM_SETPOINT_DEFAULT_DURATION' : 'setpoint_default_duration',
        'HI_SMOKEDETECTORS' : 'smokedetectors',
        'HI_PENDING_OWNERS' : 'pending_owners',
        'HI_THERM_HEATING_PRIORITY' : 'therm_heating_priority',
        'HI_THERM_HEATING_SYSTEM' : 'heating_system',
    };
    public static NARoomInfo = {
        'RI_ID' : 'id',
        'RI_TYPE' : 'type',
        'RI_MODULES' : 'modules',
        'RI_THERM_RELAY' : 'therm_relay',
        'RI_NAME' : 'name',
        'RI_OFFSET' : 'measure_offset',
        'RI_THERM_DEFECTIVE_HEATING' : 'defective_heating',
    };
    public static NARoomTypes = {
        'RT_KITCHEN' : 'kitchen',
        'RT_BEDROOM' : 'bedroom',
        'RT_LIVINGROOM' : 'livingroom',
        'RT_BATHROOM' : 'bathroom',
        'RT_LOBBY' : 'lobby',
        'RT_CUSTOM' : 'custom',
        'RT_OUTDOOR' : 'outdoor',
        'RT_TOILETS' : 'toilets',
        'RT_GARAGE' : 'garage',
        'RT_HOME_OFFICE' : 'home_office',
        'RT_DINING_ROOM' : 'dining_room',
        'RT_CORRIDOR' : 'corridor',
        'RT_STAIRS' : 'stairs',
        'validRoomTypes' : [
            'kitchen',
            'bedroom',
            'livingroom',
            'bathroom',
            'lobby',
            'custom',
            'outdoor',
            'toilets',
            'garage',
            'home_office',
            'dining_room',
            'corridor',
            'stairs',
        ],
    };
    public static NAThermRelayInfo = {
        'TRI_ID' : 'id',
        'TRI_FW' : 'firmware',
        'TRI_TYPE' : 'type',
        'TRI_HOME_ID' : 'home_id',
        'TRI_OUTDOOR_TEMP' : 'outdoor_temperature',
        'TRI_LAST_SEEN' : 'last_seen',
        'TRI_HOMEKIT_COMPATIBLE' : 'is_homekit_compatible',
        'TRI_LAST_SETUP' : 'last_setup',
        'TRI_NEW_FEATURE_AVAIL' : 'new_feature_available',
        'TRI_PARTNER_APPS' : 'partner_app',
        'TRI_MODULES' : 'modules',
        'TRI_NAME' : 'name',
        'TRI_STATUS' : 'status',
        'TRI_CONNECTED' : 'connected',
        'TRI_CONNECTED_BOILER' : 'connected_to_boiler',
        'TRI_MAX_MODULE_NB' : 'max_modules_nb',
    };
    public static NAValveInfo = {
        'VI_CALIBRATION_ERROR' : 'calibration_error',
        'VI_RADIO_ID' : 'radio_id',
        'VI_CALIBRATION_MIN_POSITION' : 'min_position',
        'VI_CALIBRATION_MAX_POSITION' : 'max_position',
        'VI_CALIBRATION_SQUARE_DISTANCE' : 'square_distance',
        'VI_CALIBRATION_DERIVATION' : 'derivation',
    };
    public static NAMagellanGatewayInfo = {
        'MAG_ID' : 'id',
        'MAG_TYPE' : 'type',
        'MAG_LAST_SETUP' : 'last_setup',
        'MAG_FIRST_SETUP' : 'first_setup',
        'MAG_NEW_FEATURE_AVAIL' : 'new_feature_available',
        'MAG_MODULES' : 'modules',
        'MAG_NAME' : 'name',
        'MAG_CONNECTED' : 'connected',
    };
    public static NAMagellanModuleInfo = {
        'MMI_ID' : 'id',
        'MMI_NAME' : 'name',
        'MMI_TYPE' : 'type',
        'MMI_ROOM_ID' : 'room_id',
        'MMI_LAST_SETUP' : 'last_setup',
        'MMI_FIRST_SETUP' : 'first_setup',
        'MMI_APPLIANCE_TYPE' : 'appliance_type',
    };
    public static NAMagellanModuleType = {
        'MMT_GATEWAY' : 'NLG',
        'MMT_SOCKET' : 'NLP',
        'MMT_SWITCH' : 'NLF',
        'MMT_REMOTE' : 'NLT',
        'MMT_MICRO' : 'NLM',
        'MMT_ROLLER' : 'NLV',
    };
    public static NAMagellanApplianceTypes = {
        'MOT_LIGHT' : 'light',
        'MOT_FRIDGE_FREEZER' : 'fridge_freezer',
        'MOT_OVEN' : 'oven',
        'MOT_WASHING_MACHINE' : 'washing_machine',
        'MOT_TUMBLE_DRYER' : 'tumble_dryer',
        'MOT_DISHWASHER' : 'dishwasher',
        'MOT_MULTIMEDIA' : 'multimedia',
        'MOT_ROUTER' : 'router',
        'MOT_OTHER' : 'other',
        'MOT_COOKING' : 'cooking',
        'MOT_RADIATOR' : 'radiator',
        'MOT_WATER_HEATER' : 'water_heater',
        'MOT_EXTRACTOR_HOOD' : 'extractor_hood',
        'validApplianceTypes' : [
            'light',
            'fridge_freezer',
            'oven',
            'washing_machine',
            'tumble_dryer',
            'dishwasher',
            'multimedia',
            'router',
            'other',
        ],
    };
    public static CommonEventTypes = {
        'COET_BATTERY_STATUS' : 'battery_status',
        'COET_BATTERY_STATUS_LOW' : 1,
        'COET_BATTERY_STATUS_VERY_LOW' : 2,
        'COET_LOCK' : 'lock',
        'COET_UNLOCK' : 'unlock',
        'COET_UNSECURE_LOCK' : 'unsecure_lock',
    };
    public static EnergyEventTypes = {
        'ENET_SCHEDULE' : 'schedule',
        'ENET_THERM_MODE' : 'therm_mode',
        'ENET_SET_POINT' : 'set_point',
        'ENET_CANCEL_SET_POINT' : 'cancel_set_point',
        'validEvents' : [
            'schedule',
            'therm_mode',
            'set_point',
            'cancel_set_point',
        ],
    };
    public static VeluxEventTypes = {
        'NXET_ALGO_WINDOWS' : 'algo_windows',
        'NXET_ALGO_COVERS' : 'algo_covers',
    };
    public static MullerEventTypes = {
        'NMET_OPENED_WINDOW' : 'opened_window',
        'NMET_LONG_ABSENCE' : 'long_absence',
        'NMET_PRESENCE' : 'presence',
        'NMET_TOMORROW_DAY_COLOR' : 'tomorrow_electrical_mode',
    };
    public static NAMagellanEventTypes = {
        'NLET_UNREACHABLE_DEVICE' : 'unreachable_device',
        'NLET_UNREACHABLE_MODULE' : 'unreachable_module',
        'NLET_ENERGY_OVERCONSUMPTION' : 'energy_overconsumption',
        'NLET_FORGOTTEN_LIGHT' : 'forgotten_light',
        'NLET_END_SIMULATION' : 'end_simulation',
        'NLET_BATTERY_STATUS' : 'battery_status',
        'NLET_BATTERY_STATUS_LOW' : 1,
    };
    public static NAHomecoachInfo = {
        'HC_TYPE' : 'NHC',
        'HC_PROFILE' : 'profile',
        'HC_LED_STATUS' : 'led_status',
        'HC_LED_ON' : 'on',
        'HC_LED_OFF' : 'off',
        'HC_ALL' : 'all',
        'HC_BABY' : 'baby',
        'HC_ASTHMA' : 'asthma',
        'HC_ADVICE' : 'advice',
        'HC_NAME' : 'name',
        'WEIGHTING_PART_DAY' : 2,
        'WEIGHTING_SEASON' : 2,
        'WEIGHTING_PROFILE_TYPE' : 2,
        'WEIGHTING_ALL' : 1,
        'authorizedProfile' : {
            'all' : true,
            'baby' : true,
            'asthma' : true,
        },
        'authorizedLedStatus' : {
            'on' : true,
            'off' : true,
        },
    };
    public static NAHomecoachAlarms = {
        'HCA_ALARM_TYPE_TEMP' : 1,
        'HCA_ALARM_TYPE_CARBON' : 3,
        'HCA_ALARM_TYPE_HUM' : 5,
        'HCA_ALARM_TYPE_NOISE' : 4,
        'HCA_ALARM_DIRECT_HIGH' : 0,
        'HCA_ALARM_DIRECT_LOW' : 1,
        'HCA_ALARM_TEMP_HIGH' : 1,
        'HCA_THRESOLD_TEMP_HIGH_1' : 26,
        'HCA_THRESOLD_TEMP_HIGH_2' : 27,
        'HCA_THRESOLD_TEMP_HIGH_3' : 29,
        'HCA_ALARM_TEMP_LOW' : 2,
        'HCA_THRESOLD_TEMP_LOW_1' : 17,
        'HCA_THRESOLD_TEMP_LOW_2' : 16,
        'HCA_THRESOLD_TEMP_LOW_3' : 15,
        'HCA_ALARM_CARBON' : 3,
        'HCA_THRESOLD_CARBON_1' : 1150,
        'HCA_THRESOLD_CARBON_2' : 1400,
        'HCA_THRESOLD_CARBON_3' : 1600,
        'HCA_ALARM_HUM_HIGH' : 4,
        'HCA_THRESOLD_HUM_HIGH_1' : 60,
        'HCA_THRESOLD_HUM_HIGH_2' : 70,
        'HCA_THRESOLD_HUM_HIGH_3' : 80,
        'HCA_ALARM_HUM_LOW' : 5,
        'HCA_THRESOLD_HUM_LOW_1' : 30,
        'HCA_THRESOLD_HUM_LOW_2' : 20,
        'HCA_THRESOLD_HUM_LOW_3' : 15,
        'HCA_ALARM_NOISE' : 6,
        'HCA_THRESOLD_NOISE_1' : 65,
        'HCA_THRESOLD_NOISE_2' : 70,
        'HCA_THRESOLD_NOISE_3' : 80,
        'HCA_THRESOLD_BABY_TEMP_HIGH_1' : 24,
        'HCA_THRESOLD_BABY_TEMP_HIGH_2' : 26,
        'HCA_THRESOLD_ASTHMA_HUM_HIGH_1' : 50,
        'HCA_THRESOLD_ASTHMA_HUM_HIGH_2' : 65,
        'HCA_THRESOLD_ASTHMA_HUM_LOW_1' : 30,
        'HCA_THRESOLD_ASTHMA_HUM_LOW_2' : 20,
    };
    public static NAHomecoachMsgTypes = {
        'TEMPERATURE_TOO_HIGH' : 'TEMPHIGH',
        'TEMPERATURE_TOO_LOW' : 'TEMPLOW',
        'TOO_MUCH_CARBON' : 'CO2HIGH',
        'HUMIDITY_TOO_HIGH' : 'RHHIGH',
        'HUMIDITY_TOO_LOW' : 'RHLOW',
        'TOO_MUCH_NOISE' : 'NOISEHIGH',
        'ALL_OK' : 'ALLOK',
        'PREFIX' : '__HCA',
        'INDEX_KEY_NAME' : 'INDEX',
        'INDEX_SPLITTER_CHAR' : ',',
        'KEYS_SPLITTER_CHAR' : '_',
        'MORNING' : 'MORNING',
        'MIDDAY' : 'MIDDAY',
        'EVENING' : 'EVENING',
        'BEFORE_SLEEP' : 'B4SLEEP',
        'PDAY_OTHER' : 'ALLDAY',
        'normalPartsDay' : [
            'MORNING',
            'MIDDAY',
            'EVENING',
            'B4SLEEP',
        ],
    };
    public static NFATypes = {
        'INFO' : 'info',
    };
    public static NADeviceNFATypes = {
        'DNFAT_PLUG_PID' : 'plug_pid',
        'DNFAT_PLUG_ANTICIPATE' : 'plug_anticipate',
        'DNFAT_PLUG_ANTICIPATE_WITH_STATION' : 'plug_anticipate_with_station',
        'DNFAT_PLUG_HEATING_SYSTEM' : 'plug_heating_system',
        'DNFAT_VGT_ANTICIPATE' : 'vgt_anticipate',
        'DNFAT_MAIN_NEW_MODULE' : 'main_new_module',
        'DNFAT_MAIN_IMPROVE_LOC' : 'main_improve_loc',
    };
    public static NAHomeNFATypes = {
        'HNFAT_PROMO_DROPBOX' : 'promo_dropbox',
    };
    public static NAUserNFATypes = {
        'UNFAT_DROPBOX' : 'dropbox',
        'UNFAT_PRESENCE' : 'presence',
        'UNFAT_ANIMALS' : 'animals',
        'UNFAT_TAGS' : 'tags',
        'UNFAT_BLACK_FRIDAY' : 'black_friday',
        'UNFAT_VALVES' : 'valves',
        'UNFAT_VALVE_ECO_COMFORT' : 'valve_eco_comfort',
        'UNFAT_CAMERA_32_FACES' : 'camera_32_faces',
        'UNFAT_FB_CHATBOT' : 'fb_chatbot',
    };
    public static NAWifiStatus = {
        'WS_NO_CONF' : 0,
        'WS_PENDING' : 1,
        'WS_UNKNOWN_ERROR' : 2,
        'WS_OK' : 3,
        'WS_PASSWORD_ERROR' : 4,
        'WS_NOT_FOUND' : 5,
        'WS_JOIN_TIMEOUT' : 6,
        'WS_INTERNAL_ERROR' : 7,
        'WS_WEAK_SIGNAL' : 8,
        'WS_DHCP_ERROR' : 9,
        'WS_DNS_ERROR' : 10,
        'WS_TCP_ERROR' : 11,
        'WS_AUTH_ERROR' : 12,
    };
}
