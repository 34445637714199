
import { Home } from '@library/store/homes/homes.interface';
import { Module } from '@library/store/modules/modules.interface';
import { Room } from '@library/store/rooms/rooms.interface';
import { QuizzData, TimetableSlot, Zone } from '@library/store/schedules/schedules.interface';
import { ScheduleModel } from '@library/store/schedules/schedules.model';
import { Const } from '@library/utils/constants/Const.constant';
import { EventZone } from './schedules.interface';

export class ScheduleModelVaillant extends ScheduleModel {
    static dayLength = 24 * 60;
    static weekLength = 7 * ScheduleModel.dayLength;

    nightShiftWeek = false;
    nightShiftWeekEnd = false;

    comfortZone: Zone = { id: 0, name: 'Comfort', rooms: [], type: 0 };
    nightZone: Zone = { id: 1, name: 'Night', rooms: [], type: 1 };
    ecoZone: Zone = { id: 4, name: 'Eco', rooms: [], type: 5 };

    comfortZoneEvent: EventZone = { id: 0, modules: [] };
    nightZoneEvent: EventZone = { id: 1, modules: [] };
    ecoZoneEvent: EventZone = { id: 4, modules: [] };

    zones: Zone[];
    eventZones: EventZone[];

    sundayEnd = 10080; // 7j * 24h * 60min

    // Full timetable with nightzone from monday to sunday
    timetable: TimetableSlot[] = [{
        zone_id: this.nightZone.id,
        m_offset: 0,
        end_offset: this.sundayEnd
    }];

    defaultRoomTemps = {};
    defaultDhw = {};

    constructor(
        public quizzData?: QuizzData,
        public home?: Home,
        public rooms?: Room[],
        public modules?: Module[],
        public scheduleType?: string
    ) {
        super(quizzData, home, rooms, modules);
        this.nightShiftWeek = this.quizzData.dayStart > this.quizzData.dayEnd;
        this.nightShiftWeekEnd = this.quizzData.dayStartWeekend > this.quizzData.dayEndWeekend;

        this.zones = [this.comfortZone, this.nightZone, this.ecoZone];
        this.eventZones = [this.comfortZoneEvent, this.nightZoneEvent, this.ecoZoneEvent];
    }

    /**
     * Constructs the timetable and zones
     * @returns timetable and zones constructed
     */
    saveTimeTableVaillant(gatewayId?: string): { timetable?: TimetableSlot[], zones?: Zone[], eventZones?: EventZone[] } {

        if (this.scheduleType === 'therm') {
            this.buildTimeTable();

            this.constructDefaultRoomTemps();

            // Fill rooms in zones
            for (const zone of this.zones) {
                for (const room of this.rooms) {
                    let roomType = room[Const.NARoomInfo.RI_TYPE];

                    if (!this.defaultRoomTemps[zone.id][room.type]) {
                        roomType = Const.NARoomTypes.RT_CUSTOM;
                    }
                    // Only add temperature for rooms that supports it.
                    if (this.quizzData.type === 'therm') {
                        const tempKey = this.getTemperatureField(room, zone.id);
                        if (tempKey) {
                            const roomTemp = { id: room[Const.NARoomInfo.RI_ID] };
                            roomTemp[tempKey] = this.defaultRoomTemps[zone.id][roomType][tempKey];
                            zone.rooms.push(roomTemp);
                        }
                    }
                }
            }
            return { timetable: this.timetable, zones: this.zones };
        }

        /**
         * If event schedule, create the zones using the gateway id and default values of DHW (domestic hot water)
         */
        if (this.scheduleType === 'event' && gatewayId) {
            this.constructDefaultDhw();
            for (const eventZone of this.eventZones) {
                const dhw = { id: gatewayId, dhw_enabled: this.defaultDhw[eventZone.id].dhw_enabled };
                eventZone.modules.push(dhw);
            }
            return { eventZones: this.eventZones };
        }
    }

    getTemperatureField(room: Room, zoneID: number): string {
        for (const module of this.modules) {
            if (module.room_id === room.id && (module.type === 'NATherm1' || module.type === 'NAThermVaillant')) {
                return 'therm_setpoint_temperature';
            }
        }
        return null;
    }


    constructDefaultRoomTemps() {
        const defaultRoomTemps = {};

        // Comfort
        defaultRoomTemps[this.comfortZone.id] = {
            kitchen: { therm_setpoint_temperature: this.quizzData.defaultComfortTemp },
            bedroom: { therm_setpoint_temperature: this.quizzData.defaultComfortTemp },
            livingroom: { therm_setpoint_temperature: this.quizzData.defaultComfortTemp },
            bathroom: { therm_setpoint_temperature: this.quizzData.defaultComfortTemp },
            lobby: { therm_setpoint_temperature: this.quizzData.defaultComfortTemp },
            custom: { therm_setpoint_temperature: this.quizzData.defaultComfortTemp },
            toilets: { therm_setpoint_temperature: this.quizzData.defaultComfortTemp }
        };
        // Night
        defaultRoomTemps[this.nightZone.id] = {
            kitchen: { therm_setpoint_temperature: 17 },
            bedroom: { therm_setpoint_temperature: 17 },
            livingroom: { therm_setpoint_temperature: 17 },
            bathroom: { therm_setpoint_temperature: 17 },
            lobby: { therm_setpoint_temperature: 17 },
            custom: { therm_setpoint_temperature: 17 },
            toilets: { therm_setpoint_temperature: 17 }
        };
        // Eco
        defaultRoomTemps[this.ecoZone.id] = {
            kitchen: { therm_setpoint_temperature: 16 },
            bedroom: { therm_setpoint_temperature: 16 },
            livingroom: { therm_setpoint_temperature: 16 },
            bathroom: { therm_setpoint_temperature: 16 },
            lobby: { therm_setpoint_temperature: 16 },
            custom: { therm_setpoint_temperature: 16 },
            toilets: { therm_setpoint_temperature: 16 }
        };

        this.defaultRoomTemps = defaultRoomTemps;
    }

    constructDefaultDhw() {
        /**
         * Comfort has dhw_enabled default to true
         * Night and Eco(away) dhw_enabled defaults to false
         */
        const defaultDhw = {};

        // Comfort
        defaultDhw[this.comfortZoneEvent.id] = {
            dhw_enabled: true
        };
        // Night
        defaultDhw[this.nightZoneEvent.id] = {
            dhw_enabled: false
        };
        // Eco
        defaultDhw[this.ecoZoneEvent.id] = {
            dhw_enabled: false
        };

        this.defaultDhw = defaultDhw;
    }
}
