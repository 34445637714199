import { EnumRoomsActions, RoomsActions } from "@library/store/rooms/rooms.action";
import { initialRoomsState, RoomsState } from "@library/store/rooms/rooms.state";
import { VaillantRoom } from "./rooms.interface";

export function VaillantRoomsReducers(state = initialRoomsState, action: RoomsActions): RoomsState {

  switch (action.type) {

    case EnumRoomsActions.UpdateRoomMeasureOffsetSuccess: {
      const all = state.all.map( (room: VaillantRoom) => {
        if (room.id === action.payload[0].room_id) {
          action.payload.forEach((payload) => {
            if (payload.data_type === 'temperature') {
              room.measure_offset_NAVaillant_temperature = payload.offset;
            } else {
              room.measure_offset_NAVaillant_temperature = payload.offset;
            }
          });
        }
        return { ...room };
      });

      return {
        ...state,
        all
      };
    }

    default:
      return state;
  }

}
