import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import supportedLangs from 'src/assets/supportedLangs';
import {
  APP_NAME_CROWDIN_MAP,
} from 'src/assets/constant/app.constant';
import { GetUserSuccess } from '@library/store/user/user.action';
import { LoadLangs } from '@library/store/lang/lang.actions';
import { environment } from '@environments/environment'

@Injectable()
export class InitEffects {
  constructor(
    private actions: Actions,
  ) { }

  getUserSuccess$ = createEffect(() => {
    return this.actions.pipe(
      ofType(GetUserSuccess),
      map((action) => {
        const language = action.user.administrative.lang
          ? action.user.administrative.lang
          : 'en-GB';

        const countryCode = language.match(/[A-z]+-/g)
          ? language.match(/[A-z]+-/g)[0].substr(0, 2)
          : language;

        const singleLang =
          supportedLangs.SupportedLanguage.single[language] ||
          supportedLangs.BackupLanguage.single[countryCode] ||
          supportedLangs.DefaultLanguage.single;

        return new LoadLangs({
          aliases: [
            { name: 'common-app', locale: singleLang },
            { name: 'common-settings', locale: singleLang },
            { name: 'common-all', locale: singleLang },
            { name: 'common-account', locale: singleLang },
            {
              name: APP_NAME_CROWDIN_MAP[environment.appName],
              locale: singleLang,
            },
            { name: 'settings-therm', locale: singleLang },
            { name: 'settings-all', locale: singleLang }
          ],
          locale: action.user.administrative.reg_locale,
        });
      })
    );
  });
}
