import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UIModule } from '@library/ui/ui.module'
import { PipesModule } from '@library/utils/pipes/pipes.module';
import { ErrorManagementComponent } from './error-management/error-management.component';
import { MinusTextPlusComponent } from './minus-text-plus/minus-text-plus.component';

@NgModule({
    declarations: [ErrorManagementComponent, MinusTextPlusComponent],
    imports: [
        PipesModule,
        CommonModule,
        FormsModule,
        UIModule,
    ],
    exports: [ErrorManagementComponent, MinusTextPlusComponent]
})
export class ComponentsModule { }
