import { Action } from '@ngrx/store';

type State = any;
type Reducer = (state: State, action: Action, args?: any[]) => State;

/**
 * Reduces two reducers operating on the same state into a single reducer
 * @param args Initial state, first reducer, second reducer
 */

export function reduceReducers(initialState: State, ...reducers: Reducer[]): Reducer {

  if (typeof initialState === 'undefined') {
    throw new TypeError(
      'The initial state may not be undefined. If you do not want to set a value for this reducer, you can use null instead of undefined.'
    );
  }

  /**
   * New reducer
   * @param prevState Status of state before action is fired
   * @param value Action fired
   * @return State
   */
  return (prevState: State, value: Action, ...args: any[]): State => {
    const prevStateIsUndefined = typeof prevState === 'undefined';
    const valueIsUndefined = typeof value === 'undefined';

    if (prevStateIsUndefined && valueIsUndefined && initialState) {
      return initialState;
    }

    /**
     * Executes reducers functions one after the other
     */
    return reducers.reduce((newState, reducer, index) => {
      if (typeof reducer === 'undefined') {
        throw new TypeError(
          `An undefined reducer was passed in at index ${index}`
        );
      }

      return reducer(newState, value, ...args);
    }, prevStateIsUndefined && !valueIsUndefined && initialState ? initialState : prevState);
  };
}

/**
 * Used in Settings Energy
 */
