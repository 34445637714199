import { Injectable } from '@angular/core';
import { SetTrueTemperaturePayload, GetMeasureCsvPayload, CreateRoom, DeleteMeasurementsPayload, RoomOffsetUpdatePayload} from './rooms.interface';
import { map } from 'rxjs/operators';
import { NgrxHttpClient } from '@library/utils/services/http-service';

@Injectable()
export class RoomsService {

  constructor(private http: NgrxHttpClient) {
  }

  createRoom(params: CreateRoom, header?: { [key: string]: string }) {
    return this.http.post('addroomtohome', params , null, null, header);
  }

  setTrueTemperature(params: SetTrueTemperaturePayload) {
    return this.http.post('truetemperature', params);
  }

  getRoomMeasureCsv(params: GetMeasureCsvPayload) {
    return this.http.post('getroommeasurecsv', params, null, {responseType: 'blob'}).pipe(map(body => body || {}));
  }

  getMeasureCsv(params: GetMeasureCsvPayload) {
    return this.http.post('getmeasurecsv', params, null, {responseType: 'blob'}).pipe(map(body => body || {}));
  }

  updateRoom(params: any, header?: { [key: string]: string }) {
    return this.http.post('updateroom', params, null, null, header);
  }

  deleteMeasurements(params: DeleteMeasurementsPayload) {
    return this.http.post('removeroomdata', params);
  }

  deleteRoom(params: {home_id: string, room_id: string}) {
    return this.http.post('removeroomfromhome', params);
  }

  updateRoomOffset(params: RoomOffsetUpdatePayload) {
    return this.http.post('setroommeasureoffset', params);
  }
}

// Should use function from 'helpers/functions/downloadFile.ts'
export function download(options) {
  const link = document.createElement('a');
  link.addEventListener('click',
      ($event) => {
          $event.stopPropagation();
      }
  );
  // Give it some invisibility
  link.style.setProperty('display', 'none');
  // Create the url fro the link with the data
  const url = window.URL.createObjectURL(options.data);
  // Set link properties
  link.href = url;
  link.download = options.filename;
  // Simulate a click on it
  document.getElementsByTagName('body')[0].appendChild(link);
  link.click();
  // Remove the link after the operation is done
  setTimeout(() => {
      document.getElementsByTagName('body')[0].removeChild(link);
      window.URL.revokeObjectURL(url);
  }, 100);
}
