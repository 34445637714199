// tslint:disable: variable-name
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

// e.g. NetatmoApp(App//v3301103/3301103)
export const ANDROID_NEW_UA_RE = /NetatmoApp\(.*?\/v(\d+)\/(\d{7})\)/i

// e.g. NetatmoApp(Device/v3.0.1/4300000)
export const ANDROID_UA_RE = /NetatmoApp\(.*?\/v(\d+(?:\.\d+)+)\/(\d+)\)/i;

// e.g. NetatmoApp/v1.3200000
export const ANDROID_UA_RE_LEGACY = /NetatmoApp\/v1\.(\d+)/i;

// e.g. NetatmoApp/v0.1.8.7
export const IOS_UA_RE_LEGACY = /NetatmoApp\/v0\.(\d+(?:\.\d+){2})/i;

@Injectable({
    providedIn: 'root'
})
export class UserAgentService {

    private _info: string;
    private _appType: number;
    private _appVersion: string[];
    private _urlParams: any;

    platform: string;

    platforms: {[key: string]: string} = {
        'os x':         'Mac OS X',
        'linux x86_64': 'Linux 64 bits',
        linux:        'Linux 32 bits',
        windows:      'Windows'
    };

    constructor(
        private deviceService: DeviceDetectorService
    ) {
        this._info = this.deviceService.getDeviceInfo().userAgent;
        this._urlParams = this.constructUrlQueryObject();
        const match = this.info.match(/NetatmoApp\/v([012])\.([0-9]+)(\.([0-9]+)(\.([0-9]*))?)?/);

        if (match !== null) {
            const app = match.map(Number);
            // First digit is app type
            this._appType = app[1];

            // Major version
            this._appVersion = [ ('0' + app[2]).slice(-2) ];

            // Minor version
            if (typeof app[4] !== 'undefined') {
                this._appVersion.push(('0' + app[4]).slice(-2));

                // Patch version
                if (typeof app[6] !== 'undefined') {
                    this._appVersion.push(('0' + app[6]).slice(-2));
                }
            }
        }

        for (const platform of Object.keys(this.platforms)) {
            if (this.info.match(new RegExp(platform, 'ig')) !== null) {
                this.platform = platform;
                break;
            }
        }
    }

    get info(): string {
        return this._info;
    }
    get appVersion(): string[] {
        return this._appVersion;
    }
    get appType(): number {
        return this._appType;
    }

    get androidAppVersion(): number {
        try {
            // Legacy
            if (ANDROID_UA_RE_LEGACY.test(this.info)) {
                const matchLegacy = this.info.match(ANDROID_UA_RE_LEGACY);
                const version = parseInt(matchLegacy[1], 10);
                return version;
            }

            // Secondary Legacy
            if (ANDROID_UA_RE.test(this.info)) {
                const match = this.info.match(ANDROID_UA_RE);
                const version = parseInt(match[2], 10);
                return version;
            }

            const match = this.info.match(ANDROID_NEW_UA_RE);
            const version = parseInt(match[2], 10);
            return version;
        } catch (e) {
            // If app version is failed to parse, default to 0
            return 0;
        }
    }

    get iosAppVersion() {
        const match = this.info.match(IOS_UA_RE_LEGACY);
        const values = match[1].split('.');
        if (values.length > 1) {
            let computedAppVersion = 0;
            for (let i = 0; i < 5; i++) {
              const digit = typeof values[i] !== 'undefined' ? parseInt(values[i], 10) : 0;
              computedAppVersion = computedAppVersion * 100 + digit;
            }

            return computedAppVersion;
        }

        return false;
    }

    constructUrlQueryObject() {
        const pairs: string[] = window.location.search.substring(1).split('&');
        const obj: object = {};
        let pair: string[];
        let i: string;

        for (i in pairs) {

            if ( pairs[i] === '' ) { continue; }

            pair = pairs[i].split('=');
            obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
        }

        return obj;
    }

    compareVersion(v1: number[], v2: number[]) {
        // Force array to same length
        const length = v1.length - v2.length;
        if (length < 0) {
            v1 = v1.concat(new Array<number>(length).fill(0));
        }
        if (length > 0) {
            v2 = v2.concat(new Array<number>(length).fill(0));
        }
        // debugger;
        for (let i = 0; i < v1.length; i++) {
            if (v1[i] === v2[i]) {
                continue;
            }
            if (typeof v1[i] === 'undefined' || v1[i] > v2[i]) {
                return 1;
            }
            if (typeof v1[i] === 'undefined' || v1[i] < v2[i]) {
                return -1;
            }
        }
        return 0;
    }

    isNativeApp() {
        const isNative = this._urlParams.is_native === 'true' ? true : false;
        return isNative || ( /NetatmoApp/i.test(this._info) );
    }

    public isMobile() {
        return ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(this.info) );
    }

    isIos() {
        return this.appType === 0 || ( /iPhone|iPad|iPod/i.test(this.info) );
    }

    isAndroid() {
        return this.appType === 1 || ( /Android/i.test(this.info) );
    }

    iosVersion() {
        return this.info.substr(this._info.indexOf('OS') + 2, 2);
    }

    isDesktopSafari() {
        return this.deviceService.isDesktop() && this.deviceService.getDeviceInfo().os === 'Safari'
    }
}
