import { Directive, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UserAgentService } from '@library/utils/services/user-agent.service';
import { CallbackService } from '@library/utils/services/callback.service';

@Directive({
  selector: '[url]'
})
export class LinkUrlDirective {
  /**
   * Url to go to
   */
  @Input() url: string;

  /**
   * Should open in browser or not
   */
  @Input() inBrowser = false;

  /**
   * Event emitted when the link is clicked
   */
  @Output() navigate = new EventEmitter();

  constructor(
    private router: Router,
    public userAgent: UserAgentService,
    private callback: CallbackService
  ) { }

  @HostListener('click') goTo() {
    if (typeof this.url === 'undefined') {
      return;
    }

    if (!this.inBrowser) {
      if (this.url.startsWith('http')) {
        window.location.href = this.url;
      } else {
        this.router.navigateByUrl(this.url);
      }
    } else if (this.userAgent.isNativeApp()) {
      this.callback.call(this.callback.list.openInBrowser, { url: this.url, exit: false });
    } else {
      window.open(this.url, '_blank');
    }
  }
}
