import { InitEffects } from './init/init.effects';
import { EffectsModule } from '@ngrx/effects';
import { AppReducers } from './app.reducers';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HomesEffectsVaillant } from './homes/homes.effects';
import { HomesServiceVaillant } from './homes/homes.service';
import { ModulesEffectsVaillant } from './modules/modules.effects';
import { ModulesServiceVaillant } from './modules/modules.service';
import { SchedulesEffectsVaillant } from './schedules/schedules.effect';
import { ConfigEffects } from '@library/store/config/config.effects';
import { UserEffects } from '@library/store/user/user.effect';
import { LangEffects } from '@library/store/lang/lang.effects';
import { HomesEffects } from '@library/store/homes/homes.effect';
import { ModulesEffects } from '@library/store/modules/modules.effect';
import { RoomsEffects } from '@library/store/rooms/rooms.effect';
import { PageLoaderEffects } from '@library/store/page-loader/page-loader.effects';
import { ConfigService } from '@library/store/config/config.service';
import { UserService } from '@library/store/user/user.service';
import { LangService } from '@library/store/lang/lang.service';
import { HomesService } from '@library/store/homes/homes.service';
import { ModulesService } from '@library/store/modules/modules.service';
import { RoomsService } from '@library/store/rooms/rooms.service';
import { SchedulesService } from '@library/store/schedules/schedules.service';

@NgModule({
  imports: [
    StoreModule.forRoot(AppReducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([
      ConfigEffects,
      InitEffects,
      UserEffects,
      LangEffects,
      HomesEffects,
      ModulesEffects,
      RoomsEffects,
      HomesEffectsVaillant,
      ModulesEffectsVaillant,
      PageLoaderEffects,
      SchedulesEffectsVaillant
    ]),
    StoreDevtoolsModule.instrument(),
  ],
  providers: [
    ConfigService,
    UserService,
    LangService,
    HomesService,
    ModulesService,
    RoomsService,
    HomesServiceVaillant,
    ModulesServiceVaillant,
    SchedulesService
  ],
})
export class StateModule { }
