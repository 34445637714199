import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuComponent, ContextMenuAnchorDirective } from './context-menu.component';
import { ContextMenuItemComponent } from './context-menu-item/context-menu-item.component';

@NgModule({
  declarations: [
    ContextMenuComponent,
    ContextMenuItemComponent, ContextMenuAnchorDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ContextMenuComponent,
    ContextMenuItemComponent, ContextMenuAnchorDirective
  ]
})
export class ContextMenuModule { }
