<div [@routeAnimations]="userAgent.isNativeApp() && o && (animation$ | async)">
  <ng-content select="[role=header]"></ng-content>

  <main class="is-fullheight"
    [class.webapp-mode]="isWebappMode()"
    [class.has-side-borders]="!userAgent.isMobile()">

    <router-outlet #o="outlet"></router-outlet>

  </main>

  <ng-content select="[role=footer]"></ng-content>
</div>
