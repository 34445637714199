import { Component, OnInit, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lay-panel-content',
  templateUrl: './panel-content.component.html',
  styleUrls: ['./panel-content.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class PanelContentComponent implements OnInit {

  @HostBinding('class') get classes() {
    return `panel ${this.styleClass}`;
  }

  @Input() styleClass = '';

  constructor() { }

  ngOnInit() {
  }

}

/**
 * Used in Settings Lux
 */

