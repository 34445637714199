import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtomsModule } from '@library/ui/atoms/atoms.module';
import { DatePickerComponent } from './date-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

@NgModule({
  declarations: [DatePickerComponent],
  imports: [
    CommonModule,
    AtomsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatMomentDateModule,
  ],
  exports: [
    DatePickerComponent
  ]
})
export class DatePickerModule { }
