import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelComponent } from './panel.component';
import { PanelContentComponent } from './panel-content/panel-content.component';
import { PanelFooterComponent } from './panel-footer/panel-footer.component';

@NgModule({
  declarations: [PanelComponent, PanelContentComponent, PanelFooterComponent],
  imports: [
    CommonModule,
  ],
  exports: [
    PanelComponent,
    PanelContentComponent,
    PanelFooterComponent
  ]
})
export class PanelModule { }
