import { Injectable } from '@angular/core';
import { NgrxHttpClient } from '@library/utils/services/http-service';
import { ChangePolarityPayload, ModuleRoomUpdate, RemoveModulePayload } from './modules.interface';
@Injectable()
export class ModulesService {
  constructor(protected http: NgrxHttpClient) {
  }

  changePolarity(params: ChangePolarityPayload) {
    return this.http.post('changedevicebehavior', params);
  }

  updateModule(params: any, header?: { [key: string]: string }) {
    return this.http.post('updatemodule', params, null, null, header);
  }

  updateDevice(params: any) {
    return this.http.post('updatedevice', params);
  }

  updateModuleRoom(params: ModuleRoomUpdate, header?: { [key: string]: string }) {
    return this.http.post('assigndevicetoroom', params, null, null, header);
  }

  removeDeviceFromHome(params: RemoveModulePayload) {
    return this.http.post('removedevicefromhome', params);
  }

}
