import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store'
import { Observable, of } from 'rxjs';
import { catchError, switchMap, delay, mergeMap, map } from 'rxjs/operators';
import { EnumHomesActions, GetHomeConfigSuccess, GetHomesSuccess, GetHomeStatusSuccess, GetReadonlyDeviceStateSuccess } from '../homes/homes.action';
import { RemoveModuleFromRoom } from '../rooms/rooms.action';
import {
  EnumModulesActions,
  GetModulesSuccess,
  UpdateModuleName,
  UpdateModuleNameFailure,
  UpdateModuleNameSuccess,
  UpdateModulesGetConfig,
  UpdateModulesStatus,
  UpdateModuleRoom,
  UpdateModuleRoomSuccess,
  UpdateModuleRoomFailure,
  RemoveModuleFromHome,
  RemoveModuleFromHomeSuccess,
  RemoveModuleFromHomeFailure,
  ChangePolarity,
  ChangePolaritySuccess,
  ChangePolarityFailure,
  SetModulesStatusErrors
} from './modules.action';
import { ModulesService } from './modules.service';

@Injectable()
export class ModulesEffects {
  constructor(
    private actions$: Actions,
    private moduleService: ModulesService
  ) { }

  getModules$ = createEffect(() => this.actions$.pipe(
    ofType<GetHomesSuccess>(EnumHomesActions.GetHomesSuccess),
    switchMap((action) => [new GetModulesSuccess(action.payload, action.modules)])
  ));

  updateConfig$ = createEffect(() => this.actions$.pipe(
    ofType<GetHomeConfigSuccess>(EnumHomesActions.GetHomeConfigSuccess),
    switchMap((action) => [new UpdateModulesGetConfig(action.payload)])
  ));

  updateStatus$ =  createEffect(() => this.actions$.pipe(
    ofType<GetHomeStatusSuccess>(EnumHomesActions.GetHomeStatusSuccess),
    switchMap((action) => [new UpdateModulesStatus(action.payload)])
  ));

  updateReadonlyDeviceState$ =  createEffect(() => this.actions$.pipe(
    ofType<GetReadonlyDeviceStateSuccess>(EnumHomesActions.GetReadonlyDeviceStateSuccess),
    switchMap(({modules, errors}) => {
      return [
        ...modules.map(m => new UpdateModulesStatus({id: m.bridge ?? m.id, modules: [m]})),
        new SetModulesStatusErrors({id: '', errors: errors ?? []}),
      ];
    }),
  ));

  updateModuleName$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateModuleName>(EnumModulesActions.UpdateModuleName),
    mergeMap((action) => {
      let apiObservable: Observable<Action>;
      if (action.payload.module_id !== action.payload.device_id) {
        apiObservable= this.moduleService.updateModule(action.payload, action.header);
      } else {
        apiObservable = this.moduleService.updateDevice(action.payload);
      }
      return apiObservable.pipe(
        switchMap( () => [
          new UpdateModuleNameSuccess(action.payload)
        ]),
        catchError( ({ error }) => of(new UpdateModuleNameFailure(error)))
      );
    })
  ));

  updateModuleRoom$ = createEffect(() => this.actions$.pipe(
    ofType(EnumModulesActions.UpdateModuleRoom),
    switchMap( (action: UpdateModuleRoom) => {
      return this.moduleService.updateModuleRoom(action.payload, action.header).pipe(
        delay(1000),
        switchMap( () => [
          new UpdateModuleRoomSuccess(action.payload)
        ]),
        catchError( ({error}) => of(new UpdateModuleRoomFailure(error)))
      );
    })
  ));

  removeModuleFromHome$ = createEffect(() => this.actions$.pipe(
    ofType(EnumModulesActions.RemoveModuleFromHome),
    switchMap( (action: RemoveModuleFromHome) => {
      return this.moduleService.removeDeviceFromHome(action.payload).pipe(
        switchMap(() => [
          new RemoveModuleFromHomeSuccess(action.payload),
          new RemoveModuleFromRoom({module_id: action.payload.device_id, room_id: action.payload.room_id})
        ]),
        catchError(({error}) => of(new RemoveModuleFromHomeFailure(error)))
      );
    })
  ));


  changePolarity$ = createEffect(() => this.actions$.pipe(
    ofType(EnumModulesActions.ChangePolarity),
    switchMap( (action: ChangePolarity) => {
        return this.moduleService.changePolarity(action.payload).pipe(
        switchMap( () => [
            new ChangePolaritySuccess(action.payload)
        ]),
        catchError( ({error}) => of(new ChangePolarityFailure(error)))
        );
    })
  ));

}

