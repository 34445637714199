import { createAction, props } from '@ngrx/store';
import { UserError, User, GetUserDataSuccessPayload } from './user.interface';

export const GetUser = createAction(
  '[USER] Get User data',
);

export const GetUserSuccess = createAction(
  '[USER] Get user data success',
  props< {user: GetUserDataSuccessPayload}>()
);

export const GetUserFailure = createAction(
  '[USER] Get user data failure',
  props< {payload: UserError}>()
);

export const SetHomeUser = createAction(
  '[USER] Set Home User data',
  props< {user: User}>()
);

