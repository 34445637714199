import { Injectable } from "@angular/core";

@Injectable()
export class PlusMinusService {
  constructor() {}

  roundValue(value, step: number, min: number, max: number, isReadonly: boolean, shouldRound: boolean) {
    /**
     * Rounds the value entered to the closest acceptable value
     * *10 and /10 to avoid JS calculation problems with floating point
     */

      const localvalue = Math.round(parseFloat(value) * 100000);
      const localStep = step * 100000;
      const r = localvalue % localStep;

      if (r <= localStep / 2) {
        value = (localvalue - r) / 100000;
      } else if (r > localStep / 2) {
        value = (localvalue + localStep - r) / 100000;
      }


      // when it's readonly, blocks the value between its intervals
      if (isReadonly || shouldRound) {
        if (value < min) {
          value = min;
        }
        if (value > max) {
          value = max;
        }
      }
      return value;
  }

}

   