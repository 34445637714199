import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { inject, Injectable, InjectionToken } from '@angular/core';
import { filter, pairwise, tap, map, share, takeUntil } from 'rxjs/operators';
import { Subscription, Observable, Subject } from 'rxjs';

export enum EnumNavigationDirections {
  Forward = 'forward',
  Backward = 'backward'
}

export const GLOBAL_NAVCOUNTER = new InjectionToken<NavigationCounter>('GLOBAL_NAVCOUNTER', {
  providedIn: 'root',
  factory: () => new NavigationCounter(inject(Router)),
});

/**
 * a service to count the number of history entries between two navigationIDs,
 * the end history entry should be behind the start history entry
 */
@Injectable()
export class NavigationCounter {

  /**
   * the number of the history entries between the start point (navigationID) and the actual point (navigationID)
   */
  // tslint:disable-next-line: variable-name
  private _nbHistoryEntries = 0;

  private lastNbEntries = 0;

  // map between navigationID and the number of history entries
  private navigationEntriesMap: any = { };

  private countingEnd$ = new Subject();

  public navigationWatcher$ = this.router.events.pipe(
    filter(event => ((event instanceof NavigationStart) || (event instanceof NavigationEnd))),
    pairwise(),
    // if we receive a NavigationStart and a NavigationEnd with the same navigationID, it's considered as a successful navigation
    filter(([e1, e2]) => ((e1 instanceof NavigationStart) && (e2 instanceof NavigationEnd) && e1.id === e2.id))
  );

  public counter$ = this.navigationWatcher$.pipe(
    tap((events: [NavigationStart, NavigationEnd]) => {
      this.lastNbEntries = this._nbHistoryEntries;
      if (events[0].navigationTrigger !== 'popstate') {
        // in case of an imperative or a hashchange, we increment the nbHistoryEntries
        this._nbHistoryEntries ++;
        this.navigationEntriesMap[events[0].id] = this._nbHistoryEntries;
      } else if (events[0].restoredState) {
        // in case of a popstate, we restore the nbHistoryEntries of the restored navigationID
        this.navigationEntriesMap[events[0].id] = this.navigationEntriesMap[events[0].restoredState.navigationId];
        this._nbHistoryEntries = this.navigationEntriesMap[events[0].id];
      }
      // if we go back to the start point or earlier points, the counter stops automatically
      if ((this._nbHistoryEntries === undefined) || (this._nbHistoryEntries <= 0)) { this.stopCounting(); }
    }),
    share(), takeUntil(this.countingEnd$)
  );

  private counterSubscription: Subscription;

  public readonly currentDirection$: Observable<EnumNavigationDirections> = this.counter$.pipe(
    filter(() => !this.router.getCurrentNavigation().extras.state?.['no-animation']),
    map(() => (this._nbHistoryEntries > this.lastNbEntries) ? EnumNavigationDirections.Forward : EnumNavigationDirections.Backward),
    share()
  );

  public readonly currentNavigation$ = this.counter$.pipe(
    map(([startEvent, endEvent]) => ({ start: startEvent, end: endEvent })),
    share()
  );

  constructor(
    private router: Router
  ) { }

  get nbHistoryEntries() {
    return this._nbHistoryEntries;
  }

  /**
   * to set the start point (navigationID) and start counting
   * @param advance push forward the start point, the default value is 0
   */
  startCounting(advance = 0) {
    this._nbHistoryEntries = advance;
    this.lastNbEntries = null;
    this.navigationEntriesMap = {};
    let i = 0;
    const currentId = window.history.state ? window.history.state.navigationId : 0;
    while (i < advance + 1) {
      this.navigationEntriesMap[currentId - i] = advance - i;
      i ++;
    }
    this.counterSubscription = this.counter$.subscribe();
  }

  /**
   * to stop counting (but the current nbHistoryEntries is still available)
   */
  stopCounting() {
    if (this.isCounting()) {
      this.countingEnd$.next('The counting ends!');
    }
  }

  /**
   * to know whether the counter is counting
   */
  isCounting() {
    if (!this.counterSubscription) {
      return false;
    }
    return !this.counterSubscription.closed;
  }
}
