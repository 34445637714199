import { getCurrentRoom } from "@library/store/rooms/rooms.selector";
import { createSelector } from "@ngrx/store";
import { VaillantRoom } from "./rooms.interface";

export const getCurrentRoomTempOffset = createSelector(
  getCurrentRoom,
  (r) => {
    let room = r as VaillantRoom;
    return room.measure_offset_NAVaillant_temperature ? room.measure_offset_NAVaillant_temperature : 0;
  }
)
