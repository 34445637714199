import { NgModule } from '@angular/core';
import { ButtonIconModule } from './button-icon/button-icon.module'
import { LayButtonModule } from './button/button.module'
import { ContextMenuModule } from './context-menu/context-menu.module'
import { HeaderModule } from './header/header.module'
import { LinkModule } from './link/link.module'
import { MainModule } from './main/main.module'
import { ModalModule } from './modal/modal.module';
import { OverflowEllipsisModule } from './overflow-ellipsis/overflow-ellipsis.module'
import { PanelBlockModule } from './panel-block/panel-block.module';
import { PanelHeadingModule } from './panel-heading/panel-heading.module'
import { PanelModule } from './panel/panel.module';
import { RadioSelectModule } from './radio-select/radio-select.module'
import { TabsModule } from './tabs/tabs.module'
import { TitleModule } from './title/title.module'

const Modules = [
  LayButtonModule,
  ButtonIconModule,
  ContextMenuModule,
  HeaderModule,
  LinkModule,
  MainModule,
  ModalModule,
  OverflowEllipsisModule,
  PanelModule,
  PanelBlockModule,
  PanelHeadingModule,
  RadioSelectModule,
  TabsModule,
  TitleModule,
];

@NgModule({
  imports: Modules,
  exports: Modules,
})
export class LayoutModule { }
