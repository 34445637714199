import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelBlockComponent } from './panel-block.component';
import { PanelTextComponent } from './panel-text/panel-text.component';
import { PanelTitleComponent } from './panel-title/panel-title.component';
import { PanelLinkComponent } from './panel-link/panel-link.component';
import { LinkModule } from '../link/link.module';
import { TitleModule } from '../title/title.module';
import { TextModule } from '@library/ui/atoms/text/text.module';
import { PanelPlusMinusComponent } from './panel-plus-minus/panel-plus-minus.component';
import { ElHeadingModule } from '@library/ui/molecules/el-heading/el-heading.module';

@NgModule({
  imports: [
    CommonModule,
    LinkModule,
    TitleModule,
    TextModule,
    ElHeadingModule
  ],
  exports: [
    PanelBlockComponent,
    PanelTextComponent,
    PanelTitleComponent,
    PanelLinkComponent,
    PanelPlusMinusComponent
  ],
  declarations: [
    PanelBlockComponent,
    PanelTextComponent,
    PanelTitleComponent,
    PanelLinkComponent,
    PanelPlusMinusComponent
  ]
})
export class PanelBlockModule { }
