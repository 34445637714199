import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lay-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
