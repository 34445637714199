import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HidePageLoader, EnumPageLoaderActions, HidePageLoaderSuccess } from './page-loader.actions';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class PageLoaderEffects {
  constructor(
    private actions$: Actions,
  ) { }

  hidePageLoader = createEffect(() => this.actions$.pipe(
    ofType<HidePageLoader>(EnumPageLoaderActions.HidePageLoader),
    switchMap(() =>
      Promise.resolve(new HidePageLoaderSuccess()),
    ),
  ));
}
