import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { SchedulesFacade } from '@library/store/schedules/schedules.facade';
import { HomesFacadeVaillant } from 'app/stores/homes/homes.facade';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AdditionalQuestionsGuard  {

    constructor(
        private router: Router,
        private homesFacadeVaillant: HomesFacadeVaillant,
        private schedulesFacade: SchedulesFacade,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return combineLatest([
            this.homesFacadeVaillant.currentHome$,
            this.schedulesFacade.quizzData$,
        ]).pipe(
            map(([home, quizzData]) => {
                const defaultSchedule = home.schedules.find(schedule => schedule.default && schedule.type === quizzData.type);
                if (typeof defaultSchedule === 'undefined') {
                    /**
                     * We need to go from /therm/presence-sunday to /therm/temp
                     * So we need to get the parent from the url state and navigate to it
                     */
                    const decodedUrl = decodeURIComponent(state.url);
                    const parentUrl = decodedUrl
                        .slice(0, decodedUrl.indexOf(route.url[route.url.length - 1].path));
                    this.router.navigate([parentUrl, 'temp']);
                    return false;
                }

                else {
                    return true;
                }
            }),
        );
    }
}

