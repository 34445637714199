/* tslint:disable:object-literal-key-quotes */
export default {
  SupportedLanguage: {
      single: {
          'en-GB': 'en-GB',
          'fr-FR': 'fr',
          'it-IT': 'it',
          'es-ES': 'es-ES',
          'pl-PL': 'pl',
          'de-DE': 'de',
          'pt-PT': 'pt-PT',
          'bg-BG': 'bg',
          'cs-CS': 'cs',
          'nl-NL': 'nl',
          'hu-HU': 'hu',
          'ro-RO': 'ro',
          'ru-RU': 'ru',
          'sk-SK': 'sk',
          'tr-TR': 'tr',
          'da-DK': 'da',
          'de-CH': 'de',
          'el-GR': 'el',
          'et-EE': 'et',
          'fr-BE': 'fr',
          'fr-CH': 'fr',
          'hr-HR': 'hr',
          'lt-LT': 'lt',
          'lv-LV': 'lv',
          'nl-BE': 'nl',
          'sl-SI': 'sl',
          'sr-RS': 'sr-CS',
          'uk-UA': 'uk',
          'sq-SQ': 'sq',
          'mk-MK': 'mk',
          'ka-GE': 'ka',

      }
  },
  BackupLanguage: {
      single: {
          'en': 'en-GB',
          'fr': 'fr',
          'it': 'it',
          'es': 'es-ES',
          'pl': 'pl',
          'de': 'de',
          'pt': 'pt-PT',
          'bg': 'bg',
          'cs': 'cs',
          'nl': 'nl',
          'hu': 'hu',
          'ro': 'ro',
          'ru': 'ru',
          'sk': 'sk',
          'tr': 'tr',
          'da': 'da',
          'el': 'el',
          'et': 'et',
          'hr': 'hr',
          'lt': 'lt',
          'lv': 'lv',
          'sl': 'sl',
          'sr': 'sr-CS',
          'uk': 'uk',
          'sq': 'sq',
          'al': 'sq',
          'ka': 'ka',
          'mk': 'mk'
      }
  },
  DefaultLanguage: {
      single: 'en-GB',
  }
};
