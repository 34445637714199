import { Injectable } from '@angular/core';
import { NgrxHttpClient } from '@library/utils/services/http-service';

import { Observable } from 'rxjs';
import { User } from './user.interface';

@Injectable()
export class UserService {
  constructor(private http: NgrxHttpClient) {}

  getUser(): Observable<User> {
    return this.http.get('getuser');
  }
}
