import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockNotificationComponent } from './block-notification.component';
import { AtomsModule } from '@library/ui/atoms/atoms.module'


@NgModule({
  declarations: [BlockNotificationComponent],
  imports: [
    CommonModule,
    AtomsModule,
  ],
  exports: [BlockNotificationComponent]
})
export class BlockNotificationModule { }
