import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { createSelector } from '@ngrx/store';
import { Room } from './rooms.interface';

const roomsState = (state: SettingsState) => state.rooms;
const homesState = (state: SettingsState) => state.homes;
const moduleState = (state: SettingsState) => state.modules;

export const getRooms = createSelector(
  roomsState,
  state => {
    return state.all;
  }
);

export const getCurrentRoomId = createSelector(
  roomsState,
  state => {
    return state.currentRoomId;
  }
);

export const getcurrentHomeRooms = createSelector(
  homesState,
  roomsState,
  (homes, rooms) => {
    return rooms.all.filter(room => room.homeId === homes.currentHomeId);
  }
);

export const isLoadingRooms = createSelector(
  roomsState,
  state => {
    return state.loading;
  }
);

export const getCurrentRoom = createSelector(
  getRooms,
  getCurrentRoomId,
  (rooms: Room[], id: string) => {
    return rooms.find(room => room.id === id);
  }
);

export const getRoomById = (id: string) => createSelector(
  getRooms,
  (rooms: Room[]) => {
    return rooms.find(room => room.id === id);
  }
);

export const getCurrentTemperature = createSelector(
  getCurrentRoom,
  room => {
    if (!room || typeof room.therm_measured_temperature === 'undefined') {
      return null;
    }
    return room.therm_measured_temperature;
  }
);

/**
 * The condition is for the room which not have modules to not print an error when we check
 * if the id is on the module list
 */
export const getRoomModules = createSelector(
  moduleState,
  getCurrentRoom,
  (state, room) => {
    if (!room || !room.modules) {
      return null; }
    return state.all.filter(mod => room.modules.includes(mod.id));
  }
);

export const getTrueTemperatureAvailable = createSelector(
  getCurrentRoom,
  room => {
    return room ? (room.true_temperature_available) : null;
  }
);

export const hasValve = createSelector(
  getRoomModules,
  modules => {
    return modules?.find(mod => mod.type === 'NRV') !== undefined ?? false;
  }
);

export const isCurrentRoomReachable = createSelector(
  getCurrentRoom,
  room => {
    return room.reachable;
  }
);

export const getRoomGateway = createSelector(
  moduleState,
  getCurrentRoom,
  (modules, room) => {
    return modules.all.find(mod => mod.id === room.therm_relay);
  }
);

