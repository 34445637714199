import { Module } from './modules.interface';

const emptyModules: Module[] = [];

export interface ModulesState {
  all: Module[];
  currentModuleId: string;
  loading: boolean;
}

export const initialModulesState: ModulesState = {
  all: emptyModules,
  currentModuleId: null,
  loading: false,
};
