import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'lay-overflow-ellipsis',
  templateUrl: './overflow-ellipsis.component.html',
  styleUrls: ['./overflow-ellipsis.component.scss']
})
export class OverflowEllipsisComponent implements OnInit {

  @HostBinding('class') classes = 'is-text-ellipsis';

  constructor() { }

  ngOnInit() {
  }

}
