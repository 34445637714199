import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, forkJoin } from 'rxjs';
import { catchError, switchMap, delay, take } from 'rxjs/operators';
import { EnumHomesActions, GetHomeConfigSuccess, GetHomesSuccess, GetHomeStatusSuccess, RemoveRoomFromHome } from '../homes/homes.action';
import { EnumRoomsActions, GetMeasureCsv, GetMeasureCsvFailure, GetMeasureCsvSuccess,
         GetRoomsSuccess, SetTrueTemperature, SetTrueTemperatureFailure,
         SetTrueTemperatureSuccess, UpdateRoomsGetConfig, UpdateRoomsStatus,
         GetRoomMeasureCsv, GetRoomMeasureCsvSuccess, GetRoomMeasureCsvFailure,
         UpdateRoomNameSuccess, UpdateRoomNameFailure, UpdateRoomName, RemoveModuleFromRoom,
         AddModuleToRoom, CreateNewRoom, CreateNewRoomSuccess, CreateNewRoomFailure,
         DeleteMeasurements, DeleteMeasurementsSuccess, DeleteMeasurementsFailure,
         DeleteRoom, DeleteRoomSuccess, DeleteRoomFailure, UpdateRoomMeasureOffset, UpdateRoomMeasureOffsetSuccess, UpdateRoomMeasureOffsetFailure } from './rooms.action';
import { RoomsService } from './rooms.service';
import { EnumModulesActions, UpdateModuleRoomSuccess } from '../modules/modules.action';

@Injectable()
export class RoomsEffects {
  constructor(
    private actions$: Actions,
    private roomsService: RoomsService
  ) { }

  getRooms$ = createEffect(() => this.actions$.pipe(
    ofType<GetHomesSuccess>(EnumHomesActions.GetHomesSuccess),
    switchMap((action) => [new GetRoomsSuccess(action.payload, action.modules)])
  ));

  updateConfig$ = createEffect(() => this.actions$.pipe(
    ofType<GetHomeConfigSuccess>(EnumHomesActions.GetHomeConfigSuccess),
    switchMap((action) => [new UpdateRoomsGetConfig(action.payload)])
  ));

  updateStatus$ = createEffect(() => this.actions$.pipe(
    ofType<GetHomeStatusSuccess>(EnumHomesActions.GetHomeStatusSuccess),
    switchMap((action) => [new UpdateRoomsStatus(action.payload)])
  ));

  setTrueTemperature$ = createEffect(() => this.actions$.pipe(
      ofType(EnumRoomsActions.SetTrueTemperature),
      switchMap((action: SetTrueTemperature) => {
          return this.roomsService.setTrueTemperature(action.payload).pipe(
              switchMap(() => [
                new SetTrueTemperatureSuccess(action.payload),
              ]),
              catchError(({ error }) => of(new SetTrueTemperatureFailure(error))),
          );
      })
  ));

  getMeasureCsv$ = createEffect(() => this.actions$.pipe(
      ofType<GetMeasureCsv>(EnumRoomsActions.GetMeasureCsv),
      switchMap((action) => {
          return this.roomsService.getMeasureCsv(action.payload).pipe(
              switchMap((t: Blob) => [
                new GetMeasureCsvSuccess(t, action.payload),
              ]),
              catchError(({ error }) => of(new GetMeasureCsvFailure(error))),
          );
      })
  ));

  getRoomMeasureCsv$ = createEffect(() => this.actions$.pipe(
      ofType<GetRoomMeasureCsv>(EnumRoomsActions.GetRoomMeasureCsv),
      switchMap((action) => {
          return this.roomsService.getRoomMeasureCsv(action.payload).pipe(
              switchMap((t: Blob) => [
                new GetRoomMeasureCsvSuccess(t, action.payload),
              ]),
              catchError(({ error }) => of(new GetRoomMeasureCsvFailure(error))),
          );
      })
  ));

  updateRoomName$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateRoomName>(EnumRoomsActions.UpdateRoomName),
    switchMap((action) => {
      return this.roomsService.updateRoom(action.payload, action.header).pipe(
        switchMap(() => [
          new UpdateRoomNameSuccess(action.payload)
        ]),
        catchError( ({ error }) => of(new UpdateRoomNameFailure(error)))
      );
    })
  ));

  moveModuleRoom$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateModuleRoomSuccess>(EnumModulesActions.UpdateModuleRoomSuccess),
    switchMap( (action) => {

      const id  = action.payload.module_id ? action.payload.module_id : action.payload.device_id;
      const payload = {
        room_id: action.payload.room_id,
        module_id: id
      };

      return [
        new RemoveModuleFromRoom(payload),
        new AddModuleToRoom(payload)
      ];

    })
  ));

  createNewRoom$ = createEffect(() => this.actions$.pipe(
    ofType<CreateNewRoom>(EnumRoomsActions.CreateNewRoom),
    switchMap( (action) => {

      return this.roomsService.createRoom(action.payload, action.header).pipe(
        delay(1000),
        switchMap( (res: any) => {

          const payload = { ...action.payload, room_id: res.body.room_id };

          return [
            new CreateNewRoomSuccess(payload)
          ];

        }),
        catchError( ({ error }) => of(new CreateNewRoomFailure(error)))
      );

    })
  ));

  deleteMeasurements$ = createEffect(() => this.actions$.pipe(
    ofType(EnumRoomsActions.DeleteMeasurements),
    switchMap((action: DeleteMeasurements) => {
        return this.roomsService.deleteMeasurements(action.payload).pipe(
            switchMap(() => [
              new DeleteMeasurementsSuccess(action.payload),
            ]),
            catchError(({ error }) => of(new DeleteMeasurementsFailure(error))),
        );
    })
  ));

  deleteRoom$ = createEffect(() => this.actions$.pipe(
    ofType(EnumRoomsActions.DeleteRoom),
    switchMap( (action: DeleteRoom) => {
      return this.roomsService.deleteRoom(action.payload).pipe(
        switchMap( () => [
          new DeleteRoomSuccess(action.payload),
          new RemoveRoomFromHome(action.payload)
        ]),
        catchError(({error}) => of(new DeleteRoomFailure(error)))
      );
    })
  ));

  updateRoomOffset$ = createEffect(() =>  this.actions$.pipe(
    ofType(EnumRoomsActions.UpdateRoomMeasureOffset),
    switchMap( (action: UpdateRoomMeasureOffset) => {

      const apiObservables = action.payload.map((data) => {
        return this.roomsService.updateRoomOffset(data).pipe(
          switchMap( (res) => {
            return of(res);
          }),
          catchError(({error}) => of(error)),
          take(1)
        );
      });

      // Handles case when multiple offset calls need to be made for same page update
      return forkJoin(apiObservables).pipe(
        switchMap((results) => {
          const statuses = results.filter( (result) => {
            // status message from Netatmo API response
            if (result.status !== 'ok') {
              return result;
            }
          });
          if (statuses.length === 0) {
            return of(new UpdateRoomMeasureOffsetSuccess(action.payload));
          } else {
            return of(new UpdateRoomMeasureOffsetFailure(statuses[0]));
          }
        })
      );
    })
  ));
}

