export const countryPrice = [
    { code: 'AL', currencySymbol: 'L', price: 17.2 },
    { code: 'DZ', currencySymbol: 'دج', price: 1.96 },
    { code: 'AU', currencySymbol: '$', price: 0.33},
    { code: 'AT', currencySymbol: '€', price: 0.2074},
    { code: 'BE', currencySymbol: '€', price: 0.286},
    { code: 'BA', currencySymbol: 'MK', price: 0.18},
    { code: 'BR', currencySymbol: 'R$', price: 0.78},
    { code: 'BG', currencySymbol: 'лв', price: 0.19},
    { code: 'CA', currencySymbol: '$', price: 0.14},
    { code: 'CL', currencySymbol: '$', price: 145.63},
    { code: 'CO', currencySymbol: '$', price: 533.62},
    { code: 'CR', currencySymbol: '₡', price: 94.66},
    { code: 'HR', currencySymbol: 'kn', price: 1},
    { code: 'CZ', currencySymbol: 'Kč', price: 0.177 },
    { code: 'DK', currencySymbol: 'kr', price: 0.2924 },
    { code: 'EG', currencySymbol: 'ج.م', price: 0.56 },
    { code: 'EE', currencySymbol: '€', price: 0.1411 },
    { code: 'FI', currencySymbol: '€', price: 0.1783 },
    { code: 'FR', currencySymbol: '€', price: 0.1913 },
    { code: 'DE', currencySymbol: '€', price: 0.2873 },
    { code: 'GB', currencySymbol: '£', price: 0.2 },
    { code: 'GR', currencySymbol: '€', price: 0.1551 },
    { code: 'HU', currencySymbol: 'Ft', price: 39.29 },
    { code: 'IN', currencySymbol: 'Rs.', price: 6.04 },
    { code: 'IE', currencySymbol: '€', price: 0.2546 },
    { code: 'IL', currencySymbol: '₪', price: 0.58 },
    { code: 'IT', currencySymbol: '€', price: 0.2341 },
    { code: 'JP', currencySymbol: '¥', price: 30.01 },
    { code: 'KR', currencySymbol: '₩', price: 126.09 },
    { code: 'XK', currencySymbol: '€', price: 0.0605 },
    { code: 'LV', currencySymbol: '€', price: 0.164 },
    { code: 'LB', currencySymbol: 'L.L', price: 116.72 },
    { code: 'LT', currencySymbol: '€', price: 0.1254 },
    { code: 'LU', currencySymbol: '€', price: 0.1799 },
    { code: 'MY', currencySymbol: 'RM', price: 0.24 },
    { code: 'MU', currencySymbol: '₨', price: 5.88 },
    { code: 'MX', currencySymbol: '$', price: 1.65},
    { code: 'ME', currencySymbol: '€', price: 0.09 },
    { code: 'MA', currencySymbol: 'د.م.', price: 1.17 },
    { code: 'MM', currencySymbol: 'K', price: 48.76 },
    { code: 'NL', currencySymbol: '€', price: 0.2055 },
    { code: 'NZ', currencySymbol: '$', price: 0.34},
    { code: 'NO', currencySymbol: 'kr', price: 1.83 },
    { code: 'MK', currencySymbol: 'MKD', price: 4.44 },
    { code: 'PL', currencySymbol: 'zł', price: 0.61 },
    { code: 'PT', currencySymbol: '€', price: 0.2181 },
    { code: 'RO', currencySymbol: 'L', price: 0.69 },
    { code: 'RU', currencySymbol: 'руб', price: 4.43 },
    { code: 'SA', currencySymbol: 'ر.س', price: 0.18 },
    { code: 'RS', currencySymbol: 'd./д.', price: 8.47 },
    { code: 'SG', currencySymbol: 'S$', price: 0.25},
    { code: 'SK', currencySymbol: '€', price: 0.1585 },
    { code: 'SI', currencySymbol: '€', price: 0.1666 },
    { code: 'ZA', currencySymbol: 'R', price: 2.03 },
    { code: 'ES', currencySymbol: '€', price: 0.2394 },
    { code: 'SE', currencySymbol: 'kr', price: 2.15 },
    { code: 'CH', currencySymbol: 'CHF', price: 0.22 },
    { code: 'TH', currencySymbol: '฿', price: 3.82 },
    { code: 'TR', currencySymbol: 'YTL', price: 0.91 },
    { code: 'AE', currencySymbol: 'د.إ', price: 0.3},
    { code: 'VN', currencySymbol: '₫', price: 1876.6 },
    { code: 'US', currencySymbol: '$', price : 0.1328 },
    // { code: 'MQ', currencySymbol: '€', price : 0.1039 },
    // { code: 'GF', currencySymbol: '€', price : 0.1008 },
    // { code: 'RE', currencySymbol: '€', price : 0.1040 },
    // { code: 'YT', currencySymbol: '€', price : 0.1055 },
];

